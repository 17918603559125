<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-3"
          color="primary"
          v-bind="attrs"
          v-on="on"
          :small="dense"
        >
          <v-icon class="mr-2">mdi-plus</v-icon>New Course
          Grouping</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          New Course Group
        </v-card-title>

        <v-card-text class="py-2">
          <v-form ref="createCourseGroupForm">
            <v-label>Title</v-label>
            <v-text-field
              v-model="title"
              :rules="rules.requiredField"
              label="Title"
              single-line
              outlined
              dense
              required
            />

            <v-label>Description</v-label>
            <v-text-field
              v-model="description"
              :rules="rules.requiredField"
              label="Description"
              single-line
              outlined
              dense
              required
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="secondary"
            :disabled="creatingCourseGroup"
            :loading="creatingCourseGroup"
            @click="createCourseGroup"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { CreateCourseGroup } from '@/graphql/CourseGroup.gql';

export default {
  name: 'CreateCourseGroupModal',
  props: {
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false,
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ]
    },
    title: '',
    description: '',
    creatingCourseGroup: false
  }),
  watch: {
    dialog(dialog) {
      if (!dialog) {
        this.resetForm();
        this.$refs.createCourseGroupForm.reset();
      }
    }
  },
  computed: {
    ...mapState('user', ['currentUserAccount']),
    courseContentId() {
      return this.$route.params.courseContentId;
    }
  },
  methods: {
    resetForm() {
      this.title = '';
      this.description = '';
      this.creatingCourseGroup = false;
    },
    async createCourseGroup() {
      if (this.$refs.createCourseGroupForm.validate()) {
        try {
          this.creatingCourseGroup = true;
          await this.$apollo.mutate({
            mutation: CreateCourseGroup,
            variables: {
              courseGroup: {
                title: this.title,
                description: this.description
              },
              courseContentId: this.courseContentId
            }
          });

          this.$emit('refetch');
          this.dialog = false;
        } catch (e) {
          e.graphQLErrors.map(x => this.$toast.error(x.message));
        } finally {
          this.creatingCourseGroup = false;
        }
      }
    }
  }
};
</script>
