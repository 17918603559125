var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "px-4 pt-8 d-flex flex-column align-center" },
    [
      _c(
        "v-col",
        { staticClass: "flex-grow-1", attrs: { cols: "10" } },
        [
          _c(
            "v-row",
            {
              staticClass: "dark-blue-text",
              attrs: { "no-gutters": "", justify: "center" }
            },
            [_c("h2", [_vm._v("You're almost there!")])]
          ),
          _c(
            "v-row",
            {
              staticClass: "font-weight-light dark-blue-text text-center mt-4",
              attrs: { "no-gutters": "", justify: "center" }
            },
            [_c("p", [_vm._v(" Your password has been reset. ")])]
          ),
          _c(
            "v-row",
            {
              staticClass: "font-weight-light dark-blue-text text-center mt-4",
              attrs: { "no-gutters": "", justify: "center" }
            },
            [
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.isLearner
                        ? "Log in with your email address and password in the Ask Annie mobile app on your iOS or Android device now. "
                        : "Log in with your email address and password in on the Ask Annie login page now."
                    ) +
                    " "
                )
              ])
            ]
          ),
          _vm.isLearner
            ? _c(
                "v-row",
                { staticClass: "mt-6", attrs: { justify: "center" } },
                [
                  _c("p", { staticClass: "font-weight-bold text-center" }, [
                    _vm._v(" Download Ask Annie now! ")
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "v-row",
            { staticClass: "mt-6", attrs: { justify: "center" } },
            [
              !_vm.isLearner
                ? _c(
                    "v-btn",
                    {
                      staticClass: "login-button",
                      attrs: { color: "primary", large: "" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/login")
                        }
                      }
                    },
                    [_vm._v(" Back to login ")]
                  )
                : _vm._e(),
              _vm.isLearner
                ? _c(
                    "a",
                    {
                      staticClass: "mr-4",
                      attrs: {
                        href:
                          "https://apps.apple.com/in/app/ask-annie/id1541576444",
                        target: "_blank"
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../images/AppStoreButton.svg"),
                          alt: "App Store Button",
                          height: "47"
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _vm.isLearner
                ? _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "https://play.google.com/store/apps/details?id=au.org.dementia",
                        target: "_blank"
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../images/GooglePlayButton.svg"),
                          alt: "Google Play Button",
                          height: "47"
                        }
                      })
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "mt-auto pb-6 flex-grow-0",
          attrs: { justify: "center" }
        },
        [
          _c("span", { staticClass: "font-weight-light dark-blue-text" }, [
            _vm._v(" Need some help? "),
            _c(
              "a",
              {
                staticClass: "toggle-link",
                attrs: {
                  href:
                    "https://www.dementia.org.au/professionals/professional-development-and-training/ask-annie/ask-annie-support",
                  target: "_blank"
                }
              },
              [_vm._v(" Contact us ")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }