<template>
  <base-login-page>
    <v-row class="pa-4" justify="center">
      <login-form
        v-if="!showResetSent"
        @reset-email-sent="handleResetEmailSent"
      />
      <reset-password-sent
        v-else
        :email="resetEmail"
        @back-to-login="showResetSent = false"
      />
    </v-row>
  </base-login-page>
</template>

<script>
import BaseLoginPage from './Login/BaseLoginPage.vue';
import LoginForm from './Login/LoginForm.vue';
import ResetPasswordSent from './Login/ResetPasswordSent.vue';

export default {
  name: 'Login',
  components: {
    BaseLoginPage,
    LoginForm,
    ResetPasswordSent
  },
  data: () => ({
    showResetSent: false,
    resetEmail: ''
  }),
  methods: {
    handleResetEmailSent(email) {
      this.resetEmail = email;
      this.showResetSent = true;
    }
  }
};
</script>
