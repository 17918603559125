import UserAccount from '../../models/UserAccount';

const actions = {
  updateCurrentUserAccount({ commit }, data) {
    commit('setCurrentUserAccount', data.currentUserAccount);
  }
};

const mutations = {
  setCurrentUserAccount(state, userAccount) {
    state.currentUserAccount = new UserAccount(userAccount);
    state.loadingUserAccount = false;
  }
};

const state = {
  currentUserAccount: undefined,
  loadingUserAccount: true,
};

export default {
  namespaced: true,
  actions,
  mutations,
  state
};
