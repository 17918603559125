var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    {
      staticClass: "text--secondary text-subtitle-2 font-weight-light",
      attrs: { "no-gutters": "", align: "center" }
    },
    _vm._l(_vm.items, function(item, index) {
      return _c(
        "v-col",
        { key: index, attrs: { cols: "auto" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c("v-col", { staticClass: "mr-1", attrs: { cols: "auto" } }, [
                _vm._v(" " + _vm._s(item) + " ")
              ]),
              index != _vm.items.length - 1
                ? _c(
                    "v-col",
                    { staticClass: "mr-1", attrs: { cols: "auto" } },
                    [_vm._v(" / ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }