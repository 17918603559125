import Vue from 'vue';
import VueApollo from 'vue-apollo';

// Load GraphQL fragment file and IntrospectionFragmentMatcher
// to support intersection types.
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { auth } from '@/main';

// Install the vue plugin
Vue.use(VueApollo);

const authLink = setContext(async (_request, { headers }) => {
  const token = (await auth.currentUser?.getIdToken()) ?? '';

  const newHeaders = {
    ...headers,
    Authorization: `Bearer ${token}`
  };

  return {
    headers: newHeaders
  };
});

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_SERVER_URL
    ? `${process.env.VUE_APP_SERVER_URL}/graphql`
    : 'https://prod-server-682260008929.australia-southeast2.run.app/graphql'
});

const { apolloClient } = createApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

// Create vue apollo provider
export const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});
