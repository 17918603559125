class Question {
  constructor(data = {}) {
    this.id = data.id;
    this.title = data.title;
    this.variations = data.variations.map(variation =>
      JSON.parse(variation)
    );
    this.questionType = data.questionType;
    this.lesson = data.lesson;
    this.referenceId = data.referenceId;
    this.version = data.version;
  }
}

export default Question;
