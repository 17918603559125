var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mr-3",
                          attrs: { color: "primary", small: _vm.dense }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-plus")
                      ]),
                      _vm._v("New Update")
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "rounded-lg" },
            [
              _c(
                "v-card-title",
                { staticClass: "h6 font-weight-bold primary--text" },
                [_vm._v(" New Update ")]
              ),
              _c(
                "v-card-text",
                { staticClass: "py-2" },
                [
                  _c(
                    "v-form",
                    { ref: "createCourseContentForm" },
                    [
                      _c("v-label", [_vm._v("Title")]),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.rules.requiredField,
                          label: "Title",
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          required: ""
                        },
                        model: {
                          value: _vm.title,
                          callback: function($$v) {
                            _vm.title = $$v
                          },
                          expression: "title"
                        }
                      }),
                      _c("v-label", [_vm._v("Summary")]),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.rules.requiredField,
                          label: "Summary",
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          required: ""
                        },
                        model: {
                          value: _vm.summary,
                          callback: function($$v) {
                            _vm.summary = $$v
                          },
                          expression: "summary"
                        }
                      }),
                      _c("v-label", [_vm._v("Version")]),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.rules.requiredField,
                          label: "Version",
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          required: ""
                        },
                        model: {
                          value: _vm.version,
                          callback: function($$v) {
                            _vm.version = $$v
                          },
                          expression: "version"
                        }
                      }),
                      _c("v-label", [
                        _vm._v("Import Content from Previous Release")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      class: _vm.existingCourseContentId
                        ? "selected-course-content px-2 py-1"
                        : "mt-1"
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _vm.existingCourseContentId
                            ? _c(
                                "v-col",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        "no-gutters": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass:
                                                "text-caption font-weight-bold",
                                              attrs: {
                                                color: "grey lighten-2",
                                                small: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "v" +
                                                  _vm._s(
                                                    _vm.courseContentSelected
                                                      .version
                                                  )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                align: "center",
                                                "no-gutters": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "primary--text text-body-2",
                                                  attrs: { cols: "auto" }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.courseContentSelected
                                                        .title
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                align: "center",
                                                "no-gutters": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "text-caption",
                                                  attrs: { cols: "auto" }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-capitalize",
                                                      style: `color: ${_vm.getStateColour(
                                                        _vm
                                                          .courseContentSelected
                                                          .state
                                                      )}`
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.courseContentSelected.state.toLowerCase()
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c("course-content-selector", {
                                attrs: {
                                  "selected-course-content":
                                    _vm.existingCourseContentId
                                },
                                on: {
                                  "add-selected-course-content":
                                    _vm.selectCourseContent
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "grey lighten-2" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary--text",
                      attrs: {
                        color: "secondary",
                        disabled: _vm.creatingCourseContent,
                        loading: _vm.creatingCourseContent
                      },
                      on: { click: _vm.createCourseContent }
                    },
                    [_vm._v(" Create ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }