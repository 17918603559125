export const getStateColour = state => {
  switch (state) {
    case 'TESTING':
      return '#9C27B0';
    case 'UNAVAILABLE':
      return '#FF5252';
    case 'PUBLISHED':
      return '#4CAF50';
    default:
      return '#9E9E9E';
  }
};
