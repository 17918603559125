var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function({ on, attrs }) {
                return [
                  _vm.small
                    ? _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            { attrs: { color: "white", icon: "" } },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-swap-vertical")])],
                        1
                      )
                    : _c(
                        "div",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass:
                                "mr-3 ml-1 pa-0 rounded-lg organisation-switcher",
                              style: `background-image: url(${_vm.currentOrganisation?.logoUrl});`,
                              attrs: { color: "primary" }
                            },
                            "div",
                            attrs,
                            false
                          ),
                          on
                        )
                      )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "rounded-lg" },
            [
              _c(
                "v-card-title",
                { staticClass: "h6 font-weight-bold primary--text" },
                [_vm._v(" Organisation Switcher ")]
              ),
              _c(
                "v-card-text",
                { staticClass: "py-4" },
                _vm._l(_vm.organisations, function(organisation, index) {
                  return _c(
                    "v-row",
                    { key: index, staticClass: "mx-0" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "rounded-lg pa-0",
                          attrs: {
                            width: "100%",
                            text: "",
                            disabled:
                              organisation.id === _vm.currentOrganisationId
                          },
                          on: {
                            click: function($event) {
                              return _vm.changeOrganisation(organisation.id)
                            }
                          }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "organisation-row",
                              attrs: { align: "center" }
                            },
                            [
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _c("img", {
                                  staticClass: "rounded-lg",
                                  staticStyle: {
                                    width: "40px",
                                    height: "40px",
                                    "object-fit": "cover",
                                    "margin-top": "4px",
                                    "margin-left": "4px"
                                  },
                                  attrs: { src: organisation.logoUrl }
                                })
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pl-0",
                                  attrs: { cols: "auto" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(organisation.companyName) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" Close ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }