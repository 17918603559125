<template>
  <div class="tabbed-page">
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>Courses</h1>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="ml-auto">
          <create-course-modal
            @refetch="$apollo.queries.courseContent.refetch()"
          />
        </v-col>
      </v-row>
    </v-container>

    <courses-table
      :courses="coursesToDisplay"
      :loading="$apollo.queries.courseContent.loading"
      course-content-view
    />
  </div>
</template>

<script>
import CoursesTable from '@/components/Course/CoursesTable';
import CreateCourseModal from '@/components/Course/CreateCourseModal';
import { CourseContent } from '@/graphql/CourseContent.gql';

export default {
  name: 'CourseContentCoursesPage',
  components: {
    CoursesTable,
    CreateCourseModal
  },
  computed: {
    courseContentId() {
      return this.$route.params.courseContentId;
    },
    coursesToDisplay() {
      if (this.$apollo.queries.courseContent.loading) {
        return [];
      }

      let courses = [];

      for (const courseGroup of this.courseContent.courseGroups) {
        for (const course of courseGroup.courses) {
          courses.push(course);
        }
      }

      return courses;
    }
  },
  apollo: {
    courseContent: {
      query: CourseContent,
      variables() {
        return {
          id: this.$route.params.courseContentId
        };
      }
    }
  },
  mounted() {
    this.$apollo.queries.courseContent.refetch();
  }
};
</script>
