<template>
  <v-app>
    <v-container class="my-auto">
      <v-row
        class="text-h5 font-weight-bold primary--text mb-2"
        justify="center"
      >
        Insufficient Permissions
      </v-row>
      <v-row class="font-weight-light mb-10" justify="center">
        If you are a learner, please continue using Ask Annie via the
        mobile app.
      </v-row>
      <v-row class="mb-10" justify="center">
        <img src="../images/insufficient_permissions_hero.svg" />
      </v-row>
      <v-row justify="center">
        <v-btn color="primary" @click="logout">Logout</v-btn>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { auth } from '@/main';

export default {
  name: 'InsufficientPermissions',
  methods: {
    logout() {
      auth.signOut().then(() => this.$router.push('/'));
    }
  }
};
</script>
