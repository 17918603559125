<template>
  <div class="tabbed-page">
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>Lessons</h1>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="ml-auto">
          <create-lesson-modal
            @refetch="$apollo.queries.courseContent.refetch()"
          />
        </v-col>
      </v-row>
    </v-container>

    <lessons-table
      :lessons="lessonsToDisplay"
      :loading="$apollo.queries.courseContent.loading"
      course-content-view
    />
  </div>
</template>

<script>
import LessonsTable from '@/components/Lesson/LessonsTable';
import { CourseContent } from '@/graphql/CourseContent.gql';
import CreateLessonModal from '@/components/Lesson/CreateLessonModal';

export default {
  name: 'CourseContentLessonsPage',
  components: {
    LessonsTable,
    CreateLessonModal
  },
  computed: {
    courseContentId() {
      return this.$route.params.courseContentId;
    },
    lessonsToDisplay() {
      if (this.$apollo.queries.courseContent.loading) {
        return [];
      }

      let lessons = [];

      for (const courseGroup of this.courseContent.courseGroups) {
        for (const course of courseGroup.courses) {
          for (const module of course.modules) {
            for (const lesson of module.lessons) {
              lessons.push(lesson);
            }
          }
        }
      }

      return lessons;
    }
  },
  apollo: {
    courseContent: {
      query: CourseContent,
      variables() {
        return {
          id: this.$route.params.courseContentId
        };
      }
    }
  },
  mounted() {
    this.$apollo.queries.courseContent.refetch();
  }
};
</script>
