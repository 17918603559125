<template>
  <div>
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>Pending Requests</h1>
            </v-col>
          </v-row>
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <div class="page-subtitle">
                The following users have requested to share their
                course completion data with your organisation.
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <pending-requests-table
      :pending-requests="organisationPendingRequests"
      :loading="$apollo.queries.organisationPendingRequests.loading"
      @approve="approvePendingRequest"
      @deny="denyPendingRequest"
    />

    <create-bundle-modal
      :open="createModalOpen"
      :organisation-id="organisationId"
      @close="createModalOpen = false"
      @created="onCreated"
    />
  </div>
</template>

<script>
import { OrganisationPendingRequests } from '@/graphql/Organisation.gql';
import {
  ApprovePendingRequest,
  DenyPendingRequest
} from '@/graphql/Membership.gql';
import CreateBundleModal from '@/components/Bundle/CreateBundleModal';
import PendingRequestsTable from '@/components/Organisation/PendingRequests/PendingRequestsTable';

export default {
  name: 'OrganisationBundleDetails',
  components: {
    CreateBundleModal,
    PendingRequestsTable
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Name', value: 'name' },
      {
        text: 'Bundle Code',
        value: 'bundleCode'
      },
      {
        text: 'Active',
        value: 'active'
      },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ],
    createModalOpen: false
  }),
  computed: {
    organisationId() {
      return this.$route.params.organisationId;
    }
  },
  apollo: {
    organisationPendingRequests: {
      query: OrganisationPendingRequests,
      variables() {
        return {
          organisationId: this.organisationId
        };
      }
    }
  },
  mounted() {
    this.$apollo.queries.organisationPendingRequests.refetch();
  },
  methods: {
    onCreated() {
      this.$apollo.queries.organisationPendingRequests.refetch();
      this.createModalOpen = false;
    },
    async approvePendingRequest(id) {
      await this.$apollo.mutate({
        mutation: ApprovePendingRequest,
        variables: {
          id: id
        }
      });
      await this.$apollo.queries.organisationPendingRequests.refetch();
    },
    async denyPendingRequest(id) {
      await this.$apollo.mutate({
        mutation: DenyPendingRequest,
        variables: {
          id: id
        }
      });
      await this.$apollo.queries.organisationPendingRequests.refetch();
    }
  }
};
</script>
