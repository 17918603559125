<template>
  <v-col cols="10" class="d-flex flex-column">
    <v-form
      ref="form"
      class="px-4 pt-8 flex-grow-1"
      @input="validateForm"
      @submit.prevent="handleResetPassword"
    >
      <v-row no-gutters justify="center" class="dark-blue-text">
        <h2>Reset Password</h2>
      </v-row>
      
      <v-text-field
        v-model="password"
        type="password"
        outlined
        placeholder="Enter new password"
        :rules="passwordRules"
        hide-details="false"
        class="mt-3 mb-4"
        :disabled="loading"
      />

      <v-text-field
        v-model="confirmPassword"
        type="password"
        outlined
        placeholder="Confirm password"
        :rules="confirmPasswordRules"
        hide-details="false"
        :disabled="loading"
      />

      <v-row v-if="message" class="mt-4" justify="center">
        <span :class="messageClass">{{ message }}</span>
      </v-row>
      
      <v-row class="mt-6" justify="center">
        <v-btn
          color="primary"
          large
          class="login-button"
          type="submit"
          :loading="loading"
          :disabled="loading"
        >
          Reset
        </v-btn>
      </v-row>
      <v-row class="mt-6" justify="center">
        <a
          href="javascript:void(0)"
          class="toggle-link font-weight-light"
          @click="$router.push('/login')"
        >
          Go back to login
        </a>
      </v-row>
    </v-form>
      <v-row class="pb-6 flex-grow-0" justify="center">
      <span class="font-weight-light dark-blue-text">
        Need some help? 
        <a
          href="https://www.dementia.org.au/professionals/professional-development-and-training/ask-annie/ask-annie-support"
          class="toggle-link text-decoration-underline"
          target="_blank"
        >
          Contact us
        </a>
      </span>
    </v-row>
  </v-col>
</template>

<script>
import { auth } from '@/main';
import {
  verifyPasswordResetCode,
  confirmPasswordReset
} from 'firebase/auth';

export default {
  name: 'ResetPasswordForm',
  props: {
    oobCode: {
      type: String,
      required: true
    }
  },
  data: () => ({
    password: '',
    confirmPassword: '',
    message: '',
    loading: false,
  }),
  computed: {
    messageClass() {
      return 'error--text font-weight-light';
    },
    validationRules() {
      return {
        password: {
          required: v => !!v || 'Please enter a password',
          minLength: v => v.length >= 6 || 'Password must be at least 6 characters'
        },
        confirmPassword: {
          required: v => !!v || 'Please confirm your password',
          match: v => v === this.password || 'Passwords do not match'
        }
      };
    },
    passwordRules() {
      return [
        this.validationRules.password.required,
        this.validationRules.password.minLength
      ];
    },
    confirmPasswordRules() {
      return [
        this.validationRules.confirmPassword.required,
        this.validationRules.confirmPassword.match
      ];
    }
  },
  methods: {
    validateForm() {
      if (this.message && this.$refs.form.validate()) {
        this.message = '';
      }
    },
    getValidationError() {
      let error = null;

      error = this.validationRules.password.required(this.password);
      if (typeof error === 'string') {
        return error;
      }

      error = this.validationRules.password.minLength(this.password);
      if (typeof error === 'string') {
        return error;
      }

      error = this.validationRules.confirmPassword.required(this.confirmPassword);
      if (typeof error === 'string') {
        return error;
      }

      error = this.validationRules.confirmPassword.match(this.confirmPassword);
      if (typeof error === 'string') {
        return error;
      }

      return null;
    },
    async handleResetPassword() {
      if (!this.$refs.form.validate()) {
        this.message = this.getValidationError();
        return;
      }

      this.message = '';
      this.loading = true;

      try {
        const isValid = await this.verifyCode();
        if (!isValid) {
          this.$router.push('/login');
          return;
        }

        await confirmPasswordReset(auth, this.oobCode, this.password);
        this.$emit('reset-complete');
      } catch (error) {
        console.error('Error resetting password:', error);
        this.message = 'Failed to reset password. Please try again.';
      } finally {
        this.loading = false;
      }
    },
    async verifyCode() {
      try {
        await verifyPasswordResetCode(auth, this.oobCode);
        return true;
      } catch (error) {
        console.error('Invalid or expired reset code:', error);
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-link {
  color: #113247;
  text-decoration: none;
  font-size: 15px;
}

.dark-blue-text {
  color: #113247;
}

.login-button {
  width: 100%;
  max-width: 200px;
}
</style> 