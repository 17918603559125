var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-sub-sec" },
        [
          _c("v-text-field", {
            staticClass: "shrink",
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search",
              outlined: "",
              dense: "",
              "hide-details": ""
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "dark--text rounded-b-lg rounded-t-0",
        attrs: {
          items: _vm.filteredLearners,
          headers: _vm.headers,
          "no-data-text": `There are no learners${
            _vm.search ? " that match your search" : ""
          }`,
          loading: _vm.loading,
          "loading-text": "Loading...",
          "item-key": "id"
        },
        on: {
          "click:row": item =>
            _vm.$router.push(
              _vm.organisationId
                ? `/organisation/${_vm.organisationId}/learners/${item.id}`
                : `/learners/${item.id}`
            )
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.firstName`,
              fn: function({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s([item.firstName, item.lastName].join(" ")) +
                      " "
                  )
                ]
              }
            },
            {
              key: `item.email`,
              fn: function({ item }) {
                return [_vm._v(" " + _vm._s(item.email) + " ")]
              }
            },
            {
              key: `item.courseCompletion`,
              fn: function({ item }) {
                return [_c("span", [_vm._v("0/10")])]
              }
            },
            {
              key: `item.moduleCompletion`,
              fn: function({ item }) {
                return [_c("span", [_vm._v("0/10")])]
              }
            },
            {
              key: `item.lessonCompletion`,
              fn: function({ item }) {
                return [_c("span", [_vm._v("0/10")])]
              }
            },
            {
              key: `item.editColumn`,
              fn: function({ item }) {
                return [
                  _vm.archived
                    ? _c(
                        "v-btn",
                        { attrs: { icon: "" } },
                        [_c("v-icon", [_vm._v("mdi-download")])],
                        1
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push(
                                _vm.organisationId
                                  ? `/organisation/${_vm.organisationId}/learners/${item.id}`
                                  : `/learners/${item.id}`
                              )
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                        1
                      ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        bottom: "",
                        "content-class": "black--text rounded-xl",
                        color: "white"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function({ on, attrs }) {
                              return [
                                item.deleteRequestedAt
                                  ? _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { color: "error" } },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-delete-clock ")]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c("div", { staticClass: "pa-1" }, [
                        _vm._v(
                          " Deletion requested on " +
                            _vm._s(
                              new Date(
                                item.deleteRequestedAt
                              ).toLocaleDateString()
                            ) +
                            " " +
                            _vm._s(
                              item.deleteRequestedBy
                                ? `by ${item.deleteRequestedBy.email}`
                                : ""
                            ) +
                            " "
                        )
                      ])
                    ]
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }