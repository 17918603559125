<template>
  <div>
    <div class="page-sub-sec">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        dense
        hide-details
        class="shrink"
      />
    </div>
    <v-data-table
      :items="filteredDementiaFactContent"
      :headers="headers"
      class="dark--text rounded-b-lg rounded-t-0"
      :no-data-text="
        `There are no dementia facts${
          search ? ' that match your search' : ''
        }`
      "
      :loading="loading"
      loading-text="Loading..."
      item-key="id"
    >
      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ formatDate(item.updatedAt) }}
      </template>

      <template v-slot:[`item.editColumn`]="{ item }">
        <v-btn icon @click="$emit('edit-dementia-fact', item)">
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <v-btn
          color="error"
          icon
          @click="$emit('delete-dementia-fact', item.id)"
        >
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { objectHasSearch } from '@/utils/search';
import { formatDate } from '@/utils/date';

export default {
  name: 'DementiaFactsTable',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    dementiaFacts: {
      required: true
    }
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Title', value: 'title' },
      { text: 'Content', value: 'content' },
      { text: 'Updated At', value: 'updatedAt' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ]
  }),
  computed: {
    filteredDementiaFactContent() {
      return this.dementiaFacts
        ? this.dementiaFacts.filter(dementiaFact =>
            objectHasSearch(dementiaFact, this.search)
          )
        : this.dementiaFacts;
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    }
  }
};
</script>
