<template>
  <div>
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>Publish Content</h1>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="ml-auto d-flex">
          <create-course-content-modal
            @refetch="$apollo.queries.courseContents.refetch()"
          />
        </v-col>
      </v-row>
    </v-container>

    <publish-table
      :course-content="courseContents"
      :loading="$apollo.queries.courseContents.loading"
    />
  </div>
</template>

<script>
import PublishTable from '@/components/Course/PublishTable';
import CreateCourseContentModal from '@/components/Course/CreateCourseContentModal';
import { CourseContents } from '@/graphql/CourseContent.gql';

export default {
  name: 'PublishPage',
  components: {
    PublishTable,
    CreateCourseContentModal
  },
  apollo: {
    courseContents: {
      query: CourseContents
    }
  }
};
</script>
