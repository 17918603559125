<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-3"
          color="primary"
          v-bind="attrs"
          v-on="on"
          :small="dense"
        >
          <v-icon class="mr-2">mdi-account-plus</v-icon>Invite
          {{ staff ? 'Staff' : 'Learner' }}</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          Invite {{ staff ? 'Staff' : 'Learner' }} Account
        </v-card-title>

        <v-card-text class="py-2">
          <v-form ref="inviteUserAccountForm">
            <v-label>Email</v-label>
            <v-text-field
              v-model="email"
              :rules="rules.requiredField"
              label="Email"
              single-line
              outlined
              dense
              required
            />

            <v-label>First Name</v-label>
            <v-text-field
              v-model="firstName"
              :rules="rules.requiredField"
              label="First Name"
              single-line
              outlined
              dense
              required
            />

            <v-label>Last Name</v-label>
            <v-text-field
              v-model="lastName"
              :rules="rules.requiredField"
              label="Last Name"
              single-line
              outlined
              dense
              required
            />

            <div v-if="!organisationId && staff">
              <v-label>System Role</v-label>
              <v-select
                v-model="systemRole"
                :rules="rules.requiredField"
                :items="systemRolesItems"
                label="System Role"
                single-line
                outlined
                dense
                required
              />
            </div>

            <div v-if="organisationId && staff">
              <v-label>User Role</v-label>
              <v-select
                v-model="userRole"
                :rules="rules.requiredField"
                :items="
                  organisationId && !siteId
                    ? organisationRoleItems
                    : siteRoleItems
                "
                label="User Role"
                single-line
                outlined
                dense
                required
              />
            </div>

            <div v-if="organisationId && !staff">
              <v-label>Site</v-label>
              <v-autocomplete
                v-model="site"
                :rules="rules.requiredField"
                :items="siteItems"
                label="Site"
                single-line
                outlined
                dense
                required
              />
            </div>

            <div v-if="organisationId && !staff">
              <v-label>Bundle</v-label>
              <v-autocomplete
                v-model="bundle"
                :rules="rules.requiredField"
                :items="bundleItems"
                label="Bundle"
                single-line
                outlined
                dense
                required
              />
            </div>
          </v-form>
        </v-card-text>

        <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-linear>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false" :disabled="loading">
            Cancel
          </v-btn>
          <v-btn color="secondary" @click="inviteUser" :loading="loading" :disabled="loading">
            Invite
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { systemRolesItems } from '@/enums/SystemRoles';
import { Sites } from '@/graphql/Site.gql';
import { Bundles } from '@/graphql/Bundle.gql';
import { InviteUserAccount } from '@/graphql/UserAccount.gql';
import { siteRoles, organisationRoles } from '@/enums/UserRoles';
import enumToDisplay from '@/utils/enumToDisplay';

export default {
  name: 'InviteUserAccountModal',
  props: {
    staff: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    organisationId: {
      type: String,
      default: null
    },
    siteId: {
      type: String,
      default: null
    }
  },
  data: () => ({
    dialog: false,
    loading: false,
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ]
    },
    firstName: '',
    lastName: '',
    email: '',
    employeeIdentifier: '',
    site: null,
    bundle: null,
    userRole: null,
    systemRole: null
  }),
  watch: {
    dialog(dialog) {
      if (!dialog) {
        this.resetForm();
        this.$refs.inviteUserAccountForm.reset();
      }
    }
  },
  computed: {
    systemRolesItems() {
      return systemRolesItems;
    },
    siteItems() {
      return this.sites
        ? this.sites.map(site => ({
            text: site.name,
            value: site.id
          }))
        : [];
    },
    bundleItems() {
      return this.bundles
        ? this.bundles.map(bundle => ({
            text: bundle.name,
            value: bundle.id
          }))
        : [];
    },
    siteRoleItems() {
      return enumToDisplay(siteRoles);
    },
    organisationRoleItems() {
      return enumToDisplay(organisationRoles);
    }
  },
  apollo: {
    sites: {
      query: Sites,
      variables() {
        return {
          organisationId: this.organisationId
        };
      },
      skip() {
        return !this.organisationId;
      }
    },
    bundles: {
      query: Bundles,
      variables() {
        return {
          organisationId: this.organisationId
        };
      },
      skip() {
        return !this.organisationId;
      }
    }
  },
  methods: {
    resetForm() {
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.site = null;
      this.bundle = null;
      this.userRole = null;
      this.systemRole = null;
    },
    async inviteUser() {
      if (this.$refs.inviteUserAccountForm.validate()) {
        this.loading = true;
        try {
          await this.$apollo.mutate({
            mutation: InviteUserAccount,
            variables: {
              inviteUserAccount: {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                siteId: this.siteId ?? this.site,
                bundleId: this.bundle,
                organisationId: this.organisationId,
                memberRole: this.userRole,
                systemRole: this.systemRole,
                employeeIdentifier: this.employeeIdentifier
              }
            }
          });

          this.$emit('refetch');
          this.dialog = false;
        } catch (e) {
          e.graphQLErrors.map(x => this.$toast.error(x.message));
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
</script>
