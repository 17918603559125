class AaaConfig {
  constructor(data = {}) {
    this.id = data.id;
    this.name = data.name;
    this.version = data.version;
    this.objectId = data.objectId;
    this.data = data.data;
    this.valid = data.valid;
    this.state = data.state;
    this.uploadedBy = data.uploadedBy;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.lastActivatedAt = data.lastActivatedAt;
    this.validationResults = data.validationResults;
    this.mediaDependencies = data.mediaDependencies;
    this.unmetMediaDependencies = data.unmetMediaDependencies;
  }
}

export default AaaConfig;
