var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabbed-page" },
    [
      _c(
        "v-container",
        { staticClass: "primary--text mb-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mr-auto", attrs: { cols: "auto" } },
                        [
                          _c("h1", [_vm._v("Dementia Facts")]),
                          _c("div", { staticClass: "page-subtitle" }, [
                            _vm._v(
                              " Configure the facts that are displayed on Ask Annie's loading screens. "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.openCreateDementiaFactModal = true
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-plus")
                      ]),
                      _vm._v("New Fact")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("dementia-facts-table", {
        attrs: {
          "dementia-facts": _vm.dementiaFacts,
          loading: _vm.$apollo.queries.dementiaFacts.loading
        },
        on: {
          "edit-dementia-fact": _vm.editDementiaFact,
          "delete-dementia-fact": _vm.deleteDementiaFact
        }
      }),
      _c("create-dementia-fact-modal", {
        attrs: {
          open: _vm.openCreateDementiaFactModal,
          "existing-dementia-fact": _vm.existingDementiaFact
        },
        on: {
          refetch: function($event) {
            return _vm.$apollo.queries.dementiaFacts.refetch()
          },
          close: _vm.closeCreateDementiaFactModal
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }