<template>
  <div>
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>Archived Learners</h1>
            </v-col>
          </v-row>
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <div class="page-subtitle">
                A place where you can view all archived learners
                associated with your organisation.
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <learners
      :learners="archivedOrganisationLearners"
      :loading="$apollo.queries.archivedOrganisationLearners.loading"
      archived
    />
  </div>
</template>

<script>
import { ArchivedOrganisationLearners } from '@/graphql/Organisation.gql';
import Learners from '@/components/Common/Learners';
import InviteUserAccountModal from '@/components/Common/InviteUserAccountModal';

export default {
  name: 'OrganisationArchivedLearners',
  components: {
    Learners,
    InviteUserAccountModal
  },
  computed: {
    organisationId() {
      return this.$route.params.organisationId;
    }
  },
  mounted() {
    this.$apollo.queries.archivedOrganisationLearners.refetch();
  },
  apollo: {
    archivedOrganisationLearners: {
      query: ArchivedOrganisationLearners,
      variables() {
        return {
          organisationId: this.organisationId
        };
      }
    }
  }
};
</script>
