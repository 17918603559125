<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-3"
          color="primary"
          v-bind="attrs"
          v-on="on"
          :small="dense || !!routeCourseId"
        >
          <v-icon :class="routeCourseId ? '' : 'mr-2'"
            >mdi-plus</v-icon
          >{{ routeCourseId ? 'Add' : 'New' }} Module</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          New Module
        </v-card-title>

        <v-card-text class="py-2">
          <v-form ref="createModuleForm">
            <v-label>Title</v-label>
            <v-text-field
              v-model="title"
              :rules="rules.requiredField"
              label="Title"
              single-line
              outlined
              dense
              required
            />

            <v-row no-gutters>
              <v-col cols="6">
                <v-label>Card Title Colour</v-label>
                <colour-picker
                  label="Card Title Colour"
                  :current-colour="cardTitleColour"
                  @change="cardTitleColour = $event"
                />
              </v-col>

              <v-col cols="6">
                <v-label>Card Subtext Colour</v-label>
                <colour-picker
                  label="Card Subtext Colour"
                  :current-colour="cardSubtextColour"
                  @change="cardSubtextColour = $event"
                />
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="6">
                <v-label>Card Background Colour</v-label>
                <colour-picker
                  label="Card Background Colour"
                  :current-colour="cardBackgroundColour"
                  @change="cardBackgroundColour = $event"
                />
              </v-col>

              <v-col cols="6">
                <v-label>Progress Fill Colour</v-label>
                <colour-picker
                  label="Progress Fill Colour"
                  :current-colour="progressFillColour"
                  @change="progressFillColour = $event"
                />
              </v-col>
            </v-row>

            <v-label>Course</v-label>
            <v-select
              v-model="courseId"
              :rules="rules.requiredField"
              :items="courseItems"
              :disabled="!!routeCourseId"
              label="Course"
              single-line
              outlined
              dense
              required
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="secondary"
            :disabled="creatingModule"
            :loading="creatingModule"
            @click="createModule"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { CreateModule } from '@/graphql/Module.gql';
import ColourPicker from '@/components/Common/ColourPicker';
import {
  textColour,
  subTextColour,
  backgroundColour,
  progressFillColour
} from '@/utils/defaultColours';
import { CourseContent } from '@/graphql/CourseContent.gql';

export default {
  name: 'CreateModuleModal',
  components: { ColourPicker },
  props: {
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false,
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ]
    },
    title: '',
    cardTitleColour: textColour,
    cardSubtextColour: subTextColour,
    cardBackgroundColour: backgroundColour,
    progressFillColour: progressFillColour,
    visible: true,
    disabled: false,
    courseId: null,
    creatingModule: false
  }),
  watch: {
    dialog(dialog) {
      this.resetForm();

      if (!dialog) {
        this.$refs.createModuleForm.reset();
      } else {
        if (this.routeCourseId) {
          this.courseId = this.routeCourseId;
        }
      }
    }
  },
  computed: {
    ...mapState('user', ['currentUserAccount']),
    courseContentId() {
      return this.$route.params.courseContentId;
    },
    routeCourseId() {
      return this.$route.params.courseId;
    },
    coursesToDisplay() {
      if (this.$apollo.queries.courseContent.loading) {
        return [];
      }

      let courses = [];

      for (const courseGroup of this.courseContent.courseGroups) {
        for (const course of courseGroup.courses) {
          courses.push(course);
        }
      }

      return courses;
    },
    courseItems() {
      return (this.coursesToDisplay ?? []).map(course => ({
        text: course.title,
        value: course.id
      }));
    }
  },
  apollo: {
    courseContent: {
      query: CourseContent,
      variables() {
        return {
          id: this.$route.params.courseContentId
        };
      }
    }
  },
  methods: {
    resetForm() {
      this.title = '';
      this.cardTitleColour = textColour;
      this.cardSubtextColour = subTextColour;
      this.cardBackgroundColour = backgroundColour;
      this.progressFillColour = progressFillColour;
      this.visible = true;
      this.disabled = false;
      this.courseId = null;
      this.creatingModule = false;
    },
    async createModule() {
      if (this.$refs.createModuleForm.validate()) {
        try {
          this.creatingModule = true;
          await this.$apollo.mutate({
            mutation: CreateModule,
            variables: {
              module: {
                title: this.title,
                cardTitleColour: this.cardTitleColour,
                cardSubtextColour: this.cardSubtextColour,
                cardBackgroundColour: this.cardBackgroundColour,
                progressFillColour: this.progressFillColour
              },
              courseId: this.courseId
            }
          });

          this.$emit('refetch');
          this.dialog = false;
        } catch (e) {
          e.graphQLErrors.map(x => this.$toast.error(x.message));
        } finally {
          this.creatingModule = false;
        }
      }
    }
  }
};
</script>
