<template>
    <div class="px-4 pt-8 d-flex flex-column">
      <div class="flex-grow-1">
        <v-row no-gutters justify="center" class="dark-blue-text">
          <h2>Reset Password</h2>
        </v-row>
        
        <v-row no-gutters justify="center" class="font-weight-light dark-blue-text text-center">
          <p>
            A password reset link has been sent to<br>
            <span class="font-weight-bold">{{ email }}</span>
          </p>
        </v-row>
        
        <v-row no-gutters justify="center" class="font-weight-light dark-blue-text text-center">
          <p>
            If you don't receive a password reset email in the next 5 minutes, please <span class="font-weight-bold">contact support</span>.
          </p>
        </v-row>

        <v-row class="mt-6" justify="center">
          <v-btn
            color="primary"
            large
            class="login-button"
            @click="$emit('back-to-login')"
          >
            Back to login
          </v-btn>
        </v-row>
      </div>

      <v-row class="mt-auto pb-6 flex-grow-0" justify="center">
        <span class="font-weight-light dark-blue-text">
          Need some help? 
          <a
            href="https://www.dementia.org.au/professionals/professional-development-and-training/ask-annie/ask-annie-support"
            class="toggle-link"
            target="_blank"
          >
            Contact us
          </a>
        </span>
      </v-row>
    </div>
</template>

<script>
export default {
  name: 'ResetPasswordSent',
  props: {
    email: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.toggle-link {
  color: #113247;
  text-decoration: underline;
}

.dark-blue-text {
  color: #113247;
}

.login-button {
  width: 100%;
  max-width: 200px;
}
</style>
