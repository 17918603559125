import enumToDisplay from '@/utils/enumToDisplay';

const BranchingNodeType = Object.freeze({
  QUESTION: 'QUESTION',
  EXIT_WITH_FEEDBACK: 'EXIT_WITH_FEEDBACK',
  EXIT_WITHOUT_FEEDBACK: 'EXIT_WITHOUT_FEEDBACK'
});

export const branchingNodeTypeItems = enumToDisplay(
  BranchingNodeType
);

export default BranchingNodeType;
