var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { color: "primary", small: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-plus")]), _vm._v(" Answer")],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "rounded-lg" },
            [
              _c(
                "v-card-title",
                { staticClass: "h6 font-weight-bold primary--text" },
                [_vm._v(" New Option ")]
              ),
              _c(
                "v-card-text",
                { staticClass: "py-2" },
                [
                  _c(
                    "v-form",
                    { ref: "createImageOptionForm" },
                    [
                      _c("v-label", [_vm._v("Option")]),
                      _c("v-file-input", {
                        ref: "fileInput",
                        attrs: {
                          label: "Option",
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          required: ""
                        },
                        on: { change: _vm.validateFile },
                        model: {
                          value: _vm.input.file,
                          callback: function($$v) {
                            _vm.$set(_vm.input, "file", $$v)
                          },
                          expression: "input.file"
                        }
                      }),
                      _vm.input.file
                        ? _c(
                            "div",
                            { staticClass: "mt-3" },
                            [
                              _c("v-label", [_vm._v("Image Name")]),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Name",
                                  "single-line": "",
                                  outlined: "",
                                  dense: "",
                                  required: ""
                                },
                                model: {
                                  value: _vm.input.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.input, "name", $$v)
                                  },
                                  expression: "input.name"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-switch", {
                        staticClass: "mt-1",
                        attrs: { label: "Correct", inset: "" },
                        model: {
                          value: _vm.correct,
                          callback: function($$v) {
                            _vm.correct = $$v
                          },
                          expression: "correct"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.isLoading
                ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
                : _vm._e(),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary" },
                      on: { click: _vm.createImage }
                    },
                    [_vm._v(" Create ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }