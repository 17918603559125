<template>
  <div>
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>Bundles</h1>
            </v-col>
          </v-row>
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <div class="page-subtitle">
                A place where you can view all the bundles associated
                with your organisation.
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="ml-auto">
          <v-btn color="primary" @click="createModalOpen = true">
            <v-icon class="mr-2">mdi-plus</v-icon>New Bundle</v-btn
          >
        </v-col>
      </v-row>
    </v-container>

    <div>
      <div class="page-sub-sec">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          outlined
          dense
          hide-details
          class="shrink"
        />
      </div>
      <v-data-table
        :items="filteredBundles"
        :headers="headers"
        class="dark--text rounded-b-lg rounded-t-0"
        :no-data-text="
          `There are no bundles${
            search ? ' that match your search' : ''
          }`
        "
        :loading="$apollo.queries.bundles.loading"
        loading-text="Loading..."
        item-key="id"
        @click:row="
          item =>
            $router.push(
              `/organisation/${organisationId}/bundles/${item.id}`
            )
        "
      >
        <template v-slot:[`item.bundleCode`]="{ item }">
          <v-chip class="text-button">{{ item.bundleCode }}</v-chip>
        </template>

        <template v-slot:[`item.active`]="{ item }">
          <v-switch
            class="my-0"
            v-model="item.active"
            @click="archiveBundle(item.id)"
            :disabled="!item.organisation"
            hide-details
            inset
          />
        </template>

        <template v-slot:[`item.editColumn`]="{ item }">
          <v-chip
            v-if="!item.organisation"
            color="primary"
            class="text-caption mr-1"
            >Managed by DA</v-chip
          >
          <v-btn
            icon
            @click="
              $router.push(
                `/organisation/${organisationId}/bundles/${item.id}`
              )
            "
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <create-bundle-modal
      :open="createModalOpen"
      :organisation-id="organisationId"
      @close="createModalOpen = false"
      @created="onCreated"
    />
  </div>
</template>

<script>
import { Bundles, ArchiveBundle } from '@/graphql/Bundle.gql';
import CreateBundleModal from '@/components/Bundle/CreateBundleModal';
import { objectHasSearch } from '@/utils/search';

export default {
  name: 'OrganisationBundles',
  components: {
    CreateBundleModal
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Name', value: 'name' },
      {
        text: 'Bundle Code',
        value: 'bundleCode'
      },
      {
        text: 'Active',
        value: 'active'
      },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ],
    createModalOpen: false
  }),
  computed: {
    organisationId() {
      return this.$route.params.organisationId;
    },
    filteredBundles() {
      return this.search
        ? this.bundles.filter(site =>
            objectHasSearch(site, this.search)
          )
        : this.bundles;
    }
  },
  apollo: {
    bundles: {
      query: Bundles,
      variables() {
        return {
          organisationId: this.organisationId
        };
      }
    }
  },
  methods: {
    onCreated() {
      this.$apollo.queries.bundles.refetch();
      this.createModalOpen = false;
    },
    async archiveBundle(id) {
      try {
        await this.$apollo.mutate({
          mutation: ArchiveBundle,
          variables: {
            id: id
          }
        });
        await this.$apollo.queries.bundles.refetch();
        this.$toast.success(
          'Bundle active status updated successfully'
        );
      } catch (e) {
        e.graphQLErrors.map(x => this.$toast.error(x.message));
      }
    }
  }
};
</script>
