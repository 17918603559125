var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-login-page",
    [
      _c(
        "v-row",
        { staticClass: "pa-4", attrs: { justify: "center" } },
        [
          !_vm.showResetSent
            ? _c("login-form", {
                on: { "reset-email-sent": _vm.handleResetEmailSent }
              })
            : _c("reset-password-sent", {
                attrs: { email: _vm.resetEmail },
                on: {
                  "back-to-login": function($event) {
                    _vm.showResetSent = false
                  }
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }