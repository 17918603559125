var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "primary--text mb-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mr-auto", attrs: { cols: "auto" } },
                        [_c("h1", [_vm._v("Staff")])]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mr-auto", attrs: { cols: "auto" } },
                        [
                          _c("div", { staticClass: "page-subtitle" }, [
                            _vm._v(
                              " A place where you can view all staff associated with your organisation. "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "ml-auto d-flex", attrs: { cols: "auto" } },
                [
                  _c("invite-user-account-modal", {
                    attrs: { "organisation-id": _vm.organisationId, staff: "" },
                    on: {
                      refetch: function($event) {
                        return _vm.$apollo.queries.organisationStaff.refetch()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("organisation-staff-table", {
        attrs: {
          staff: _vm.organisationStaff,
          loading: _vm.$apollo.queries.organisationStaff.loading
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }