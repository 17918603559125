<template>
  <div>
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>Learners</h1>
            </v-col>
          </v-row>
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <div class="page-subtitle">
                A list of learners that currently share their data.
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="ml-auto d-flex">
          <invite-user-account-modal
            @refetch="$apollo.queries.allLearners.refetch()"
          />
        </v-col>
      </v-row>
    </v-container>

    <learners
      :learners="allLearners"
      :loading="$apollo.queries.allLearners.loading"
    />
  </div>
</template>

<script>
import { AllLearners } from '@/graphql/UserAccount.gql';
import Learners from '@/components/Common/Learners';
import InviteUserAccountModal from '@/components/Common/InviteUserAccountModal';

export default {
  name: 'UserList',
  components: {
    Learners,
    InviteUserAccountModal
  },
  mounted() {
    this.$apollo.queries.allLearners.refetch();
  },
  apollo: {
    allLearners: {
      query: AllLearners
    }
  }
};
</script>
