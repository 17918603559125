var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { staticClass: "d-flex flex-column", attrs: { cols: "10" } },
    [
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "px-4 pt-8 flex-grow-1",
          on: {
            input: _vm.validateForm,
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleResetPassword.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "dark-blue-text",
              attrs: { "no-gutters": "", justify: "center" }
            },
            [_c("h2", [_vm._v("Reset Password")])]
          ),
          _c("v-text-field", {
            staticClass: "mt-3 mb-4",
            attrs: {
              type: "password",
              outlined: "",
              placeholder: "Enter new password",
              rules: _vm.passwordRules,
              "hide-details": "false",
              disabled: _vm.loading
            },
            model: {
              value: _vm.password,
              callback: function($$v) {
                _vm.password = $$v
              },
              expression: "password"
            }
          }),
          _c("v-text-field", {
            attrs: {
              type: "password",
              outlined: "",
              placeholder: "Confirm password",
              rules: _vm.confirmPasswordRules,
              "hide-details": "false",
              disabled: _vm.loading
            },
            model: {
              value: _vm.confirmPassword,
              callback: function($$v) {
                _vm.confirmPassword = $$v
              },
              expression: "confirmPassword"
            }
          }),
          _vm.message
            ? _c(
                "v-row",
                { staticClass: "mt-4", attrs: { justify: "center" } },
                [
                  _c("span", { class: _vm.messageClass }, [
                    _vm._v(_vm._s(_vm.message))
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "v-row",
            { staticClass: "mt-6", attrs: { justify: "center" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "login-button",
                  attrs: {
                    color: "primary",
                    large: "",
                    type: "submit",
                    loading: _vm.loading,
                    disabled: _vm.loading
                  }
                },
                [_vm._v(" Reset ")]
              )
            ],
            1
          ),
          _c("v-row", { staticClass: "mt-6", attrs: { justify: "center" } }, [
            _c(
              "a",
              {
                staticClass: "toggle-link font-weight-light",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    return _vm.$router.push("/login")
                  }
                }
              },
              [_vm._v(" Go back to login ")]
            )
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "pb-6 flex-grow-0", attrs: { justify: "center" } },
        [
          _c("span", { staticClass: "font-weight-light dark-blue-text" }, [
            _vm._v(" Need some help? "),
            _c(
              "a",
              {
                staticClass: "toggle-link text-decoration-underline",
                attrs: {
                  href:
                    "https://www.dementia.org.au/professionals/professional-development-and-training/ask-annie/ask-annie-support",
                  target: "_blank"
                }
              },
              [_vm._v(" Contact us ")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }