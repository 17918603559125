const UserRoles = Object.freeze({
  USER: 'USER',
  SITE_VIEWER: 'SITE_VIEWER',
  SITE_ADMIN: 'SITE_ADMIN',
  ORG_VIEWER: 'ORG_VIEWER',
  ORG_ADMIN: 'ORG_ADMIN'
});

export const siteRoles = [UserRoles.SITE_VIEWER, UserRoles.SITE_ADMIN]

export const organisationRoles = [UserRoles.ORG_VIEWER, UserRoles.ORG_ADMIN]

export default UserRoles;
