var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-sub-sec" },
        [
          _c("v-text-field", {
            staticClass: "shrink",
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search",
              outlined: "",
              dense: "",
              "hide-details": ""
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "dark--text rounded-b-lg rounded-t-0",
        attrs: {
          items: _vm.filteredStaff,
          headers: _vm.headers,
          "no-data-text": `There are no staff${
            _vm.search ? " that match your search" : ""
          }`,
          loading: _vm.loading,
          "loading-text": "Loading...",
          "item-key": "id"
        },
        on: { "click:row": item => _vm.$router.push(`/staff/${item.id}`) },
        scopedSlots: _vm._u(
          [
            {
              key: `item.firstName`,
              fn: function({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s([item.firstName, item.lastName].join(" ")) +
                      " "
                  )
                ]
              }
            },
            {
              key: `item.email`,
              fn: function({ item }) {
                return [_vm._v(" " + _vm._s(item.email) + " ")]
              }
            },
            {
              key: `item.memberRole`,
              fn: function({ item }) {
                return [
                  _c("span", { staticClass: "text-capitalize" }, [
                    _vm._v(
                      _vm._s(item.systemRole.replaceAll("_", " ").toLowerCase())
                    )
                  ])
                ]
              }
            },
            {
              key: `item.editColumn`,
              fn: function({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push(`/staff/${item.id}`)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }