<template>
  <div>
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>Ask Annie Anything</h1>
            </v-col>
          </v-row>
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <div class="page-subtitle">
                Upload, validate, and activate Ask Annie Anything
                configurations for all users.
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters class="my-4">
        <v-col><h2 class="pt-4 pl-3">Configurations</h2></v-col>
        <v-col cols="auto" class="ml-auto">
          <v-btn class="ml-2" color="primary" @click="configModalOpen = true">
            <v-icon class="mr-2">mdi-upload-outline</v-icon>
            Upload Config
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-data-table
            :items-per-page="5"
            :items="configs"
            :headers="configTableHeaders"
            class="dark--text rounded-b-lg rounded-t-0"
            no-data-text="There are no configurations."
            loading-text="Loading..."
            item-key="id"
          >
            <template v-slot:[`item.problemsColumn`]="{ item }">
              <v-chip class="pl-1 error text--white" v-if="errorCount(item) > 0"><v-icon white>dangerous</v-icon>{{ errorCount(item) }} errors</v-chip>
              <v-chip class="pl-1 orange accent-3 darken-1 white--text" v-if="warningCount(item) > 0" @click="warningsDialogOpenFor = item">
                <v-icon class="mr-1" color="white">error</v-icon>{{ warningCount(item) }} warnings
              </v-chip>
            </template>
            <template v-slot:[`item.uploadedBy`]="{ item }">
              {{ uploaderName(item.uploadedBy) }}
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ formatDateTime(item.createdAt) }}
            </template>
            <template v-slot:[`item.state`]="{ item }">
              <v-chip
                class="pr-1 white--text font-weight-bold caption"
                :color="configStates[item.state].color">
                {{ configStates[item.state].displayName }}
                <v-icon
                  @click="openStatusMenu = openStatusMenu == item.id ? null : item.id"
                  class="ml-1">
                  expand_circle_down
                </v-icon>
              </v-chip>
              <v-col v-if="openStatusMenu == item.id" class="pa-6 mt-1 rounded-xxl state-dropdown">
                <v-row class="justify-end" v-for="(state, index) in Object.values(configStates).filter(s => s.enumValue !== item.state)" :key="index">
                  <v-chip :class="index != 0 ? 'mt-2' : ''" class="white--text font-weight-bold caption" :color="state.color" @click="updateState(item, state)">
                    {{ state.displayName }}
                  </v-chip>
                </v-row>
              </v-col>
            </template>
            <template v-slot:[`item.mediaProblems`]="{ item }">
              <v-chip class="white--text pl-1" v-if="item.unmetMediaDependencies.length <= 0" color="success">
                <v-icon class="mr-1">check_circle</v-icon> All met!
              </v-chip>
              <v-chip v-else class="orange accent-3 darken-1 white--text pl-1" @click="missingMediaDialogOpenFor = item">
                <v-icon class="mr-1" color="white">error</v-icon> {{ item.unmetMediaDependencies.length }} missing
              </v-chip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row no-gutters class="mt-8 mb-4">
        <v-col><h2 class="pt-4 pl-3">Media</h2></v-col>
        <v-col cols="auto" class="ml-auto">
          <v-btn color="primary" @click="mediaModalOpen = true">
            <v-icon class="mr-1">mdi-upload-outline</v-icon>
            Upload Media
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-data-table
            :items-per-page="5"
            :items="media"
            :headers="mediaTableHeaders"
            class="dark--text rounded-b-lg rounded-t-0"
            no-data-text="There is no media."
            loading-text="Loading..."
            item-key="id"
          >
            <template v-slot:[`item.type`]="{ item }">
              <col>
                <v-icon>
                  {{ mediaTypeByEnumValue(item.mediaType).icon }}
                </v-icon>
                {{ mediaTypeByEnumValue(item.mediaType).name }}
              </col>
            </template>
            <template v-slot:[`item.uploadedBy`]="{ item }">
              {{ uploaderName(item.uploadedBy) }}
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ formatDateTime(item.createdAt) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <create-aaa-config-modal
      :open="configModalOpen"
      @close="configModalOpen = false"
      @success="() => {
        configModalOpen = false;
        $apollo.queries.configs.refetch();
      }"
    />
    <create-aaa-media-modal
      :open="mediaModalOpen"
      @close="mediaModalOpen = false"
      @success="() => {
        mediaModalOpen = false;
        $apollo.queries.media.refetch();
      }"
    />
    <warnings-dialog
      :open="warningsDialogOpenFor != null"
      :warnings="warningsDialogOpenFor?.validationResults?.warnings ?? []"
      @close="warningsDialogOpenFor = null"
    />
    <missing-media-dialog
      :open="missingMediaDialogOpenFor != null"
      :missingMedia="missingMediaDialogOpenFor?.unmetMediaDependencies ?? []"
      @close="missingMediaDialogOpenFor = null"
    />
  </div>
</template>

<script>
import CreateAaaConfigModal from '@/components/Aaa/CreateAaaConfigModal';
import CreateAaaMediaModal from '@/components/Aaa/CreateAaaMediaModal';
import WarningsDialog from '@/components/Aaa/WarningsDialog.vue';
import { FetchAaaConfigs, SetActiveAaaConfig, SetTestingAaaConfig, SetAaaConfigInactive } from '@/graphql/AaaConfig.gql';
import { FetchAaaMedia } from '@/graphql/AaaMedia.gql';
import AaaConfig from '@/models/AaaConfig';
import AaaMedia from '@/models/AaaMedia';
import {
  mediaTypeByEnumValue
} from '@/utils/aaa';
import {formatDateTime} from '@/utils/date';
import MissingMediaDialog from '../../components/Aaa/MissingMediaDialog.vue';

export default {
  name: 'AaaConfig',
  components: {
    CreateAaaConfigModal,
    CreateAaaMediaModal,
    WarningsDialog,
    MissingMediaDialog,
  },
  data: () => ({
    configTableHeaders: [
      { text: 'Name', value: 'name' },
      { text: 'Version', value: 'version' },
      { text: 'Problems', value: 'problemsColumn' },
      { text: 'Media', value: 'mediaProblems' },
      { text: 'Status', value: 'state' },
      { text: 'Uploaded By', value: 'uploadedBy' },
      { text: 'Created At', value: 'createdAt' }
    ],
    mediaTableHeaders: [
      { text: 'Media Type', value: 'type' },
      { text: 'Name', value: 'name' },
      { text: 'Uploaded By', value: 'uploadedBy' },
      { text: 'Created At', value: 'createdAt' }
    ],
    openStatusMenu: null,
    warningsDialogOpenFor: null,
    missingMediaDialogOpenFor: null,
    configModalOpen: false,
    mediaModalOpen: false
  }),
  apollo: {
    configs: {
      query: FetchAaaConfigs,
      fetchPolicy: 'network-only',
      update: data => {
        return data.aaaConfigs.map(d => new AaaConfig(d));
      }
    },
    media: {
      query: FetchAaaMedia,
      fetchPolicy: 'network-only',
      update: data => {
        return data.aaaMedia.map(d => new AaaMedia(d));
      }
    }
  },
  created() {
    this.$apollo.queries.configs.refetch();
    this.$apollo.queries.media.refetch();
  },
  computed: {
    configStates() {
      return {
        'ACTIVE': {displayName: 'Active', icon: 'check_circle_outline', color: 'green', enumValue: 'ACTIVE'},
        'INACTIVE': {displayName: 'Inactive', icon: 'visibility_off', color: 'grey lighten-1', enumValue: 'INACTIVE'},
        'TESTING': {displayName: 'Testing', icon: 'handyman', color: 'purple', enumValue: 'TESTING'},
      }
    }
  },
  methods: {
    mediaTypeByEnumValue, formatDateTime,
    errorCount(item) {
      return item.validationResults?.errors?.length || 0;
    },
    warningCount(item) {
      return item.validationResults?.warnings?.length || 0;
    },
    uploaderName(uploadedBy) {
      var formattedName = (uploadedBy.firstName + " " + uploadedBy.lastName).trim();
      return  formattedName.length > 0 ? formattedName : uploadedBy.email;
    },
    async updateState(item, state) {
      if (state.enumValue === 'ACTIVE') {
        await this.$apollo.mutate({
          mutation: SetActiveAaaConfig,
          variables: {
            id: item.id
          }
        })
      } else if (state.enumValue === 'TESTING') {
        await this.$apollo.mutate({
          mutation: SetTestingAaaConfig,
          variables: {
            id: item.id
          }
        })
      } else if (state.enumValue === 'INACTIVE') {
        await this.$apollo.mutate({
          mutation: SetAaaConfigInactive,
          variables: {
            id: item.id
          }
        });
      } else {
        console.error('Invalid state');
        throw new Error('Invalid state');
      }
      this.openStatusMenu = null;
      this.$apollo.queries.configs.refetch();
    }
  }
};
</script>
<style scoped>
.state-dropdown {
  position: absolute;
  z-index: 1;
  background-color: white;
  width: auto;
  border: 1px solid lightgray;
}
</style>
