var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.open, width: "500" },
      on: {
        "click:outside": function($event) {
          return _vm.cancel()
        }
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "rounded-lg" },
        [
          _c(
            "v-card-title",
            { staticClass: "h6 font-weight-bold primary--text" },
            [_vm._v(" Create Ask Annie Anything Configuration ")]
          ),
          _c(
            "v-stepper",
            {
              staticClass: "mt-4",
              model: {
                value: _vm.step,
                callback: function($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "1" } },
                    [
                      _vm.loading
                        ? _c("v-progress-linear", {
                            attrs: { indeterminate: "" }
                          })
                        : _c(
                            "div",
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("v-file-input", {
                                    attrs: {
                                      label: "Upload XLSX File",
                                      accept: ".xlsx"
                                    },
                                    on: { change: _vm.fileChanged },
                                    model: {
                                      value: _vm.input.file,
                                      callback: function($$v) {
                                        _vm.$set(_vm.input, "file", $$v)
                                      },
                                      expression: "input.file"
                                    }
                                  }),
                                  _c("div", [
                                    _vm.validationResult.errors.length > 0
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "v-card-title",
                                              { staticClass: "pb-1" },
                                              [
                                                _vm._v(
                                                  " Errors (" +
                                                    _vm._s(
                                                      _vm.validationResult
                                                        .errors.length
                                                    ) +
                                                    ") "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-card-text",
                                              { staticClass: "pb-1" },
                                              [
                                                _vm._v(
                                                  " Please fix the following and try again. "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-list",
                                              _vm._l(
                                                _vm.validationResult.errors,
                                                function(error) {
                                                  return _c(
                                                    "v-list-item",
                                                    {
                                                      key: error,
                                                      staticClass: "body-2"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "red--text darken-2 mr-2"
                                                        },
                                                        [_vm._v(" error ")]
                                                      ),
                                                      _c("span", [
                                                        _vm._v(_vm._s(error))
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.validationResult.warnings.length > 0
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "v-card-title",
                                              { staticClass: "pb-1" },
                                              [
                                                _vm._v(
                                                  " Warnings (" +
                                                    _vm._s(
                                                      _vm.validationResult
                                                        .warnings.length
                                                    ) +
                                                    ") "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-list",
                                              _vm._l(
                                                _vm.validationResult.warnings,
                                                function(warning) {
                                                  return _c(
                                                    "v-list-item",
                                                    {
                                                      key: warning,
                                                      staticClass: "body-2"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "orange--text darken-1 mr-2"
                                                        },
                                                        [_vm._v(" error ")]
                                                      ),
                                                      _c("span", [
                                                        _vm._v(_vm._s(warning))
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                      _c(
                        "v-card-actions",
                        { staticClass: "justify-end" },
                        [
                          _vm.error
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "d-flex align-center pa-2 red--text"
                                },
                                [
                                  _c("v-icon", { staticClass: "red--text" }, [
                                    _vm._v("error")
                                  ]),
                                  _vm._v("  " + _vm._s(_vm.error) + " ")
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.cancel()
                                }
                              }
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _vm.fileValidated
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function($event) {
                                      _vm.step = 2
                                    }
                                  }
                                },
                                [_vm._v(" Continue ")]
                              )
                            : _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.loading || !_vm.input.file
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.doFunc(_vm.upload)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.validationResult.errors.length > 0
                                          ? "Try Again"
                                          : "Upload"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "2" } },
                    [
                      _vm.loading
                        ? _c("v-progress-linear", {
                            attrs: { indeterminate: "" }
                          })
                        : _c(
                            "div",
                            [
                              _c("v-text-field", {
                                attrs: { label: "Name" },
                                model: {
                                  value: _vm.input.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.input, "name", $$v)
                                  },
                                  expression: "input.name"
                                }
                              }),
                              _c("v-text-field", {
                                attrs: { label: "Version" },
                                model: {
                                  value: _vm.input.version,
                                  callback: function($$v) {
                                    _vm.$set(_vm.input, "version", $$v)
                                  },
                                  expression: "input.version"
                                }
                              })
                            ],
                            1
                          ),
                      _c(
                        "v-card-actions",
                        { staticClass: "justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.cancel()
                                }
                              }
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.doFunc(_vm.save)
                                }
                              }
                            },
                            [_vm._v(" Save & Finish ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }