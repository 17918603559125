var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.open, width: "500" },
      on: {
        "click:outside": function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "rounded-lg" },
        [
          _c(
            "v-card-title",
            { staticClass: "h6 font-weight-bold primary--text" },
            [_vm._v(" Create Media ")]
          ),
          _c(
            "v-card-text",
            [
              _c("v-file-input", {
                ref: "fileInput",
                attrs: {
                  label: "Upload File",
                  "error-messages": _vm.fileValidationErrors,
                  validation: () => {},
                  rules: [
                    v => v != undefined || "File is required",
                    v =>
                      !v ||
                      v.size < 5 * 1024 * 1024 ||
                      "File size must be less than 5 MB"
                  ],
                  disabled: _vm.loading
                },
                on: { change: _vm.validateFile },
                model: {
                  value: _vm.input.file,
                  callback: function($$v) {
                    _vm.$set(_vm.input, "file", $$v)
                  },
                  expression: "input.file"
                }
              }),
              _vm.showNameAndTypeForm
                ? _c(
                    "div",
                    [
                      _c("v-text-field", {
                        attrs: { label: "Name", disabled: _vm.loading },
                        model: {
                          value: _vm.input.name,
                          callback: function($$v) {
                            _vm.$set(_vm.input, "name", $$v)
                          },
                          expression: "input.name"
                        }
                      }),
                      _c("v-select", {
                        attrs: {
                          items: _vm.supportedFileTypes.map(t => t.name),
                          label: "Type",
                          disabled: _vm.loading
                        },
                        model: {
                          value: _vm.input.type,
                          callback: function($$v) {
                            _vm.$set(_vm.input, "type", $$v)
                          },
                          expression: "input.type"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.processErrors.length > 0
                ? _c(
                    "div",
                    { staticClass: "red--text" },
                    _vm._l(_vm.processErrors, function(error) {
                      return _c(
                        "p",
                        { key: error },
                        [
                          _c("v-icon", { staticClass: "red--text" }, [
                            _vm._v("error")
                          ]),
                          _vm._v(" " + _vm._s(error))
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.loading
                ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { disabled: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: _vm.loading },
                  on: { click: _vm.createMedia }
                },
                [_vm._v(" Done ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }