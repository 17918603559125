var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "px-4 pt-8 d-flex flex-column" },
    [
      _c(
        "div",
        { staticClass: "flex-grow-1" },
        [
          _c(
            "v-row",
            {
              staticClass: "dark-blue-text",
              attrs: { "no-gutters": "", justify: "center" }
            },
            [_c("h2", [_vm._v("Reset Password")])]
          ),
          _c(
            "v-row",
            {
              staticClass: "font-weight-light dark-blue-text text-center",
              attrs: { "no-gutters": "", justify: "center" }
            },
            [
              _c("p", [
                _vm._v(" A password reset link has been sent to"),
                _c("br"),
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.email))
                ])
              ])
            ]
          ),
          _c(
            "v-row",
            {
              staticClass: "font-weight-light dark-blue-text text-center",
              attrs: { "no-gutters": "", justify: "center" }
            },
            [
              _c("p", [
                _vm._v(
                  " If you don't receive a password reset email in the next 5 minutes, please "
                ),
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("contact support")
                ]),
                _vm._v(". ")
              ])
            ]
          ),
          _c(
            "v-row",
            { staticClass: "mt-6", attrs: { justify: "center" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "login-button",
                  attrs: { color: "primary", large: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("back-to-login")
                    }
                  }
                },
                [_vm._v(" Back to login ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "mt-auto pb-6 flex-grow-0",
          attrs: { justify: "center" }
        },
        [
          _c("span", { staticClass: "font-weight-light dark-blue-text" }, [
            _vm._v(" Need some help? "),
            _c(
              "a",
              {
                staticClass: "toggle-link",
                attrs: {
                  href:
                    "https://www.dementia.org.au/professionals/professional-development-and-training/ask-annie/ask-annie-support",
                  target: "_blank"
                }
              },
              [_vm._v(" Contact us ")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }