import SystemRoles from '@/enums/SystemRoles';
import UserRoles from '@/enums/UserRoles';

export const mainNavigationItems = [
  {
    title: 'User Management',
    systemRole: SystemRoles.DEMENTIA_ADMIN,
    children: [
      {
        title: 'Organisations',
        to: '/organisations',
        icon: 'mdi-domain'
      },
      {
        title: 'Learners',
        to: '/learners',
        icon: 'mdi-school-outline'
      },
      {
        title: 'Staff',
        to: '/staff',
        icon: 'mdi-account-group-outline'
      }
    ]
  },
  {
    title: 'Course Data',
    systemRole: SystemRoles.CMS_ADMIN,
    children: [
      {
        title: 'Publish',
        to: '/publish',
        icon: 'mdi-folder-arrow-right-outline'
      },
      {
        title: 'Courses',
        to: '/courses',
        icon: 'mdi-folder-multiple-outline'
      },
      {
        title: 'Modules',
        to: '/modules',
        icon: 'mdi-folder-outline'
      },
      {
        title: 'Lessons',
        to: '/lessons',
        icon: 'mdi-file-document-outline'
      }
    ]
  },
  {
    title: 'Bundle Management',
    systemRole: SystemRoles.CMS_ADMIN,
    children: [
      {
        title: 'Bundles',
        to: '/bundles',
        icon: 'mdi-bookmark-box-multiple-outline'
      }
    ]
  },
  {
    title: 'App Instructions',
    systemRole: SystemRoles.CMS_ADMIN,
    children: [
      {
        title: 'Instructions',
        to: '/app-instructions',
        icon: 'mdi-chat-question-outline'
      }
    ]
  },
  {
    title: 'Other Content',
    systemRole: SystemRoles.CMS_ADMIN,
    children: [
      {
        title: 'Dementia Facts',
        to: '/dementia-facts',
        icon: 'mdi-brain'
      },
      {
        title: 'Ask Annie Anything',
        to: '/aaa-config',
        icon: 'mdi-hand-wave-outline'
      }
    ]
  }
];

export const organisationNavigationItems = [
  {
    title: 'Organisation',
    userRoles: [UserRoles.ORG_ADMIN, UserRoles.ORG_VIEWER],
    children: [
      {
        title: 'Overview',
        to: '/overview',
        icon: 'mdi-format-list-bulleted-square'
      }
    ]
  },
  {
    title: 'User Management',
    userRoles: [UserRoles.ORG_ADMIN, UserRoles.ORG_VIEWER],
    children: [
      {
        title: 'Learners',
        to: '/learners',
        icon: 'mdi-school-outline'
      },
      {
        title: 'Staff',
        to: '/staff',
        icon: 'mdi-account-group-outline'
      },
      {
        title: 'Pending Requests',
        to: '/pending-requests',
        icon: 'mdi-bell-ring-outline'
      },
      {
        title: 'Archived Learners',
        to: '/archived-learners',
        icon: 'mdi-folder-cancel-outline'
      }
    ]
  },
  {
    title: 'Organisation Details',
    userRoles: [
      UserRoles.ORG_ADMIN,
      UserRoles.ORG_VIEWER,
      UserRoles.SITE_ADMIN,
      UserRoles.SITE_VIEWER
    ],
    children: [
      {
        title: 'Sites',
        to: '/sites',
        icon: 'mdi-office-building-marker-outline'
      }
    ]
  },
  {
    title: 'Content Management',
    userRoles: [UserRoles.ORG_ADMIN, UserRoles.ORG_VIEWER],
    children: [
      {
        title: 'Bundles',
        to: '/bundles',
        icon: 'mdi-bookmark-box-multiple-outline'
      }
    ]
  }
];

export const courseContentNavigationItems = [
  {
    title: 'Course Data Management',
    systemRole: SystemRoles.CMS_ADMIN,
    children: [
      {
        title: 'Overview',
        to: '/overview',
        icon: 'mdi-information-outline'
      },
      {
        title: 'Course Groups',
        to: '/course-groups',
        icon: 'mdi-format-list-group'
      }
    ]
  },
  {
    title: 'Course Content Resources',
    systemRole: SystemRoles.CMS_ADMIN,
    children: [
      {
        title: 'Courses',
        to: '/courses',
        icon: 'mdi-folder-multiple-outline'
      },
      {
        title: 'Modules',
        to: '/modules',
        icon: 'mdi-folder-outline'
      },
      {
        title: 'Lessons',
        to: '/lessons',
        icon: 'mdi-file-document-outline'
      }
    ]
  }
];
