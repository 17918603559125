<template>
  <base-login-page>
    <v-row justify="center">
      <reset-password-form 
        v-if="!isComplete" 
        :oob-code="oobCode" 
        @reset-complete="handleResetComplete" 
      />
      <reset-password-complete 
        v-else 
        :email="email" 
      />
    </v-row>
  </base-login-page>
</template>

<script>
import BaseLoginPage from './Login/BaseLoginPage.vue';
import ResetPasswordForm from './Login/ResetPasswordForm.vue';
import ResetPasswordComplete from './Login/ResetPasswordComplete.vue';
import { auth } from '@/main';
import { verifyPasswordResetCode } from 'firebase/auth';

export default {
  name: 'ResetPassword',
  components: {
    BaseLoginPage,
    ResetPasswordForm,
    ResetPasswordComplete
  },
  data: () => ({
    isComplete: false
  }),
  computed: {
    oobCode() {
      return this.$route.query.oobCode;
    },
    email() {
      return this.$route.query.email;
    }
  },
  async mounted() {
    if (!this.oobCode) {
      this.$router.push('/login');
      return;
    }

    if (!this.email) {
      this.$router.push('/login');
      return;
    }

    try {
      await verifyPasswordResetCode(auth, this.oobCode);
    } catch (error) {
      console.error('Invalid or expired reset code:', error);
      this.$router.push('/login');
    }
  },
  methods: {
    handleResetComplete() {
      this.isComplete = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.toggle-link {
  color: #113247;
  text-decoration: underline;
}

.dark-blue-text {
  color: #113247;
}
</style>
