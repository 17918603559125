<template>
  <div v-if="!loadingUserAccount">
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col class="ml-n3 mr-1" cols="auto">
              <v-btn color="#00000060" icon @click="$router.go(-1)">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <h1>Overview of {{ bundle?.name }}</h1>
            </v-col>
          </v-row>

          <breadcrumbs :items="['Bundle', bundle?.name]" />
        </v-col>
      </v-row>
    </v-container>

    <div>
      <div class="text--primary mb-3 font-weight-bold text-h6">
        Bundle Overview
      </div>
      <div class="rounded-lg white px-5 py-1 text--primary">
        <v-row no-gutters align="center" class="info-row py-2">
          <v-col cols="auto" class="mr-auto title-column">
            Bundle Name
          </v-col>
          <v-col cols="auto" class="ml-auto value-column">
            <edit-field
              :current-value="bundle?.name"
              @update-value="updateBundleName"
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-2">
          <v-col cols="auto" class="mr-auto title-column">
            Data Sharing Code
          </v-col>
          <v-col cols="auto" class="ml-auto value-column">
            <edit-field
              :current-value="bundle?.bundleCode"
              @update-value="updateBundleCode"
            />
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="mt-8">
      <v-row justify="space-between" align="center">
        <v-col
          cols="auto"
          class="text--primary mb-3 font-weight-bold text-h6"
        >
          Course Management
        </v-col>

        <v-col cols="auto">
          <active-course-selector
            :selected-courses="
              bundle?.courses.map(c => c.referenceId)
            "
            :course-grouping-title="bundle?.name"
            @add-selected-courses="addSelectedCourses"
          />
        </v-col>
      </v-row>

      <v-data-table
        :items="bundle?.courses"
        :headers="headers"
        class="dark--text rounded-b-lg rounded-t-0"
        no-data-text="There are no courses for this bundle"
        :loading="$apollo.queries.bundle?.loading"
        loading-text="Loading..."
        item-key="referenceId"
        @click:row="item => $router.push(`/courses/${item.id}`)"
      >
        <template v-slot:[`item.editColumn`]="{ item }">
          <v-btn icon @click="$router.push(`/courses/${item.id}`)">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <v-btn color="error" icon @click="removeCourse(item.id)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
  <div v-else></div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Bundle,
  AddCourseToBundle,
  RemoveCourseFromBundle,
  UpdateBundle
} from '@/graphql/Bundle.gql';
import Breadcrumbs from '@/components/Common/Breadcrumbs';
import { formatDate } from '@/utils/date';
import ActiveCourseSelector from '@/components/Course/ActiveCourseSelector';
import EditField from '@/components/Common/EditField';

export default {
  name: 'BundleDetailsPage',
  components: {
    Breadcrumbs,
    ActiveCourseSelector,
    EditField
  },
  data: () => ({
    headers: [
      { text: 'Name', value: 'title' },
      { text: 'Version', value: 'version' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ]
  }),
  computed: {
    ...mapState('user', ['currentUserAccount', 'loadingUserAccount']),
    organisationId() {
      return this.$route.params.organisationId;
    },
    bundleId() {
      return this.$route.params.bundleId;
    },
    defaultBundle() {
      return {
        id: this.bundle?.id,
        name: this.bundle?.name,
        bundleCode: this.bundle?.bundleCode,
        active: this.bundle?.active,
        organisation: this.bundle?.organisation.id
      };
    }
  },
  apollo: {
    bundle: {
      query: Bundle,
      variables() {
        return {
          id: this.bundleId
        };
      }
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    async addSelectedCourses(courses) {
      for (const courseId of courses) {
        try {
          await this.$apollo.mutate({
            mutation: AddCourseToBundle,
            variables: {
              courseId: courseId,
              bundleId: this.bundleId
            }
          });
        } catch {
          this.$toast.error(
            'There was a problem adding the course to the bundle, please try again.'
          );
        }
      }

      this.$apollo.queries.bundle?.refetch();
    },
    async removeCourse(courseId) {
      try {
        await this.$apollo.mutate({
          mutation: RemoveCourseFromBundle,
          variables: {
            courseId: courseId,
            bundleId: this.bundleId
          }
        });

        this.$apollo.queries.bundle?.refetch();
      } catch {
        this.$toast.error(
          'There was a problem removing the course from the bundle, please try again.'
        );
      }
    },
    async updateBundle(bundle) {
      try {
        await this.$apollo.mutate({
          mutation: UpdateBundle,
          variables: {
            bundle: bundle
          }
        });

        await this.$apollo.queries.bundle.refetch();
      } catch (e) {
        e.graphQLErrors.map(x => this.$toast.error(x.message));
      }
    },
    async updateBundleName(name) {
      await this.updateBundle({
        ...this.defaultBundle,
        name: name
      });
    },
    async updateBundleCode(code) {
      await this.updateBundle({
        ...this.defaultBundle,
        bundleCode: code
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.info-row {
  font-size: 14px;

  .title-column {
    font-weight: 500;
  }

  .value-column {
    font-weight: 300;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>
