var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-sub-sec" },
        [
          _c("v-text-field", {
            staticClass: "shrink",
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search",
              outlined: "",
              dense: "",
              "hide-details": ""
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "dark--text rounded-b-lg rounded-t-0",
        attrs: {
          items: _vm.filteredCourseGroup,
          headers: _vm.headers,
          "no-data-text": `There are no course groups${
            _vm.search ? " that match your search" : ""
          }`,
          loading: _vm.loading,
          "loading-text": "Loading...",
          "item-key": "id"
        },
        on: {
          "click:row": item =>
            _vm.$router.push(
              `/course-content/${_vm.courseContentId}/course-groups/${item.id}`
            )
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.updatedAt`,
              fn: function({ item }) {
                return [
                  _vm._v(" " + _vm._s(_vm.formatDate(item.updatedAt)) + " ")
                ]
              }
            },
            {
              key: `item.editColumn`,
              fn: function({ item, index }) {
                return [
                  index != 0
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "pa-0 rounded-md",
                          attrs: {
                            text: "",
                            icon: "",
                            disabled: _vm.changingDisplayIndex
                          },
                          on: {
                            click: function($event) {
                              return _vm.decreaseDisplayIndex(index)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-arrow-up")])],
                        1
                      )
                    : _vm._e(),
                  _vm.filteredCourseGroup.length - 1 != index
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "pa-0 rounded-md",
                          attrs: {
                            text: "",
                            icon: "",
                            disabled: _vm.changingDisplayIndex
                          },
                          on: {
                            click: function($event) {
                              return _vm.increaseDisplayIndex(index)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-arrow-down")])],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pa-0 rounded-md",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push(
                            `/course-content/${_vm.courseContentId}/course-groups/${item.id}`
                          )
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                    1
                  ),
                  _c("delete-button", {
                    on: {
                      delete: function($event) {
                        return _vm.deleteCourseGroup(item.id)
                      }
                    }
                  })
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }