<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="small"
          color="white"
          icon
          v-bind="attrs"
          v-on="on"
          ><v-icon>mdi-swap-vertical</v-icon></v-btn
        >
        <div
          v-else
          class="mr-3 ml-1 pa-0 rounded-lg organisation-switcher"
          :style="
            `background-image: url(${currentOrganisation?.logoUrl});`
          "
          color="primary"
          v-bind="attrs"
          v-on="on"
        ></div>
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          Organisation Switcher
        </v-card-title>

        <v-card-text class="py-4">
          <v-row
            v-for="(organisation, index) in organisations"
            :key="index"
            class="mx-0"
          >
            <v-btn
              class="rounded-lg pa-0"
              width="100%"
              text
              :disabled="organisation.id === currentOrganisationId"
              @click="changeOrganisation(organisation.id)"
            >
              <v-row class="organisation-row" align="center">
                <v-col cols="auto">
                  <img
                    class="rounded-lg"
                    style="width: 40px;height: 40px;object-fit: cover;margin-top:4px;margin-left:4px;"
                    :src="organisation.logoUrl"
                  />
                </v-col>

                <v-col cols="auto" class="pl-0">
                  {{ organisation.companyName }}
                </v-col>
              </v-row>
            </v-btn>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { Organisations } from '@/graphql/Organisation.gql';

export default {
  name: 'OrganisationSwitcherModal',
  props: {
    small: {
      type: Boolean
    }
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    ...mapState('user', ['currentUserAccount']),
    currentOrganisationId() {
      return this.$route.params.organisationId;
    },
    currentOrganisation() {
      return this.organisations?.find(org => {
        return org.id === this.currentOrganisationId;
      });
    }
  },
  apollo: {
    organisations: {
      query: Organisations
    }
  },
  methods: {
    changeOrganisation(id) {
      this.$emit('switch-org', id);
      this.dialog = false;
    },
    backToDementiaAdminView() {
      this.$router.push(`/`);
      this.dialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
$color: #fff;
$scale: 40px;
$space: 1.5px;

.organisation-switcher {
  height: $scale;
  width: $scale;
  background-size: cover;
  background-position: center;
  margin: 0 $scale / 5;
  transition: all ease 0.3s;
  &:hover {
    box-shadow: 0 0 0 $space $color, 0 0 0 ($space + 2) #003349;
  }
}
</style>
