<template>
  <loading v-if="loading" />
  <insufficient-permissions
    v-else-if="currentUserAccount?.isUser() && !loading"
  />
  <v-app v-else>
    <v-navigation-drawer
      app
      class="side-drawer"
      :mobile-breakpoint="0"
    >
      <v-list class="secondary--text pa-0">
        <div
          style="background-color: #2dd5c4 !important; height: 64px;"
        >
          <v-list-item link to="/">
            <v-list-item-content class="logo-sec">
              <img src="../images/logo.svg" class="logo" />
            </v-list-item-content>
          </v-list-item>
        </div>

        <div style="height: calc(100vh - 129px);overflow-y: auto;">
          <div
            v-for="(navItem, index) in navigationItems"
            :key="index"
          >
            <div
              v-if="
                !currentUserAccount?.isDementiaAdmin()
                  ? currentUserAccount?.systemRole ==
                    navItem.systemRole
                  : true
              "
              class="navigation-subtitle"
            >
              {{ navItem.title }}
            </div>

            <div
              v-for="(navChild, index) in navItem.children"
              :key="`${navItem.title.replaceAll(' ', '_')}-${index}`"
            >
              <v-list-item
                v-if="
                  !currentUserAccount?.isDementiaAdmin()
                    ? currentUserAccount?.systemRole ==
                      navItem.systemRole
                    : true
                "
                link
                active-class="navigation-item-active"
                :to="navChild.to"
              >
                <v-list-item-action>
                  <v-icon>{{ navChild.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    navChild.title
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>

            <v-divider
              v-if="
                !currentUserAccount?.isDementiaAdmin()
                  ? currentUserAccount?.systemRole ==
                    navItem.systemRole
                  : true
              "
              class="my-3"
            />
          </div>
        </div>

        <div class="navigation-bottom-bar">
          <v-row
            class="align-center px-5 text--primary"
            style="height: 64px;"
            no-gutters
          >
            <v-col cols="auto">
              <div class="font-weight-bold">
                {{
                  [
                    currentUserAccount?.firstName,
                    currentUserAccount?.lastName
                  ].join(' ')
                }}
              </div>
              <div
                class="text-capitalize text--secondary"
                style="font-size: 12px;"
              >
                {{
                  currentUserAccount?.systemRole
                    .replaceAll('_', ' ')
                    .toLowerCase()
                }}
              </div>
            </v-col>

            <v-col cols="auto" class="ml-auto">
              <v-btn icon @click="logout"
                ><v-icon size="24">mdi-logout</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-main class="main-application">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { CurrentUserAccount } from '@/graphql/UserAccount.gql';
import { mainNavigationItems } from '@/utils/navigation';
import { auth } from '@/main';
import InsufficientPermissions from '@/pages/InsufficientPermissions';
import Loading from '@/pages/Loading';

export default {
  name: 'ApplicationLayout',
  components: { InsufficientPermissions, Loading },
  data: () => ({
    drawerState: null
  }),
  computed: {
    ...mapState('user', ['currentUserAccount', 'loadingUserAccount']),
    navigationItems() {
      return mainNavigationItems;
    },
    loading() {
      return (
        this.$apollo.queries.currentUserAccount.loading
      );
    }
  },
  apollo: {
    currentUserAccount: {
      query: CurrentUserAccount,
      fetchPolicy: 'no-cache',
      result({ data }) {
        this.updateCurrentUserAccount(data);
        this.checkUserMemberships(
          data.currentUserAccount
        );
      }
    }
  },
  methods: {
    ...mapActions('user', ['updateCurrentUserAccount']),
    logout() {
      auth.signOut().then(() => this.$router.push('/'));
    },
    checkUserMemberships(currentUserAccount) {
      if (currentUserAccount.systemRole === 'USER') {
        const activeOrgStaffMemberships = currentUserAccount.memberships.filter(
          membership =>
            !membership.archivedAt &&
            membership.membershipState === 'ACTIVE' &&
            (membership.memberRole === 'ORG_ADMIN' ||
              membership.memberRole === 'ORG_VIEWER')
        );

        if (activeOrgStaffMemberships.length > 0) {
          this.$router.push(
            `/organisation/${activeOrgStaffMemberships[0].organisation.id}/overview`
          );
        }

        const activeSiteStaffMemberships = currentUserAccount.memberships.filter(
          membership =>
            !membership.archivedAt &&
            membership.membershipState === 'ACTIVE' &&
            (membership.memberRole === 'SITE_ADMIN' ||
              membership.memberRole === 'SITE_VIEWER')
        );

        if (activeSiteStaffMemberships.length > 0) {
          this.$router.push(
            `/organisation/${activeSiteStaffMemberships[0].organisation.id}/sites/${activeSiteStaffMemberships[0].site.id}`
          );
        }
      }
    }
  }
};
</script>

<style scoped>
.container {
  background-color: #fafafa !important;
  box-shadow: none !important;
}

.logo-list-item {
  background-color: #2dd5c4 !important;
}
</style>
