var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "10" } },
    [
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "px-4 pt-8",
          on: {
            input: _vm.validateForm,
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "dark-blue-text",
              attrs: { "no-gutters": "", justify: "center" }
            },
            [
              _c("h2", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.forgotPasswordToggled ? "Reset Password" : "Login"
                    ) +
                    " "
                )
              ])
            ]
          ),
          !_vm.forgotPasswordToggled
            ? _c(
                "v-row",
                {
                  staticClass: "font-weight-light dark-blue-text",
                  attrs: { "no-gutters": "", justify: "center" }
                },
                [_c("p", [_vm._v("Welcome back to Ask Annie.")])]
              )
            : _vm._e(),
          _c("v-text-field", {
            staticClass: "mt-3 mb-4",
            attrs: {
              outlined: "",
              placeholder: "Email",
              rules: _vm.emailRules,
              "hide-details": "false",
              disabled: _vm.loading
            },
            model: {
              value: _vm.email,
              callback: function($$v) {
                _vm.email = $$v
              },
              expression: "email"
            }
          }),
          !_vm.forgotPasswordToggled
            ? _c("v-text-field", {
                attrs: {
                  type: "password",
                  outlined: "",
                  rules: _vm.passwordRules,
                  "hide-details": "false",
                  placeholder: "Password",
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.password,
                  callback: function($$v) {
                    _vm.password = $$v
                  },
                  expression: "password"
                }
              })
            : _vm._e(),
          _vm.message
            ? _c(
                "v-row",
                { staticClass: "mt-4", attrs: { justify: "center" } },
                [
                  _c("span", { class: _vm.messageClass }, [
                    _vm._v(_vm._s(_vm.message))
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "v-row",
            { staticClass: "mt-6", attrs: { justify: "center" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "login-button",
                  attrs: {
                    color: "primary",
                    large: "",
                    type: "submit",
                    loading: _vm.loading,
                    disabled: _vm.loading
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.forgotPasswordToggled ? "Reset Password" : "Login"
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c("v-row", { staticClass: "mt-6", attrs: { justify: "center" } }, [
            _c(
              "a",
              {
                staticClass: "toggle-link font-weight-light",
                attrs: { href: "javascript:void(0)" },
                on: { click: _vm.toggleForgotPassword }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.forgotPasswordToggled
                        ? "Back to login"
                        : "Forgot password"
                    ) +
                    " "
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }