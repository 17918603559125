<template>
  <div class="tabbed-page">
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row align="center" class="ml-0 mb-0">
            <h1>{{ course?.title }}</h1>
            <v-chip
              v-if="getCourseContentState() !== 'DRAFT'"
              :color="getCourseContentStateColour()"
              class="ml-2 text-caption text-capitalize font-weight-bold white--text"
              >{{ getCourseContentState().toLowerCase() }} Course
              Content</v-chip
            >
          </v-row>
          <v-row no-gutters align="center">
            <v-col cols="auto">
              <breadcrumbs :items="['Courses', course?.title]" />
            </v-col>
            <v-col cols="auto">
              <v-chip
                small
                color="primary"
                class="font-weight-bold text-caption"
                >v{{ course?.version }}</v-chip
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" class="ml-auto">
          <v-row align="center" class="mr-0">
            <course-timeline-modal
              :course-reference-id="course?.referenceId"
              :current-version="course?.version"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-alert
      v-if="getCourseContentState() === 'UNAVAILABLE'"
      border="left"
      color="red lighten-1"
      dark
    >
      <v-row align="center">
        <v-col class="grow">
          <v-icon class="mx-2">mdi-pencil-off</v-icon>
          This version of the course has been retired. The current
          published version is v{{ latestCourseVersion?.version }}.
        </v-col>
        <v-col class="shrink">
          <course-timeline-modal
            :course-reference-id="course?.referenceId"
            :current-version="course?.version"
            error-alert
          />
        </v-col>
      </v-row>
    </v-alert>

    <div>
      <div class="text--primary mb-3 font-weight-bold text-h6">
        Course Configuration
      </div>
      <div class="rounded-lg white px-5 py-1 text--primary">
        <v-row no-gutters align="center" class="info-row py-2">
          <v-col cols="auto" class="mr-auto title-column">
            Course Title
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <edit-field
              :readonly="
                course?.isCurrentCourseContent || !courseContentId
              "
              :current-value="course?.title"
              @update-value="updateTitle"
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-2">
          <v-col cols="auto" class="mr-auto title-column">
            Course Description
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <edit-field
              :readonly="
                course?.isCurrentCourseContent || !courseContentId
              "
              :current-value="course?.description"
              @update-value="updateDescription"
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-2">
          <v-col cols="auto" class="mr-auto title-column">
            Visible
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <v-switch
              :disabled="course?.isCurrentCourseContent || !courseContentId"
              :input-value="course?.visible"
              @change="updateVisible"
              hide-details
              class="mt-0"
              inset
            ></v-switch>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-4">
          <v-col cols="auto" class="mr-auto title-column">
            Last Updated At
          </v-col>
          <v-col cols="auto" class="ml-auto">
            {{ formatDate(course?.updatedAt) }}
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="mt-8">
      <v-row justify="space-between" align="center">
        <v-col
          cols="auto"
          class="text--primary mb-3 font-weight-bold text-h6"
        >
          Modules
        </v-col>

        <v-col v-if="courseContentId" cols="auto">
          <create-module-modal
            @refetch="$apollo.queries.course.refetch()"
          />
        </v-col>
      </v-row>
      <div class="rounded-lg white px-5 text--primary">
        <v-data-table
          :items="course?.modules"
          :headers="moduleHeaders"
          class="dark--text rounded-b-lg rounded-t-0"
          no-data-text="There are no modules for this course"
          item-key="id"
          @click:row="
            item =>
              $router.push(
                courseContentId
                  ? `/course-content/${courseContentId}/modules/${item.id}`
                  : `/modules/${item.id}`
              )
          "
        >
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ formatDate(item.updatedAt) }}
          </template>
          <template v-slot:[`item.lessons`]="{ item }">
            {{ item.lessons.length }}
          </template>
          <template v-slot:[`item.editColumn`]="{ item }">
            <delete-button
              v-if="courseContentId"
              @delete="deleteModule(item.id)"
            />
            <v-btn
              v-if="courseContentId"
              icon
              @click="
                $router.push(
                  `/course-content/${courseContentId}/modules/${item.id}`
                )
              "
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              v-if="!courseContentId"
              icon
              @click="$router.push(`/modules/${item?.id}`)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Course,
  DeleteModuleFromCourse,
  AddModuleToCourse,
  UpdateCourse,
  DuplicateCourseForEdit
} from '@/graphql/Course.gql';
import { formatDate } from '@/utils/date';
import ColourPicker from '@/components/Common/ColourPicker';
import Breadcrumbs from '@/components/Common/Breadcrumbs';
import ModuleSelector from '@/components/Module/ModuleSelector';
import EditField from '@/components/Common/EditField';
import CourseTimelineModal from '@/components/Course/CourseTimelineModal';
import { getStateColour } from '@/utils/courseContent';
import { CourseVersions } from '@/graphql/Course.gql';
import CreateModuleModal from '@/components/Module/CreateModuleModal';
import DeleteButton from '@/components/Common/DeleteButton.vue';

export default {
  name: 'CoursePage',
  components: {
    ColourPicker,
    Breadcrumbs,
    ModuleSelector,
    EditField,
    CourseTimelineModal,
    CreateModuleModal,
    DeleteButton
  },
  data: () => ({
    moduleHeaders: [
      { text: 'Name', value: 'title' },
      { text: 'Lessons', value: 'lessons' },
      { text: 'Last Updated At', value: 'updatedAt' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ]
  }),
  computed: {
    courseContentId() {
      return this.$route.params.courseContentId;
    },
    courseId() {
      return this.$route.params.courseId;
    },
    defaultCourse() {
      return {
        id: this.course?.id,
        title: this.course?.title,
        description: this.course?.description,
        headerContent: this.course?.headerContent,
        titleColour: this.course?.titleColour,
        titleTextColour: this.course?.titleTextColour,
        subTextColour: this.course?.subTextColour,
        progressFillColour: this.course?.progressFillColour,
        learningObjectives: this.course?.learningObjectives,
        visible: this.course?.visible,
        disabled: this.course?.disabled,
        referenceId: this.course?.referenceId,
        version: this.course?.version
      };
    },
    latestCourseVersion() {
      return this.courseVersions?.sort(
        (a, b) => b.version - a.version
      )[0];
    }
  },
  apollo: {
    course: {
      query: Course,
      variables() {
        return {
          id: this.courseId
        };
      },
      skip() {
        return !this.courseId;
      }
    },
    courseVersions: {
      query: CourseVersions,
      variables() {
        return { referenceId: this.course?.referenceId };
      },
      skip() {
        return !this.course?.referenceId;
      }
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    async deleteModule(id) {
      try {
        await this.$apollo.mutate({
          mutation: DeleteModuleFromCourse,
          variables: {
            moduleId: id,
            courseId: this.course?.id
          }
        });
      } catch (error) {
        this.$toast.error(
          error.graphQLErrors.map(x => x.message).join(', ')
        );
      }
      await this.$apollo.queries.course.refetch();
    },
    async addSelectedModules(moduleIds) {
      try {
        for (let i = 0; i < moduleIds.length; i++) {
          await this.$apollo.mutate({
            mutation: AddModuleToCourse,
            variables: {
              moduleId: moduleIds[i],
              courseId: this.course?.id
            }
          });
        }
      } catch (error) {
        this.$toast.error(
          error.graphQLErrors.map(x => x.message).join(', ')
        );
      }

      await this.$apollo.queries.course.refetch();
    },
    async updateCourse(course) {
      try {
        await this.$apollo.mutate({
          mutation: UpdateCourse,
          variables: {
            course: course
          }
        });

        await this.$apollo.queries.course.refetch();
      } catch (e) {
        e.graphQLErrors.map(x => this.$toast.error(x.message));
      }
    },
    async updateTitle(title) {
      await this.updateCourse({
        ...this.defaultCourse,
        title: title
      });
    },
    async updateDescription(description) {
      await this.updateCourse({
        ...this.defaultCourse,
        description: description
      });
    },
    async updateTitleColour(colour) {
      await this.updateCourse({
        ...this.defaultCourse,
        titleColour: colour
      });
    },
    async updateTitleTextColour(colour) {
      await this.updateCourse({
        ...this.defaultCourse,
        titleTextColour: colour
      });
    },
    async updateSubTextColour(colour) {
      await this.updateCourse({
        ...this.defaultCourse,
        subTextColour: colour
      });
    },
    async updateProgressFillColour(colour) {
      await this.updateCourse({
        ...this.defaultCourse,
        progressFillColour: colour
      });
    },
    async duplicateCourse() {
      const { data } = await this.$apollo.mutate({
        mutation: DuplicateCourseForEdit,
        variables: { id: this.course?.id }
      });

      this.$router.push(`/courses/${data.duplicateCourseForEdit.id}`);
    },
    getCourseContentState() {
      return this.course?.isTestCourseContent
        ? 'TESTING'
        : this.course?.isRetiredCourseContent
        ? 'UNAVAILABLE'
        : this.course?.isCurrentCourseContent
        ? 'PUBLISHED'
        : 'DRAFT';
    },
    getCourseContentStateColour() {
      return getStateColour(this.getCourseContentState());
    },
    async updateVisible(visible) {
      await this.updateCourse({
        ...this.defaultCourse,
        visible: visible
      });
    }
  }
};
</script>
