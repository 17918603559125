var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.open, "max-width": "600px" },
      on: {
        "click:outside": _vm.cancel,
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.cancel.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "rounded-lg" },
        [
          _c(
            "v-card-title",
            { staticClass: "h6 font-weight-bold primary--text" },
            [_vm._v(" " + _vm._s(_vm.modalTitle) + " ")]
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Label",
                      rules: [v => !!v || "Label is required"],
                      required: ""
                    },
                    model: {
                      value: _vm.input.label,
                      callback: function($$v) {
                        _vm.$set(_vm.input, "label", $$v)
                      },
                      expression: "input.label"
                    }
                  }),
                  _c("v-label", [_vm._v("Content")]),
                  _c(
                    "div",
                    { staticClass: "content-list" },
                    _vm._l(_vm.input.content, function(content, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "mb-2" },
                        [
                          _c(
                            "v-card",
                            { attrs: { outlined: "" } },
                            [
                              _c("v-card-text", [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-medium" },
                                      [_vm._v(_vm._s(content.title))]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "ml-2 grey--text" },
                                      [
                                        _vm._v(
                                          "(" +
                                            _vm._s(content.contentType) +
                                            ")"
                                        )
                                      ]
                                    ),
                                    _c("v-spacer"),
                                    content.contentType?.toUpperCase() ===
                                    "TEXT"
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "mr-2",
                                            attrs: { icon: "", small: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.editContent(content)
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v("mdi-pencil")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { icon: "", small: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.removeContent(content)
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("mdi-delete-outline")
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c("create-content-modal", {
                    staticClass: "mt-4",
                    on: { "create-content": _vm.addContent }
                  }),
                  _vm.isEditModalOpen
                    ? _c("edit-text-content-modal", {
                        attrs: {
                          content: _vm.editingContent,
                          open: _vm.isEditModalOpen
                        },
                        on: {
                          "update:open": function($event) {
                            _vm.isEditModalOpen = $event
                          },
                          save: _vm.updateContent
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-end" },
            [
              _vm.error
                ? _c("span", { staticClass: "red--text mr-4" }, [
                    _vm._v(_vm._s(_vm.error))
                  ])
                : _vm._e(),
              _c("v-btn", { attrs: { text: "" }, on: { click: _vm.cancel } }, [
                _vm._v("Cancel")
              ]),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    loading: _vm.loading,
                    disabled: !_vm.valid || !_vm.input.content.length
                  },
                  on: { click: _vm.save }
                },
                [_vm._v(" " + _vm._s(_vm.submitButtonText) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }