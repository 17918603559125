var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          class: _vm.errorAlert ? "primary--text" : "mr-3",
                          attrs: {
                            color: _vm.errorAlert ? "white" : "grey lighten-2",
                            small: _vm.errorAlert
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-history")
                      ]),
                      _vm._v("Version History")
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "rounded-lg" },
            [
              _c(
                "v-card-title",
                { staticClass: "h6 font-weight-bold primary--text" },
                [_vm._v(" Lesson History ")]
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-timeline",
                    {
                      staticClass: "ml-n3 mr-2",
                      attrs: { dense: "", clipped: "" }
                    },
                    _vm._l(_vm.lessonVersions, function(lesson) {
                      return _c(
                        "v-timeline-item",
                        {
                          key: lesson.id,
                          staticClass: "mb-4 align-center",
                          attrs: {
                            color:
                              lesson.version === _vm.currentVersion
                                ? "primary"
                                : "grey",
                            "icon-color": "white"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function() {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "white--text font-weight-bold text-caption"
                                      },
                                      [_vm._v("V" + _vm._s(lesson.version))]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                justify: "space-between",
                                align: "center"
                              }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "primary--text",
                                  attrs: { cols: "7" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        "no-gutters": ""
                                      }
                                    },
                                    [
                                      _c("v-col", { attrs: { cols: "auto" } }, [
                                        _vm._v(" " + _vm._s(lesson.title) + " ")
                                      ]),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          lesson.version !== _vm.currentVersion
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ml-1",
                                                  attrs: {
                                                    icon: "",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openVersion(
                                                        lesson.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-open-in-new")
                                                  ])
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        "no-gutters": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "text-capitalize font-weight-bold text-caption",
                                          style: {
                                            color: _vm.getLessonContentStateColour(
                                              _vm.getLessonContentState(lesson)
                                            )
                                          },
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .getLessonContentState(lesson)
                                                  .toLowerCase()
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { cols: "5" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formatDate(lesson.createdAt)) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" Close ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }