var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "primary--text mb-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mr-auto", attrs: { cols: "auto" } },
                        [_c("h1", [_vm._v("Ask Annie Anything")])]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mr-auto", attrs: { cols: "auto" } },
                        [
                          _c("div", { staticClass: "page-subtitle" }, [
                            _vm._v(
                              " Upload, validate, and activate Ask Annie Anything configurations for all users. "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "my-4", attrs: { "no-gutters": "" } },
            [
              _c("v-col", [
                _c("h2", { staticClass: "pt-4 pl-3" }, [
                  _vm._v("Configurations")
                ])
              ]),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.configModalOpen = true
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-upload-outline")
                      ]),
                      _vm._v(" Upload Config ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                [
                  _c("v-data-table", {
                    staticClass: "dark--text rounded-b-lg rounded-t-0",
                    attrs: {
                      "items-per-page": 5,
                      items: _vm.configs,
                      headers: _vm.configTableHeaders,
                      "no-data-text": "There are no configurations.",
                      "loading-text": "Loading...",
                      "item-key": "id"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `item.problemsColumn`,
                          fn: function({ item }) {
                            return [
                              _vm.errorCount(item) > 0
                                ? _c(
                                    "v-chip",
                                    { staticClass: "pl-1 error text--white" },
                                    [
                                      _c("v-icon", { attrs: { white: "" } }, [
                                        _vm._v("dangerous")
                                      ]),
                                      _vm._v(
                                        _vm._s(_vm.errorCount(item)) + " errors"
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.warningCount(item) > 0
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass:
                                        "pl-1 orange accent-3 darken-1 white--text",
                                      on: {
                                        click: function($event) {
                                          _vm.warningsDialogOpenFor = item
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { color: "white" }
                                        },
                                        [_vm._v("error")]
                                      ),
                                      _vm._v(
                                        _vm._s(_vm.warningCount(item)) +
                                          " warnings "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: `item.uploadedBy`,
                          fn: function({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.uploaderName(item.uploadedBy)) +
                                  " "
                              )
                            ]
                          }
                        },
                        {
                          key: `item.createdAt`,
                          fn: function({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formatDateTime(item.createdAt)) +
                                  " "
                              )
                            ]
                          }
                        },
                        {
                          key: `item.state`,
                          fn: function({ item }) {
                            return [
                              _c(
                                "v-chip",
                                {
                                  staticClass:
                                    "pr-1 white--text font-weight-bold caption",
                                  attrs: {
                                    color: _vm.configStates[item.state].color
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.configStates[item.state].displayName
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-1",
                                      on: {
                                        click: function($event) {
                                          _vm.openStatusMenu =
                                            _vm.openStatusMenu == item.id
                                              ? null
                                              : item.id
                                        }
                                      }
                                    },
                                    [_vm._v(" expand_circle_down ")]
                                  )
                                ],
                                1
                              ),
                              _vm.openStatusMenu == item.id
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "pa-6 mt-1 rounded-xxl state-dropdown"
                                    },
                                    _vm._l(
                                      Object.values(_vm.configStates).filter(
                                        s => s.enumValue !== item.state
                                      ),
                                      function(state, index) {
                                        return _c(
                                          "v-row",
                                          {
                                            key: index,
                                            staticClass: "justify-end"
                                          },
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass:
                                                  "white--text font-weight-bold caption",
                                                class: index != 0 ? "mt-2" : "",
                                                attrs: { color: state.color },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.updateState(
                                                      item,
                                                      state
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(state.displayName) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: `item.mediaProblems`,
                          fn: function({ item }) {
                            return [
                              item.unmetMediaDependencies.length <= 0
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass: "white--text pl-1",
                                      attrs: { color: "success" }
                                    },
                                    [
                                      _c("v-icon", { staticClass: "mr-1" }, [
                                        _vm._v("check_circle")
                                      ]),
                                      _vm._v(" All met! ")
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-chip",
                                    {
                                      staticClass:
                                        "orange accent-3 darken-1 white--text pl-1",
                                      on: {
                                        click: function($event) {
                                          _vm.missingMediaDialogOpenFor = item
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { color: "white" }
                                        },
                                        [_vm._v("error")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.unmetMediaDependencies.length
                                          ) +
                                          " missing "
                                      )
                                    ],
                                    1
                                  )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-8 mb-4", attrs: { "no-gutters": "" } },
            [
              _c("v-col", [
                _c("h2", { staticClass: "pt-4 pl-3" }, [_vm._v("Media")])
              ]),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.mediaModalOpen = true
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-1" }, [
                        _vm._v("mdi-upload-outline")
                      ]),
                      _vm._v(" Upload Media ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                [
                  _c("v-data-table", {
                    staticClass: "dark--text rounded-b-lg rounded-t-0",
                    attrs: {
                      "items-per-page": 5,
                      items: _vm.media,
                      headers: _vm.mediaTableHeaders,
                      "no-data-text": "There is no media.",
                      "loading-text": "Loading...",
                      "item-key": "id"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `item.type`,
                          fn: function({ item }) {
                            return [
                              _c("col"),
                              _c("v-icon", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.mediaTypeByEnumValue(item.mediaType)
                                        .icon
                                    ) +
                                    " "
                                )
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.mediaTypeByEnumValue(item.mediaType)
                                      .name
                                  ) +
                                  " "
                              )
                            ]
                          }
                        },
                        {
                          key: `item.uploadedBy`,
                          fn: function({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.uploaderName(item.uploadedBy)) +
                                  " "
                              )
                            ]
                          }
                        },
                        {
                          key: `item.createdAt`,
                          fn: function({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formatDateTime(item.createdAt)) +
                                  " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("create-aaa-config-modal", {
        attrs: { open: _vm.configModalOpen },
        on: {
          close: function($event) {
            _vm.configModalOpen = false
          },
          success: () => {
            _vm.configModalOpen = false
            _vm.$apollo.queries.configs.refetch()
          }
        }
      }),
      _c("create-aaa-media-modal", {
        attrs: { open: _vm.mediaModalOpen },
        on: {
          close: function($event) {
            _vm.mediaModalOpen = false
          },
          success: () => {
            _vm.mediaModalOpen = false
            _vm.$apollo.queries.media.refetch()
          }
        }
      }),
      _c("warnings-dialog", {
        attrs: {
          open: _vm.warningsDialogOpenFor != null,
          warnings: _vm.warningsDialogOpenFor?.validationResults?.warnings ?? []
        },
        on: {
          close: function($event) {
            _vm.warningsDialogOpenFor = null
          }
        }
      }),
      _c("missing-media-dialog", {
        attrs: {
          open: _vm.missingMediaDialogOpenFor != null,
          missingMedia:
            _vm.missingMediaDialogOpenFor?.unmetMediaDependencies ?? []
        },
        on: {
          close: function($event) {
            _vm.missingMediaDialogOpenFor = null
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }