import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import user from './modules/user';
import organisation from './modules/organisation';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    organisation
  },
  plugins: [
    createPersistedState({
      paths: ['user', 'organisation']
    })
  ]
});
