var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-sub-sec" },
        [
          _c("v-text-field", {
            staticClass: "shrink",
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search",
              outlined: "",
              dense: "",
              "hide-details": ""
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "dark--text rounded-b-lg rounded-t-0",
        attrs: {
          items: _vm.filteredDementiaFactContent,
          headers: _vm.headers,
          "no-data-text": `There are no dementia facts${
            _vm.search ? " that match your search" : ""
          }`,
          loading: _vm.loading,
          "loading-text": "Loading...",
          "item-key": "id"
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.updatedAt`,
              fn: function({ item }) {
                return [
                  _vm._v(" " + _vm._s(_vm.formatDate(item.updatedAt)) + " ")
                ]
              }
            },
            {
              key: `item.editColumn`,
              fn: function({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("edit-dementia-fact", item)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("delete-dementia-fact", item.id)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-delete-outline")])],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }