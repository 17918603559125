var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "primary--text mb-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "ml-n3 mr-1", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "#00000060", icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.go(-1)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "auto" } }, [
                        _c("h1", [
                          _vm._v(
                            " " +
                              _vm._s(
                                [
                                  _vm.userAccount?.firstName,
                                  _vm.userAccount?.lastName
                                ].join(" ")
                              ) +
                              " "
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("breadcrumbs", { attrs: { items: _vm.breadcrumbItems } })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: { color: "#E5E5E5", loading: _vm.resendingInvite },
                      on: { click: _vm.resendInvite }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-email-outline")
                      ]),
                      _vm._v(" Resend Invite ")
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: { color: "#E5E5E5" },
                      on: { click: _vm.sendPasswordReset }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-lock-plus-outline")
                      ]),
                      _vm._v("Reset Password")
                    ],
                    1
                  ),
                  !_vm.isStaffAccount
                    ? _c(
                        "v-btn",
                        { attrs: { color: "primary" } },
                        [
                          _c("v-icon", { staticClass: "mr-2" }, [
                            _vm._v("mdi-share-variant-outline")
                          ]),
                          _vm._v("Export")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.organisationId
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "red--text",
                          attrs: {
                            color: "grey lighten-2",
                            disabled: _vm.archivingLearner
                          },
                          on: {
                            click: function($event) {
                              return _vm.archiveUser()
                            }
                          }
                        },
                        [
                          _c("v-icon", { staticClass: "mr-2" }, [
                            _vm._v(" " + _vm._s("mdi-delete") + " ")
                          ]),
                          _vm._v(" Delete User ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentUserAccount?.isDementiaAdmin() &&
                  !_vm.isStaffAccount
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mx-3",
                          attrs: {
                            color: _vm.userAccount?.deleteRequestedAt
                              ? "warning"
                              : "error"
                          },
                          on: {
                            click: function($event) {
                              _vm.userAccount?.deleteRequestedAt
                                ? _vm.confirmCancelDelete()
                                : _vm.confirmDeleteRequest()
                            }
                          }
                        },
                        [
                          _c("v-icon", { staticClass: "mr-2" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.userAccount?.deleteRequestedAt
                                    ? "mdi-restore"
                                    : "mdi-delete"
                                ) +
                                " "
                            )
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.userAccount?.deleteRequestedAt
                                  ? "Cancel Account Deletion"
                                  : "Request Account Deletion"
                              ) +
                              " "
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "rounded-lg white px-5 py-1 text--primary" },
        [
          _vm.userAccount?.deleteRequestedAt
            ? _c(
                "v-row",
                {
                  staticClass: "info-row py-4 red--text",
                  attrs: { "no-gutters": "", align: "center" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "mr-auto title-column",
                      attrs: { cols: "auto" }
                    },
                    [_vm._v(" Scheduled for Deletion ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto title-column",
                      attrs: { cols: "auto" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.scheduleAccountDeletionDate(
                              _vm.userAccount?.deleteRequestedAt
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            {
              staticClass: "info-row py-4",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "mr-auto title-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Email ")]
              ),
              _c(
                "v-col",
                {
                  staticClass: "ml-auto value-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" " + _vm._s(_vm.userAccount?.email) + " ")]
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "info-row py-4",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "mr-auto title-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" First Name ")]
              ),
              _c(
                "v-col",
                {
                  staticClass: "ml-auto value-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" " + _vm._s(_vm.userAccount?.firstName) + " ")]
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "info-row py-4",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "mr-auto title-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Last Name ")]
              ),
              _c(
                "v-col",
                {
                  staticClass: "ml-auto value-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" " + _vm._s(_vm.userAccount?.lastName) + " ")]
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "info-row py-4",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "mr-auto title-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Gender ")]
              ),
              _c(
                "v-col",
                {
                  staticClass: "ml-auto value-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" " + _vm._s(_vm.userAccount?.gender ?? "-") + " ")]
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "info-row py-4",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "mr-auto title-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Job Role ")]
              ),
              _c(
                "v-col",
                {
                  staticClass: "ml-auto value-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" " + _vm._s(_vm.userAccount?.jobRole ?? "-") + " ")]
              )
            ],
            1
          ),
          _vm.isUserOrgStaff
            ? _c(
                "v-row",
                {
                  staticClass: "info-row py-2",
                  attrs: { "no-gutters": "", align: "center" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "mr-auto title-column",
                      attrs: { cols: "auto" }
                    },
                    [_vm._v(" Site Admin For ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto value-column",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center", "no-gutters": "" } },
                        [
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.siteAdminForSites
                                      .map(membership => membership.site.name)
                                      .join(", ") ?? "-"
                                  ) +
                                  " "
                              )
                            ])
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c("site-selector", {
                                attrs: {
                                  "existing-memberships":
                                    _vm.membershipsForUserAccount,
                                  "pre-selected-sites": _vm.siteAdminForSites.map(
                                    membership => membership.site
                                  ),
                                  disabled: !_vm.isCurrentUserOrgAdmin,
                                  "member-role": "SITE_ADMIN"
                                },
                                on: { refetch: _vm.refetchUserAccount }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isUserOrgStaff
            ? _c(
                "v-row",
                {
                  staticClass: "info-row py-2",
                  attrs: { "no-gutters": "", align: "center" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "mr-auto title-column",
                      attrs: { cols: "auto" }
                    },
                    [_vm._v(" Site Viewer For ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto value-column",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center", "no-gutters": "" } },
                        [
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.siteViewerForSites
                                      .map(membership => membership.site.name)
                                      .join(", ") ?? "-"
                                  ) +
                                  " "
                              )
                            ])
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c("site-selector", {
                                attrs: {
                                  "existing-memberships":
                                    _vm.membershipsForUserAccount,
                                  "pre-selected-sites": _vm.siteViewerForSites.map(
                                    membership => membership.site
                                  ),
                                  disabled: !_vm.isCurrentUserOrgAdmin,
                                  "member-role": "SITE_VIEWER"
                                },
                                on: { refetch: _vm.refetchUserAccount }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            {
              staticClass: "info-row py-4",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "mr-auto title-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Last Active At ")]
              ),
              _c(
                "v-col",
                {
                  staticClass: "ml-auto value-column",
                  attrs: { cols: "auto" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.userAccount?.updatedAt
                          ? _vm.formatDate(_vm.userAccount?.updatedAt)
                          : ""
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.currentUserAccount?.isDementiaAdmin()
        ? _c("div", { staticClass: "mt-8" }, [
            _c(
              "div",
              { staticClass: "text--primary mb-3 font-weight-bold text-h6" },
              [_vm._v(" Testing Features ")]
            ),
            _c(
              "div",
              { staticClass: "rounded-lg white px-5 py-1 text--primary" },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "info-row py-4",
                    attrs: { "no-gutters": "", align: "center" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mr-auto title-column",
                        attrs: { cols: "auto" }
                      },
                      [_vm._v(" Ask Annie Anything ")]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "ml-auto", attrs: { cols: "auto" } },
                      [
                        _c("v-switch", {
                          staticClass: "my-0",
                          attrs: {
                            disabled:
                              _vm.togglingAaaAccess ||
                              _vm.$apollo.queries.userAccount.loading,
                            inset: "",
                            "hide-details": ""
                          },
                          on: { click: _vm.toggleAaaAccess },
                          model: {
                            value: _vm.userAccount.aaaAccess,
                            callback: function($$v) {
                              _vm.$set(_vm.userAccount, "aaaAccess", $$v)
                            },
                            expression: "userAccount.aaaAccess"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "info-row py-4",
                    attrs: { "no-gutters": "", align: "center" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mr-auto title-column",
                        attrs: { cols: "auto" }
                      },
                      [_vm._v(" View Test Course Data ")]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "ml-auto", attrs: { cols: "auto" } },
                      [
                        _c("v-switch", {
                          staticClass: "my-0",
                          attrs: {
                            disabled:
                              _vm.togglingTestUser ||
                              _vm.$apollo.queries.userAccount.loading,
                            inset: "",
                            "hide-details": ""
                          },
                          on: { click: _vm.toggleTestUser },
                          model: {
                            value: _vm.userAccount.isTestUser,
                            callback: function($$v) {
                              _vm.$set(_vm.userAccount, "isTestUser", $$v)
                            },
                            expression: "userAccount.isTestUser"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      !_vm.isStaffAccount
        ? _c(
            "div",
            { staticClass: "mt-8" },
            [
              _c(
                "v-row",
                { attrs: { justify: "space-between", align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "text--primary mb-3 font-weight-bold text-h6",
                      attrs: { cols: "auto" }
                    },
                    [_vm._v(" Summary of Course Completion ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "rounded-lg white px-5 text--primary" },
                [
                  _c("v-data-table", {
                    staticClass: "dark--text rounded-b-lg rounded-t-0",
                    attrs: {
                      items: _vm.formattedLessonCompletionsForUser,
                      headers: _vm.summaryOfCourseCompletionHeaders,
                      "no-data-text": "No completions recorded for this user",
                      "loading-text": "Loading...",
                      "item-key": "id"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `item.totalModules`,
                          fn: function({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(item.completedModules) +
                                  "/" +
                                  _vm._s(item.totalModules) +
                                  " "
                              )
                            ]
                          }
                        },
                        {
                          key: `item.totalLessons`,
                          fn: function({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(item.completedLessons) +
                                  "/" +
                                  _vm._s(item.totalLessons) +
                                  " "
                              )
                            ]
                          }
                        },
                        {
                          key: `item.courseAvailability`,
                          fn: function({ item }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "text-capitalize",
                                  style: `color: ${_vm.getStateColour(
                                    item.courseContentState
                                  )}`
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.courseContentState
                                          .replaceAll("_", " ")
                                          .toLowerCase()
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.currentUserAccount?.isDementiaAdmin()
        ? _c(
            "div",
            { staticClass: "mt-8" },
            [
              _c(
                "v-row",
                { attrs: { justify: "space-between", align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "text--primary mb-3 font-weight-bold text-h6",
                      attrs: { cols: "auto" }
                    },
                    [_vm._v(" Data Sharing ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "rounded-lg white px-5 text--primary" },
                [
                  _c("v-data-table", {
                    staticClass: "dark--text rounded-b-lg rounded-t-0",
                    attrs: {
                      items: _vm.membershipsForUserAccount,
                      headers: _vm.dataSharingHeaders,
                      "no-data-text": "No memberships found for this user",
                      "loading-text": "Loading...",
                      "item-key": "id"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `item.site.name`,
                          fn: function({ item }) {
                            return [
                              _vm._v(" " + _vm._s(item.site?.name ?? "-") + " ")
                            ]
                          }
                        },
                        {
                          key: `item.bundle.name`,
                          fn: function({ item }) {
                            return [
                              _vm._v(
                                " " + _vm._s(item.bundle?.name ?? "-") + " "
                              )
                            ]
                          }
                        },
                        {
                          key: `item.createdAt`,
                          fn: function({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formatDate(item.createdAt)) +
                                  " "
                              )
                            ]
                          }
                        },
                        {
                          key: `item.archivedAt`,
                          fn: function({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.archivedAt
                                      ? _vm.formatDate(item.archivedAt)
                                      : "-"
                                  ) +
                                  " "
                              )
                            ]
                          }
                        },
                        {
                          key: `item.membershipState`,
                          fn: function({ item }) {
                            return [
                              _c(
                                "v-chip",
                                {
                                  staticClass:
                                    "text-capitalize text-caption white--text",
                                  attrs: {
                                    color: _vm.getMembershipStateColour(
                                      item.membershipState
                                    )
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.membershipState
                                          .replaceAll("_", " ")
                                          .toLowerCase()
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          }
                        },
                        {
                          key: `item.memberRole`,
                          fn: function({ item }) {
                            return [
                              _c("span", { staticClass: "text-capitalize" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.memberRole
                                        .replaceAll("_", " ")
                                        .toLowerCase()
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.showDeleteDialog,
            callback: function($$v) {
              _vm.showDeleteDialog = $$v
            },
            expression: "showDeleteDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v("Request Account Deletion")
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Are you sure you want to request deletion of this account? "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function($event) {
                          _vm.showDeleteDialog = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error",
                        loading: _vm.requestingDeletion
                      },
                      on: { click: _vm.requestDeletion }
                    },
                    [_vm._v(" Request Deletion ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.showCancelDeleteDialog,
            callback: function($$v) {
              _vm.showCancelDeleteDialog = $$v
            },
            expression: "showCancelDeleteDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v("Cancel Account Deletion Request")
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Are you sure you want to cancel the deletion request for this account? "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function($event) {
                          _vm.showCancelDeleteDialog = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "warning",
                        loading: _vm.cancelingDeletion
                      },
                      on: { click: _vm.cancelDeletion }
                    },
                    [_vm._v(" Cancel Deletion Request ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }