import { render, staticRenderFns } from "./CreateOrganisationModal.vue?vue&type=template&id=0365d932"
import script from "./CreateOrganisationModal.vue?vue&type=script&lang=js"
export * from "./CreateOrganisationModal.vue?vue&type=script&lang=js"
import style0 from "./CreateOrganisationModal.vue?vue&type=style&index=0&id=0365d932&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0365d932')) {
      api.createRecord('0365d932', component.options)
    } else {
      api.reload('0365d932', component.options)
    }
    module.hot.accept("./CreateOrganisationModal.vue?vue&type=template&id=0365d932", function () {
      api.rerender('0365d932', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Organisation/CreateOrganisationModal.vue"
export default component.exports