<template>
  <div>
    <div class="page-sub-sec">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        dense
        hide-details
        class="shrink"
      />
    </div>
    <v-data-table
      v-model="selectedCourses"
      :show-select="allowSelects"
      :items="filteredCourseContent"
      :headers="headers"
      class="dark--text rounded-b-lg rounded-t-0"
      :no-data-text="
        `There are no courses${
          search ? ' that match your search' : ''
        }`
      "
      :loading="loading"
      loading-text="Loading..."
      item-key="id"
      @click:row="
        item =>
          $router.push(
            courseContentId
              ? `/course-content/${courseContentId}/courses/${item.id}`
              : `/courses/${item.id}`
          )
      "
    >
      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ formatDate(item.updatedAt) }}
      </template>

      <template v-slot:[`item.editColumn`]="{ item }">
        <v-chip
          v-if="item.isCurrentCourseContent"
          color="primary"
          class="mr-2 text-caption font-weight-bold"
          >Current Course Content</v-chip
        >
        <delete-button
          v-if="courseGroup"
          @delete="$emit('click-delete', item.id)"
        />
        <v-btn
          v-if="courseContentId"
          icon
          @click="
            $router.push(
              `/course-content/${courseContentId}/courses/${item.id}`
            )
          "
        >
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <v-btn
          v-else
          icon
          @click="$router.push(`/courses/${item.id}`)"
        >
          <v-icon>mdi-eye-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { objectHasSearch } from '@/utils/search';
import { formatDate } from '@/utils/date';
import DeleteButton from '@/components/Common/DeleteButton.vue';

export default {
  name: 'CoursesTable',
  components: [DeleteButton],
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    courses: {
      required: true
    },
    courseGroup: {
      default: false
    },
    allowSelects: {
      default: false
    },
    courseContentView: {
      type: Boolean
    }
  },
  data: () => ({
    selectedCourses: [],
    search: '',
    headers: [
      { text: 'Name', value: 'title' },
      { text: 'Version', value: 'version' },
      { text: 'Updated At', value: 'updatedAt' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ]
  }),
  computed: {
    courseContentId() {
      return this.$route.params.courseContentId;
    },
    filteredCourseContent() {
      return this.courses
        ? this.courses.filter(course =>
            objectHasSearch(course, this.search)
          )
        : this.courses;
    }
  },
  mounted() {
    this.selectedCourses = [];
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    getStateColour(state) {
      switch (state) {
        case 'TESTING':
          return '#ff8000';
        case 'UNAVAILABLE':
          return '#ff0000';
        case 'PUBLISHED':
          return '#1ba416';
        default:
          return '#919191';
      }
    }
  }
};
</script>
