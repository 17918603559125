<template>
  <v-dialog :value="open" width="500" @click:outside="$emit('close')">
    <v-card class="rounded-lg">
      <v-card-title class="h6 font-weight-bold primary--text">
        Create Media
      </v-card-title>
      <v-card-text>
        <v-file-input
          ref="fileInput"
          v-model="input.file"
          label="Upload File"
          @change="validateFile"
          :error-messages="fileValidationErrors"
          :validation="() => {}"
          :rules="[
            v => v != undefined || 'File is required',
            v =>
              !v ||
              v.size < 5 * 1024 * 1024 ||
              'File size must be less than 5 MB'
          ]"
          :disabled="loading"
        />
        <div v-if="showNameAndTypeForm">
          <v-text-field v-model="input.name" label="Name" :disabled="loading" />
          <v-select
            v-model="input.type"
            :items="supportedFileTypes.map(t => t.name)"
            label="Type"
            :disabled="loading"
          />
        </div>
        <div v-if="processErrors.length > 0" class="red--text">
          <p v-for="error in processErrors" :key="error"><v-icon class="red--text">error</v-icon> {{ error }}</p>
        </div>
        <!-- Added loading indicator -->
        <v-progress-linear v-if="loading" indeterminate />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn @click="$emit('close')" :disabled="loading">
          Cancel
        </v-btn>
        <v-btn color="primary" @click="createMedia" :disabled="loading">
          Done
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  CreateAaaMedia,
  RequestAaaMediaUploadUrl
} from '@/graphql/AaaMedia.gql';
import axios from 'axios';
import { MEDIA_TYPES } from '@/utils/aaa';

export default {
  data() {
    return {
      input: {},
      fileValidationErrors: [],
      loading: false,
      processErrors: [],
    };
  },
  props: {
    open: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    supportedFileTypes() {
      return MEDIA_TYPES;
    },
    showNameAndTypeForm() {
      return (
        this.fileValidationErrors.length === 0 &&
        this.input.file &&
        (!this.input.file.errorMessages ||
          this.input.file.errorMessages.length === 0)
      );
    }
  },
  methods: {
    async createMedia() {
      this.loading = true;
      try {
        const selectedMediaType = this.supportedFileTypes.find(
          t => t.name === this.input.type
        );

        if (!selectedMediaType) {
          this.processErrors = [`Unsupported media type: ${this.input.type}`];
          return;
        }

        const img = await this.loadImageDataFromInput();
        const isValidWidth = img.width === selectedMediaType.width;
        const isValidHeight = img.height === selectedMediaType.height;

        if (!isValidWidth || !isValidHeight) {
          this.processErrors = [`The selected file does not match the dimensions required ` +
                          `for the media type: ${this.input.type}. Please select a file ` +
                          `with dimensions ${selectedMediaType.width}x${selectedMediaType.height}.`];
          return;
        }

        const response = await this.$apollo.mutate({
          mutation: CreateAaaMedia,
          variables: {
            aaaMedia: {
              name: this.input.name,
              mediaType: selectedMediaType.enumValue
            }
          }
        });
        this.media = response.data.createAaaMedia;
        const uploadUrlResponse = await this.$apollo.mutate({
          mutation: RequestAaaMediaUploadUrl,
          variables: {
            id: this.media.id,
            contentType: this.input.file.type
          }
        });
        const uploadUrl =
          uploadUrlResponse.data.requestAaaMediaUploadUrl;

        try {
          await axios.put(uploadUrl, this.input.file, {
            headers: { 'Content-Type': this.input.file.type }
          });
          this.$emit('success');
          this.clear();
        } catch (error) {
          this.processErrors = ['Failed to upload file.'];
        }
      } finally {
        this.loading = false;
      }
    },
    async loadImageDataFromInput() {
      const imgFile = this.input.file;
      if (!imgFile) return;

      const img = new Image();
      const imageLoadPromise = new Promise((resolve, reject) => {
        img.onload = () => {
          resolve();
        };
        img.onerror = reject;
      });
      img.src = URL.createObjectURL(imgFile);

      // Await image load
      await imageLoadPromise;
      return img;
    },
    async validateFile() {
      this.fileValidationErrors = [];
      if (this.$refs.fileInput.errorMessages.length > 0) {
        return; // Skip this if other validations have failed.
      }

      const fileType = this.input.file.type;
      const supportedMediaContentTypes = this.supportedFileTypes.flatMap(
        t => t.contentType
      );

      if (!supportedMediaContentTypes.includes(fileType)) {
        this.fileValidationErrors = ['Invalid file type'];
        return;
      }

      let identifiedMediaType = undefined;
      if (fileType.indexOf('image/') === 0) {
        const img = await this.loadImageDataFromInput();
        const matchingDimensions = this.supportedFileTypes.find(
          t => t.width === img.width && t.height === img.height
        );
        if (!matchingDimensions) {
          const supportedResolutionsString = this.supportedFileTypes
            .map(t => `${t.name}: ${t.width}x${t.height}`)
            .join(', ');
          const unsupportedResolutionError = `Unsupported resolution. Supported resolutions: ${supportedResolutionsString}`;
          this.fileValidationErrors = [unsupportedResolutionError];
          return;
        }
        identifiedMediaType = matchingDimensions;
      } else {
        identifiedMediaType = this.supportedFileTypes.find(t =>
          t.contentType.includes(fileType)
        );
        if (!identifiedMediaType) {
          const supportedTypesStr = [
            ...new Set(supportedMediaContentTypes.map(t => `'${t}'`))
          ].join(', ');
          this.fileValidationErrors = [
            `Invalid file type: '${fileType}'; valid content types: ${supportedTypesStr}`
          ];
          return;
        }
      }

      // Assuming file is valid, update the input object
      this.input.name = this.input.file.name;
      this.input.type = identifiedMediaType.name;
      this.$forceUpdate();
    },
    clear() {
      this.input = {};
      this.fileValidationErrors = [];
    }
  }
};
</script>
