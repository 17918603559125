var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.open, width: "500" },
      on: {
        input: function($event) {
          return _vm.$emit("update:open", $event)
        }
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "rounded-lg" },
        [
          _c(
            "v-card-title",
            { staticClass: "h6 font-weight-bold primary--text" },
            [_vm._v(" Edit Text Content ")]
          ),
          _c(
            "v-card-text",
            { staticClass: "py-2" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.rules.requiredField,
                      label: "Title",
                      "single-line": "",
                      outlined: "",
                      dense: "",
                      required: ""
                    },
                    model: {
                      value: _vm.localContent.title,
                      callback: function($$v) {
                        _vm.$set(_vm.localContent, "title", $$v)
                      },
                      expression: "localContent.title"
                    }
                  }),
                  _c("tiptap-vuetify", {
                    attrs: { extensions: _vm.extensions },
                    model: {
                      value: _vm.localContent.content,
                      callback: function($$v) {
                        _vm.$set(_vm.localContent, "content", $$v)
                      },
                      expression: "localContent.content"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("v-btn", { attrs: { text: "" }, on: { click: _vm.cancel } }, [
                _vm._v("Cancel")
              ]),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: !_vm.valid },
                  on: { click: _vm.save }
                },
                [_vm._v(" Save ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }