var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-sub-sec" },
        [
          _c("v-text-field", {
            staticClass: "shrink",
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search",
              outlined: "",
              dense: "",
              "hide-details": ""
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "dark--text rounded-b-lg rounded-t-0",
        attrs: {
          items: _vm.filteredPendingRequests,
          headers: _vm.headers,
          "no-data-text": `There are no pending requests${
            _vm.search ? " that match your search" : ""
          }`,
          loading: _vm.loading,
          "loading-text": "Loading...",
          "item-key": "id"
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.firstName`,
              fn: function({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        [
                          item.userAccount.firstName,
                          item.userAccount.lastName
                        ].join(" ")
                      ) +
                      " "
                  )
                ]
              }
            },
            {
              key: `item.email`,
              fn: function({ item }) {
                return [_vm._v(" " + _vm._s(item.userAccount.email) + " ")]
              }
            },
            {
              key: `item.createdAt`,
              fn: function({ item }) {
                return [
                  _vm._v(" " + _vm._s(_vm.formatDate(item.createdAt)) + " ")
                ]
              }
            },
            {
              key: `item.editColumn`,
              fn: function({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: { color: "error", small: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("deny", item.id)
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { size: "14" } },
                        [_vm._v("mdi-thumb-down-outline")]
                      ),
                      _vm._v(" Deny ")
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", small: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("approve", item.id)
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { size: "14" } },
                        [_vm._v("mdi-thumb-up-outline")]
                      ),
                      _vm._v(" Approve ")
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }