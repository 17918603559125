class AaaMedia {
  constructor(data = {}) {
    this.id = data.id;
    this.name = data.name;
    this.mediaType = data.mediaType;
    this.objectId = data.objectId;
    this.uploadedBy = data.uploadedBy;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}

export default AaaMedia;
