import enumToDisplay from '@/utils/enumToDisplay';

const SystemRoles = Object.freeze({
  CMS_ADMIN: 'CMS_ADMIN',
  DEMENTIA_ADMIN: 'DEMENTIA_ADMIN',
  USER: 'USER'
});

export const systemRolesItems = enumToDisplay(SystemRoles);

export default SystemRoles;
