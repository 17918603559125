<template>
  <loading v-if="loading" />
  <insufficient-permissions
    v-else-if="insufficientPermissions && !loading"
  />
  <v-app v-else>
    <v-navigation-drawer
      v-model="drawerState"
      app
      class="side-drawer"
    >
      <v-list class="secondary--text pa-0">
        <div
          style="background-color: #2dd5c4 !important; height: 64px;"
        >
          <v-list-item
            link
            @click="
              () =>
                $router.push(
                  `/organisation/${$route.params.organisationId}/overview`
                )
            "
          >
            <v-list-item-content class="logo-sec">
              <img src="../images/logo.svg" class="logo" />
            </v-list-item-content>
          </v-list-item>
        </div>

        <div class="primary" style="height: 36px;">
          <v-row
            no-gutters
            align="center"
            class="px-1"
            style="height: 36px;"
          >
            <v-col v-if="!currentUserAccount.isUser()" cols="auto">
              <v-btn
                color="white"
                icon
                @click="$router.push('/organisations')"
                ><v-icon>mdi-arrow-left</v-icon></v-btn
              >
            </v-col>

            <v-col class="px-1">
              <div class="white--text text-body-2">
                {{ organisation?.companyName }}
              </div>
            </v-col>

            <v-col
              v-if="
                activeStaffMemberships.length > 1 ||
                  currentUserAccount.isDementiaAdmin()
              "
              cols="auto"
            >
              <organisation-switcher
                small
                @switch-org="switchOrganisation"
              />
            </v-col>
          </v-row>
        </div>

        <div style="height: calc(100vh - 165px);overflow-y: auto;">
          <div
            v-for="(navItem, index) in navigationItems"
            :key="index"
          >
            <div
              v-if="
                currentUserAccount?.isUser()
                  ? containsUserRole(navItem.userRoles)
                  : true
              "
              class="navigation-subtitle"
            >
              {{ navItem.title }}
            </div>

            <div
              v-for="(navChild, index) in navItem.children"
              :key="`${navItem.title.replaceAll(' ', '_')}-${index}`"
            >
              <v-list-item
                v-if="
                  currentUserAccount?.isUser()
                    ? containsUserRole(navItem.userRoles)
                    : true
                "
                link
                active-class="navigation-item-active"
                :to="
                  `/organisation/${$route.params.organisationId}${navChild.to}`
                "
              >
                <v-list-item-action>
                  <v-icon>{{ navChild.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    navChild.title
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>

            <v-divider
              v-if="
                currentUserAccount?.isUser()
                  ? containsUserRole(navItem.userRoles)
                  : true
              "
              class="my-3"
            />
          </div>
        </div>

        <div class="navigation-bottom-bar">
          <v-row
            class="align-center px-2 text--primary"
            style="height: 64px;"
            no-gutters
          >
            <v-col cols="auto">
              <organisation-switcher
                @switch-org="switchOrganisation"
              />
            </v-col>

            <v-col cols="auto">
              <div class="font-weight-bold">
                {{
                  [
                    currentUserAccount?.firstName,
                    currentUserAccount?.lastName
                  ].join(' ')
                }}
              </div>
              <div
                class="text-capitalize text--secondary"
                style="font-size: 12px;"
              >
                {{
                  currentUserAccount?.isUser()
                    ? activeMembership?.memberRole
                        .replaceAll('_', ' ')
                        .toLowerCase()
                    : currentUserAccount?.systemRole
                        .replaceAll('_', ' ')
                        .toLowerCase()
                }}
              </div>
            </v-col>

            <v-col cols="auto" class="ml-auto">
              <v-btn icon @click="logout"
                ><v-icon size="24">mdi-logout</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-main class="main-application">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { organisationNavigationItems } from '@/utils/navigation';
import { auth } from '@/main';
import { CurrentUserAccount } from '@/graphql/UserAccount.gql';
import { Organisation } from '@/graphql/Organisation.gql';
import OrganisationSwitcher from '@/components/Common/OrganisationSwitcher';
import { MembershipsForCurrentUserAccount } from '@/graphql/Membership.gql';
import InsufficientPermissions from '@/pages/InsufficientPermissions';
import Loading from '@/pages/Loading';

export default {
  name: 'OrganisationLayout',
  components: {
    OrganisationSwitcher,
    InsufficientPermissions,
    Loading
  },
  data: () => ({
    drawerState: null,
    insufficientPermissions: false
  }),
  computed: {
    ...mapState('user', ['currentUserAccount']),
    navigationItems() {
      return organisationNavigationItems;
    },
    loading() {
      return (
        this.$apollo.queries.currentUserAccount.loading ||
        this.$apollo.queries.membershipsForCurrentUserAccount
          .loading ||
        this.$apollo.queries.organisation.loading
      );
    },
    activeMembership() {
      return this.membershipsForCurrentUserAccount.find(
        membership =>
          !membership.archivedAt &&
          membership.membershipState === 'ACTIVE' &&
          membership.organisation.id ===
            this.$route.params.organisationId
      );
    },
    activeOrgStaffMemberships() {
      return this.membershipsForCurrentUserAccount.filter(
        membership =>
          !membership.archivedAt &&
          membership.membershipState === 'ACTIVE' &&
          (membership.memberRole === 'ORG_ADMIN' ||
            membership.memberRole === 'ORG_VIEWER')
      );
    },
    activeSiteStaffMemberships() {
      return this.membershipsForCurrentUserAccount.filter(
        membership =>
          !membership.archivedAt &&
          membership.membershipState === 'ACTIVE' &&
          (membership.memberRole === 'SITE_ADMIN' ||
            membership.memberRole === 'SITE_VIEWER')
      );
    },
    activeStaffMemberships() {
      return this.activeOrgStaffMemberships.concat(
        this.activeSiteStaffMemberships
      );
    }
  },
  apollo: {
    currentUserAccount: {
      query: CurrentUserAccount,
      fetchPolicy: 'no-cache',
      result({ data }) {
        this.updateCurrentUserAccount(data);
      }
    },
    organisation: {
      query: Organisation,
      variables() {
        return {
          id: this.$route.params.organisationId
        };
      }
    },
    membershipsForCurrentUserAccount: {
      query: MembershipsForCurrentUserAccount,
      result({ data }) {
        const membership = data.membershipsForCurrentUserAccount.find(
          membership =>
            membership.organisation.id ===
            this.$route.params.organisationId
        );

        if (!membership && this.currentUserAccount.isUser()) {
          this.insufficientPermissions = true;
        }
      }
    }
  },
  methods: {
    ...mapActions('user', ['updateCurrentUserAccount']),
    logout() {
      auth.signOut().then(() => this.$router.push('/'));
    },
    containsUserRole(userRoles) {
      return userRoles.includes(this.activeMembership.memberRole);
    },
    switchOrganisation(id) {
      const membershipForOrgId = this.activeStaffMemberships.find(
        membership => membership.organisation.id === id
      );

      this.$router.push(
        `/organisation/${id}/${
          membershipForOrgId.memberRole === 'SITE_ADMIN' ||
          membershipForOrgId.memberRole === 'SITE_VIEWER'
            ? 'sites'
            : 'learners'
        }`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.side-drawer
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
  .col.col-auto
  i {
  color: #fff !important;
}
</style>
