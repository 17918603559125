<template>
  <div>
    <div class="page-sub-sec">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        dense
        hide-details
        class="shrink"
      />
    </div>
    <v-data-table
      :items="filteredCourseGroup"
      :headers="headers"
      class="dark--text rounded-b-lg rounded-t-0"
      :no-data-text="
        `There are no course groups${
          search ? ' that match your search' : ''
        }`
      "
      :loading="loading"
      loading-text="Loading..."
      item-key="id"
      @click:row="
        item =>
          $router.push(
            `/course-content/${courseContentId}/course-groups/${item.id}`
          )
      "
    >
      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ formatDate(item.updatedAt) }}
      </template>

      <template v-slot:[`item.editColumn`]="{ item, index }">
        <v-btn
          v-if="index != 0"
          text
          class="pa-0 rounded-md"
          icon
          :disabled="changingDisplayIndex"
          @click="decreaseDisplayIndex(index)"
        >
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
        <v-btn
          v-if="filteredCourseGroup.length - 1 != index"
          text
          class="pa-0 rounded-md"
          icon
          :disabled="changingDisplayIndex"
          @click="increaseDisplayIndex(index)"
        >
          <v-icon>mdi-arrow-down</v-icon>
        </v-btn>
        <v-btn
          text
          class="pa-0 rounded-md"
          icon
          @click="
            $router.push(
              `/course-content/${courseContentId}/course-groups/${item.id}`
            )
          "
        >
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <delete-button @delete="deleteCourseGroup(item.id)" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { objectHasSearch } from '@/utils/search';
import { formatDate } from '@/utils/date';
import {
  IncreaseDisplayIndex,
  DecreaseDisplayIndex,
  DeleteCourseGroup
} from '@/graphql/CourseGroup.gql';
import DeleteButton from '@/components/Common/DeleteButton.vue';

export default {
  name: 'CourseGroupTable',
  components: { DeleteButton },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    courseGroups: {
      required: true
    }
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Title', value: 'title', sortable: false },
      {
        text: 'Last Updated At',
        value: 'updatedAt',
        sortable: false
      },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ],
    changingDisplayIndex: false
  }),
  computed: {
    courseContentId() {
      return this.$route.params.courseContentId;
    },
    filteredCourseGroup() {
      return this.courseGroups
        ? this.courseGroups.filter(group =>
            objectHasSearch(group, this.search)
          )
        : this.courseGroups;
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    async increaseDisplayIndex(index) {
      try {
        await this.$apollo.mutate({
          mutation: IncreaseDisplayIndex,
          variables: {
            id: this.filteredCourseGroup[index].id,
            otherCourseGroupId: this.filteredCourseGroup[index + 1].id
          }
        });

        this.$emit('refetch');
      } catch (e) {
        e.graphQLErrors.map(x => this.$toast.error(x.message));
      }
    },
    async decreaseDisplayIndex(index) {
      try {
        await this.$apollo.mutate({
          mutation: DecreaseDisplayIndex,
          variables: {
            id: this.filteredCourseGroup[index].id,
            otherCourseGroupId: this.filteredCourseGroup[index - 1].id
          }
        });

        this.$emit('refetch');
      } catch (e) {
        console.log(e);
        e.graphQLErrors.map(x => this.$toast.error(x.message));
      }
    },
    async deleteCourseGroup(id) {
      try {
        await this.$apollo.mutate({
          mutation: DeleteCourseGroup,
          variables: {
            id: id,
            courseContentId: this.courseContentId
          }
        });

        this.$emit('refetch');
      } catch (e) {
        console.log(e);
        e.graphQLErrors.map(x => this.$toast.error(x.message));
      }
    }
  }
};
</script>
