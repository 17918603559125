<template>
  <div>
    <div class="page-sub-sec">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        dense
        hide-details
        class="shrink"
      />
    </div>
    <v-data-table
      v-model="selectedLessons"
      :show-select="allowSelects"
      :items="filteredLessonContent"
      :headers="headers"
      class="dark--text rounded-b-lg rounded-t-0"
      :no-data-text="
        `There are no lessons${
          search ? ' that match your search' : ''
        }`
      "
      :single-select="singleSelect"
      :loading="loading"
      loading-text="Loading..."
      item-key="id"
      @click:row="
        item =>
          $router.push(
            courseContentId
              ? `/course-content/${courseContentId}/lessons/${item.id}`
              : `/lessons/${item.id}`
          )
      "
    >
      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ formatDate(item.updatedAt) }}
      </template>

      <template v-slot:[`item.editColumn`]="{ item }">
        <delete-button
          v-if="lessonGroup"
          @delete="$emit('click-delete', item.id)"
        />
        <v-btn
          v-if="!lessonGroup && !allowSelects && courseContentId"
          icon
          @click="
            $router.push(
              `/course-content/${courseContentId}/lessons/${item.id}`
            )
          "
        >
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <v-btn
          v-else
          icon
          @click="$router.push(`/lessons/${item.id}`)"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { objectHasSearch } from '@/utils/search';
import { formatDate } from '@/utils/date';
import DeleteButton from '@/components/Common/DeleteButton.vue';

export default {
  name: 'LessonsTable',
  components: [DeleteButton],
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    lessons: {
      required: true
    },
    lessonGroup: {
      default: false
    },
    allowSelects: {
      default: false
    },
    courseContentView: {
      type: Boolean
    },
    singleSelect: {
      default: false
    }
  },
  data: () => ({
    selectedLessons: [],
    search: '',
    headers: [
      { text: 'Name', value: 'title' },
      { text: 'Version', value: 'version' },
      { text: 'Updated At', value: 'updatedAt' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ]
  }),
  computed: {
    courseContentId() {
      return this.$route.params.courseContentId;
    },
    filteredLessonContent() {
      return this.lessons
        ? this.lessons.filter(lesson =>
            objectHasSearch(lesson, this.search)
          )
        : this.lessons;
    }
  },
  mounted() {
    this.selectedLessons = [];
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    getStateColour(state) {
      switch (state) {
        case 'TESTING':
          return '#ff8000';
        case 'UNAVAILABLE':
          return '#ff0000';
        case 'PUBLISHED':
          return '#1ba416';
        default:
          return '#919191';
      }
    }
  }
};
</script>
