var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "primary--text mb-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mr-auto", attrs: { cols: "auto" } },
                        [_c("h1", [_vm._v("Organisations")])]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mr-auto", attrs: { cols: "auto" } },
                        [
                          _c("div", { staticClass: "page-subtitle" }, [
                            _vm._v(
                              " A place where you can view all the organisations. "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.createModalOpen = true
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-plus")
                      ]),
                      _vm._v("New Organisation")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "page-sub-sec" },
            [
              _c("v-text-field", {
                staticClass: "shrink",
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: "Search",
                  outlined: "",
                  dense: "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "dark--text rounded-b-lg rounded-t-0",
            attrs: {
              items: _vm.filteredOrganisations,
              headers: _vm.headers,
              "no-data-text": `There are no organisations${
                _vm.search ? " that match your search" : ""
              }`,
              loading: _vm.$apollo.queries.organisations.loading,
              "loading-text": "Loading...",
              "item-key": "id"
            },
            on: {
              "click:row": item =>
                _vm.$router.push(`/organisation/${item.id}/overview`)
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.organisationCode`,
                  fn: function({ item }) {
                    return [
                      _c("v-chip", { staticClass: "text-button" }, [
                        _vm._v(_vm._s(item.organisationCode))
                      ])
                    ]
                  }
                },
                {
                  key: `item.editColumn`,
                  fn: function({ item }) {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push(
                                `/organisation/${item.id}/overview`
                              )
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-arrow-right")])],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c("create-organisation-modal", {
        attrs: { open: _vm.createModalOpen },
        on: {
          close: function($event) {
            _vm.createModalOpen = false
          },
          created: _vm.onCreated
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }