<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on">
          <v-icon class="mr-2">mdi-plus</v-icon>
          New Variation
        </v-btn>
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          New Question Variation
        </v-card-title>

        <v-card-text class="py-2">
          <v-form ref="newQuestionVariationForm">
            <v-label>Question Type</v-label>
            <v-select
              v-model="questionType"
              :rules="rules.requiredField"
              :items="variantTypeItems"
              label="Question Type"
              single-line
              outlined
              dense
              required
            />

            <v-label>Question Text</v-label>
            <tiptap-vuetify
              class="mb-4"
              v-model="questionText"
              :extensions="extensions"
            />

            <v-label>Incorrect Answer Feedback</v-label>
            <v-text-field
              v-model="incorrectAnswerFeedback"
              :rules="rules.requiredField"
              label="Incorrect Answer Feedback"
              single-line
              outlined
              dense
              required
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="secondary" @click="addQuestionVariation">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { variantTypeItems } from '@/enums/VariantType';
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
  History
} from 'tiptap-vuetify';

export default {
  name: 'CreateVariationModal',
  components: { TiptapVuetify },
  props: {
    staff: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    organisationId: {
      type: String,
      default: null
    },
    siteId: {
      type: String,
      default: null
    }
  },
  data: () => ({
    dialog: false,
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ]
    },
    extensions: [
      History,
      Underline,
      Italic,
      ListItem, // if you need to use a list (BulletList, OrderedList)
      BulletList,
      OrderedList,
      [
        Heading,
        {
          // Options that fall into the tiptap's extension
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      Bold,
      HardBreak // line break on Shift + Ctrl + Enter
    ],
    id: uuidv4(),
    questionType: 'SINGLE_TEXT_CHOICE',
    questionText: '',
    incorrectAnswerFeedback: ''
  }),
  watch: {
    dialog(dialog) {
      if (!dialog) {
        this.resetForm();
        this.$refs.newQuestionVariationForm.reset();
      } else {
        this.resetForm();
      }
    }
  },
  computed: {
    variantTypeItems() {
      return variantTypeItems;
    }
  },
  methods: {
    resetForm() {
      this.id = uuidv4();
      this.questionType = 'SINGLE_TEXT_CHOICE';
      this.questionText = '';
      this.incorrectAnswerFeedback = '';
    },
    async addQuestionVariation() {
      if (this.$refs.newQuestionVariationForm.validate()) {
        try {
          this.$emit('add-variation', {
            id: this.id,
            variationType: this.questionType,
            questionText: this.questionText,
            incorrectAnswerFeedback: this.incorrectAnswerFeedback
          });
          this.dialog = false;
        } catch (e) {
          e.graphQLErrors.map(x => this.$toast.error(x.message));
        }
      }
    }
  }
};
</script>
