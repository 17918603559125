var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.open, width: "500" },
      on: {
        "click:outside": function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "rounded-lg" },
        [
          _c(
            "v-card-title",
            { staticClass: "h6 font-weight-bold primary--text" },
            [_vm._v(" New Site ")]
          ),
          _c(
            "v-card-text",
            { staticClass: "py-2" },
            [
              _c(
                "v-form",
                {
                  ref: "createSiteForm",
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("v-label", [_vm._v("Name")]),
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.rules.requiredField,
                      label: "Name",
                      "single-line": "",
                      outlined: "",
                      dense: "",
                      required: ""
                    },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  }),
                  _c("v-label", [_vm._v("Address")]),
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.rules.requiredField,
                      label: "Address",
                      "single-line": "",
                      outlined: "",
                      dense: "",
                      required: ""
                    },
                    model: {
                      value: _vm.address,
                      callback: function($$v) {
                        _vm.address = $$v
                      },
                      expression: "address"
                    }
                  }),
                  _c("v-label", [_vm._v("Country")]),
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.rules.requiredField,
                      label: "Country",
                      "single-line": "",
                      outlined: "",
                      dense: "",
                      required: ""
                    },
                    model: {
                      value: _vm.country,
                      callback: function($$v) {
                        _vm.country = $$v
                      },
                      expression: "country"
                    }
                  }),
                  _c("v-label", [_vm._v("Website")]),
                  _c("v-text-field", {
                    attrs: {
                      label: "Website",
                      "single-line": "",
                      outlined: "",
                      dense: ""
                    },
                    model: {
                      value: _vm.website,
                      callback: function($$v) {
                        _vm.website = $$v
                      },
                      expression: "website"
                    }
                  }),
                  _c("v-label", [_vm._v("Site Code")]),
                  _c("v-text-field", {
                    staticClass: "site-code-text-input",
                    attrs: {
                      rules: _vm.rules.siteCode,
                      label: "Site Code",
                      "single-line": "",
                      outlined: "",
                      dense: "",
                      required: ""
                    },
                    model: {
                      value: _vm.siteCode,
                      callback: function($$v) {
                        _vm.siteCode = $$v
                      },
                      expression: "siteCode"
                    }
                  }),
                  _c("v-switch", {
                    staticClass: "mt-0",
                    attrs: {
                      label: "New data share requests require approval",
                      inset: ""
                    },
                    model: {
                      value: _vm.privateSite,
                      callback: function($$v) {
                        _vm.privateSite = $$v
                      },
                      expression: "privateSite"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary" },
                  on: { click: _vm.createSite }
                },
                [_vm._v(" Create ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }