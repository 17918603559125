var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rounded-lg white px-5 py-1 text--primary" },
    [
      _c(
        "v-row",
        {
          staticClass: "info-row py-2",
          attrs: { "no-gutters": "", align: "center" }
        },
        [
          _c(
            "v-col",
            { staticClass: "mr-auto title-column", attrs: { cols: "auto" } },
            [_vm._v(" Node Label ")]
          ),
          _c(
            "v-col",
            { staticClass: "ml-auto", attrs: { cols: "auto" } },
            [
              _c("edit-field", {
                attrs: {
                  readonly: !_vm.courseContentId,
                  "current-value": _vm.variation.label
                },
                on: { "update-value": newValue => _vm.updateLabel(newValue) }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "info-row py-2",
          attrs: { "no-gutters": "", align: "center" }
        },
        [
          _c(
            "v-col",
            { staticClass: "mr-auto title-column", attrs: { cols: "auto" } },
            [_vm._v(" Node Type ")]
          ),
          _c(
            "v-col",
            { staticClass: "ml-auto", attrs: { cols: "auto" } },
            [
              _c("edit-field", {
                attrs: {
                  readonly: _vm.index === 0 || !_vm.courseContentId,
                  "display-value": _vm.variation.branchingNodeType
                    .replaceAll("_", " ")
                    .toLowerCase(),
                  "current-value": _vm.variation.branchingNodeType,
                  "select-items": _vm.branchingNodeTypeItems,
                  type: "select"
                },
                on: {
                  "update-value": newValue =>
                    _vm.updateBranchingNodeType(newValue)
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "info-row py-2",
          attrs: { "no-gutters": "", align: "center" }
        },
        [
          _c(
            "v-col",
            { staticClass: "mr-auto title-column", attrs: { cols: "auto" } },
            [_vm._v(" Images ")]
          ),
          _c(
            "v-col",
            { staticClass: "ml-auto", attrs: { cols: "auto" } },
            [
              _c(
                "v-row",
                { attrs: { align: "center", "no-gutters": "" } },
                [
                  _vm.isUploadingImages
                    ? _c("v-progress-circular", {
                        staticClass: "mr-2",
                        attrs: { indeterminate: "", size: "24" }
                      })
                    : _vm._e(),
                  _c(
                    "edit-field",
                    {
                      attrs: {
                        readonly: !_vm.courseContentId,
                        type: "file",
                        "add-icon": ""
                      },
                      on: { "update-value": _vm.addImage }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        _vm._l(_vm.variation.images, function(image, index) {
                          return _c(
                            "div",
                            {
                              key: image.id,
                              class: `${
                                index === _vm.variation.images.length - 1
                                  ? ""
                                  : "mr-2"
                              }`
                            },
                            [
                              _vm._v(" " + _vm._s(image.objectId) + " "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "error",
                                    "x-small": "",
                                    icon: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteImage(image.id)
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { size: "14" } }, [
                                    _vm._v("mdi-delete-outline")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    index === _vm.variation.images.length - 1
                                      ? ""
                                      : ", "
                                  ) +
                                  " "
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "info-row py-2",
          attrs: { "no-gutters": "", align: "center" }
        },
        [
          _c(
            "v-col",
            { staticClass: "mr-auto title-column", attrs: { cols: "auto" } },
            [_vm._v(" Videos ")]
          ),
          _c(
            "v-col",
            { staticClass: "ml-auto", attrs: { cols: "auto" } },
            [
              _c(
                "v-row",
                { attrs: { align: "center", "no-gutters": "" } },
                [
                  _vm.isUploadingVideos
                    ? _c("v-progress-circular", {
                        staticClass: "mr-2",
                        attrs: { indeterminate: "", size: "24" }
                      })
                    : _vm._e(),
                  _c(
                    "edit-field",
                    {
                      attrs: {
                        readonly: !_vm.courseContentId,
                        type: "file",
                        "add-icon": ""
                      },
                      on: { "update-value": _vm.addVideo }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        _vm._l(_vm.variation.videos, function(video, index) {
                          return _c(
                            "div",
                            {
                              key: video.id,
                              class: `${
                                index === _vm.variation.videos.length - 1
                                  ? ""
                                  : "mr-2"
                              }`
                            },
                            [
                              _vm._v(" " + _vm._s(video.objectId) + " "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "error",
                                    "x-small": "",
                                    icon: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteVideo(video.id)
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { size: "14" } }, [
                                    _vm._v("mdi-delete-outline")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    index === _vm.variation.videos.length - 1
                                      ? ""
                                      : ", "
                                  ) +
                                  " "
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "info-row py-2",
          attrs: { "no-gutters": "", align: "center" }
        },
        [
          _c(
            "v-col",
            { staticClass: "mr-auto title-column", attrs: { cols: "auto" } },
            [_vm._v(" PDFs ")]
          ),
          _c(
            "v-col",
            { staticClass: "ml-auto", attrs: { cols: "auto" } },
            [
              _c(
                "v-row",
                { attrs: { align: "center", "no-gutters": "" } },
                [
                  _vm.isUploadingPdfs
                    ? _c("v-progress-circular", {
                        staticClass: "mr-2",
                        attrs: { indeterminate: "", size: "24" }
                      })
                    : _vm._e(),
                  _c(
                    "edit-field",
                    {
                      attrs: {
                        readonly: !_vm.courseContentId,
                        type: "file",
                        "add-icon": ""
                      },
                      on: { "update-value": _vm.addPdf }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        _vm._l(_vm.variation.pdfs, function(pdf, index) {
                          return _c(
                            "div",
                            {
                              key: pdf.id,
                              class: `${
                                index === _vm.variation.pdfs.length - 1
                                  ? ""
                                  : "mr-2"
                              }`
                            },
                            [
                              _vm._v(" " + _vm._s(pdf.objectId) + " "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "error",
                                    "x-small": "",
                                    icon: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.deletePdf(pdf.id)
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { size: "14" } }, [
                                    _vm._v("mdi-delete-outline")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    index === _vm.variation.pdfs.length - 1
                                      ? ""
                                      : ", "
                                  ) +
                                  " "
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.variation.branchingNodeType === "EXIT_WITH_FEEDBACK" ||
      _vm.variation.branchingNodeType === "EXIT_WITHOUT_FEEDBACK"
        ? _c(
            "v-row",
            {
              staticClass: "info-row py-2",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "mr-auto title-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Outcome ")]
              ),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c("edit-field", {
                    attrs: {
                      readonly: !_vm.courseContentId,
                      "current-value": _vm.variation.outcome,
                      type: "select",
                      "select-items": _vm.branchingOutcomeTypeItems,
                      "display-value": _vm.variation.outcome
                        .replaceAll("_", " ")
                        .toLowerCase()
                    },
                    on: {
                      "update-value": newValue => _vm.updateOutcome(newValue)
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.variation.branchingNodeType === "EXIT_WITH_FEEDBACK"
        ? _c(
            "v-row",
            {
              staticClass: "info-row py-2",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "mr-auto title-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Heading ")]
              ),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c("edit-field", {
                    attrs: {
                      readonly: !_vm.courseContentId,
                      "current-value": _vm.variation.feedbackHeading
                    },
                    on: {
                      "update-value": newValue =>
                        _vm.updateFeedbackHeading(newValue)
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.variation.branchingNodeType === "EXIT_WITH_FEEDBACK"
        ? _c(
            "v-row",
            {
              staticClass: "info-row py-2",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "mr-auto title-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Message ")]
              ),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c("edit-field", {
                    attrs: {
                      readonly: !_vm.courseContentId,
                      "current-value": _vm.variation.feedbackMessage
                    },
                    on: {
                      "update-value": newValue =>
                        _vm.updateFeedbackMessage(newValue)
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.variation.branchingNodeType === "QUESTION"
        ? _c(
            "v-row",
            {
              staticClass: "info-row",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-data-table", {
                    staticClass: "dark--text branching-node-table",
                    attrs: {
                      items: _vm.variation.questions,
                      headers: _vm.questionHeaders,
                      "no-data-text": "There are no questions for this node",
                      "loading-text": "Loading...",
                      "item-key": "id",
                      "disable-pagination": "",
                      "disable-filtering": "",
                      "disable-sorting": "",
                      "hide-default-footer": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `header.editColumn`,
                          fn: function({ item }) {
                            return [
                              _c(
                                "div",
                                [
                                  _vm.courseContentId
                                    ? _c("branch-question-modal", {
                                        attrs: {
                                          "node-id": _vm.variation.id,
                                          nodes: _vm.nodes
                                        },
                                        on: {
                                          "create-question": _vm.addQuestion
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: `item.editColumn`,
                          fn: function({ item, index }) {
                            return [
                              !_vm.courseContentId
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "pa-0 rounded-md",
                                      attrs: { color: "error", icon: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteQuestion(index)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-delete-outline")
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: `item.iconName`,
                          fn: function({ item }) {
                            return [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { size: "20" } },
                                [_vm._v("mdi-" + _vm._s(item.iconName))]
                              ),
                              _vm._v(" " + _vm._s(item.iconName) + " ")
                            ]
                          }
                        },
                        {
                          key: `item.correct`,
                          fn: function({ item }) {
                            return [
                              _c(
                                "v-chip",
                                {
                                  staticClass:
                                    "white--text font-weight-bold text-caption",
                                  attrs: {
                                    color: item.correct ? "green" : "red"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.correct ? "Correct" : "Incorrect"
                                    )
                                  )
                                ]
                              )
                            ]
                          }
                        },
                        {
                          key: `item.linkNode`,
                          fn: function({ item }) {
                            return [
                              _c(
                                "v-chip",
                                {
                                  staticClass: "text-caption font-weight-bold"
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.nodeForId(item.linkNode)?.label)
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.courseContentId
        ? _c(
            "v-row",
            {
              staticClass: "py-2",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                { staticClass: "mx-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-caption",
                      attrs: { color: "error", "x-small": "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("delete-node", _vm.variation.id)
                        }
                      }
                    },
                    [_vm._v("Delete Node")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }