import SystemRoles from '@/enums/SystemRoles';

class UserAccount {
  constructor(data = {}) {
    this.id = data.id;
    this.email = data.email;
    this.userId = data.userId;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.additionalLearning = data.additionalLearning;
    this.systemRole = data.systemRole;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.memberships = data.memberships;
  }

  isDementiaAdmin() {
    return this.systemRole === SystemRoles.DEMENTIA_ADMIN;
  }

  isCmsAdmin() {
    return this.systemRole === SystemRoles.CMS_ADMIN;
  }

  isUser() {
    return this.systemRole === SystemRoles.USER;
  }
}

export default UserAccount;
