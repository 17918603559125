<template>
  <v-dialog
    :value="open"
    max-width="600px"
    @click:outside="cancel"
    @keydown.esc="cancel"
  >
    <v-card class="rounded-lg">
      <v-card-title class="h6 font-weight-bold primary--text">
        {{ modalTitle }}
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="input.label"
            label="Label"
            :rules="[v => !!v || 'Label is required']"
            required
          />

          <v-label>Content</v-label>
          <div class="content-list">
            <div
              v-for="(content, index) in input.content"
              :key="index"
              class="mb-2"
            >
              <v-card outlined>
                <v-card-text>
                  <div class="d-flex align-center">
                    <span class="font-weight-medium">{{
                      content.title
                    }}</span>
                    <span class="ml-2 grey--text"
                      >({{ content.contentType }})</span
                    >
                    <v-spacer />
                    <v-btn
                      v-if="
                        content.contentType?.toUpperCase() === 'TEXT'
                      "
                      icon
                      small
                      class="mr-2"
                      @click="editContent(content)"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon small @click="removeContent(content)">
                      <v-icon small>mdi-delete-outline</v-icon>
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>

          <create-content-modal
            @create-content="addContent"
            class="mt-4"
          />

          <edit-text-content-modal
            v-if="isEditModalOpen"
            :content="editingContent"
            :open.sync="isEditModalOpen"
            @save="updateContent"
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-end">
        <span v-if="error" class="red--text mr-4">{{ error }}</span>
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="!valid || !input.content.length"
          @click="save"
        >
          {{ submitButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  CreateAppInstruction,
  UpdateAppInstruction
} from '@/graphql/AppInstructions.gql';
import CreateContentModal from '@/components/Common/CreateContentModal.vue';
import EditTextContentModal from '@/components/Common/EditTextContentModal.vue';

export default {
  name: 'CreateInstructionModal',
  components: {
    CreateContentModal,
    EditTextContentModal
  },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    instructionToEdit: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    valid: false,
    loading: false,
    error: null,
    input: {
      label: '',
      content: []
    },
    editingContent: null,
    isEditModalOpen: false
  }),
  watch: {
    instructionToEdit: {
      immediate: true,
      handler(instruction) {
        if (instruction) {
          this.input = {
            label: instruction.label,
            content: [...instruction.content]
          };
        }
      }
    }
  },
  computed: {
    isEditing() {
      return !!this.instructionToEdit;
    },
    modalTitle() {
      return this.isEditing
        ? 'Edit App Instruction'
        : 'Create App Instruction';
    },
    submitButtonText() {
      return this.isEditing ? 'Save' : 'Create';
    }
  },
  methods: {
    editContent(content) {
      if (content.contentType === 'TEXT') {
        this.editingContent = {
          title: content.title,
          content: content.content,
          contentType: content.contentType
        };
        this.isEditModalOpen = true;
      }
    },
    updateContent(updatedContent) {
      const index = this.input.content.findIndex(
        c =>
          c.title === this.editingContent.title &&
          c.content === this.editingContent.content
      );
      if (index > -1) {
        this.input.content.splice(index, 1, updatedContent);
      }
      this.editingContent = null;
      this.isEditModalOpen = false;
    },
    addContent(content) {
      this.input.content.push(content);
    },
    removeContent(content) {
      const index = this.input.content.findIndex(c => c === content);
      if (index > -1) {
        this.input.content.splice(index, 1);
      }
    },
    cleanContentData(content) {
      return content.map(item => ({
        title: item.title,
        content: item.content,
        type: item.type,
        contentType: item.contentType
      }));
    },
    async save() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;
      this.error = null;

      try {
        const cleanedContent = this.cleanContentData(
          this.input.content
        );

        await this.$apollo.mutate({
          mutation: this.isEditing
            ? UpdateAppInstruction
            : CreateAppInstruction,
          variables: this.isEditing
            ? {
                id: this.instructionToEdit.id,
                instruction: {
                  label: this.input.label,
                  content: cleanedContent
                }
              }
            : {
                instruction: {
                  label: this.input.label,
                  content: cleanedContent
                }
              }
        });

        this.$emit('success');
        this.clear();
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    clear() {
      this.input = {
        label: '',
        content: []
      };
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.error = null;
      this.valid = false;
    }
  }
};
</script>

<style scoped>
.content-list {
  max-height: 300px;
  overflow-y: auto;
}
</style>
