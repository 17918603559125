var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mr-3",
                          attrs: { color: "primary", small: _vm.dense }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-plus")
                      ]),
                      _vm._v("New Course Grouping")
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "rounded-lg" },
            [
              _c(
                "v-card-title",
                { staticClass: "h6 font-weight-bold primary--text" },
                [_vm._v(" New Course Group ")]
              ),
              _c(
                "v-card-text",
                { staticClass: "py-2" },
                [
                  _c(
                    "v-form",
                    { ref: "createCourseGroupForm" },
                    [
                      _c("v-label", [_vm._v("Title")]),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.rules.requiredField,
                          label: "Title",
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          required: ""
                        },
                        model: {
                          value: _vm.title,
                          callback: function($$v) {
                            _vm.title = $$v
                          },
                          expression: "title"
                        }
                      }),
                      _c("v-label", [_vm._v("Description")]),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.rules.requiredField,
                          label: "Description",
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          required: ""
                        },
                        model: {
                          value: _vm.description,
                          callback: function($$v) {
                            _vm.description = $$v
                          },
                          expression: "description"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "secondary",
                        disabled: _vm.creatingCourseGroup,
                        loading: _vm.creatingCourseGroup
                      },
                      on: { click: _vm.createCourseGroup }
                    },
                    [_vm._v(" Create ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }