<template>
  <div>
    <div class="page-sub-sec">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        dense
        hide-details
        class="shrink"
      />
    </div>
    <v-data-table
      :items="filteredPendingRequests"
      :headers="headers"
      class="dark--text rounded-b-lg rounded-t-0"
      :no-data-text="
        `There are no pending requests${
          search ? ' that match your search' : ''
        }`
      "
      :loading="loading"
      loading-text="Loading..."
      item-key="id"
    >
      <template v-slot:[`item.firstName`]="{ item }">
        {{
          [
            item.userAccount.firstName,
            item.userAccount.lastName
          ].join(' ')
        }}
      </template>

      <template v-slot:[`item.email`]="{ item }">
        {{ item.userAccount.email }}
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <template v-slot:[`item.editColumn`]="{ item }">
        <v-btn
          class="mr-1"
          color="error"
          small
          @click="$emit('deny', item.id)"
        >
          <v-icon size="14" class="mr-1"
            >mdi-thumb-down-outline</v-icon
          >
          Deny
        </v-btn>
        <v-btn
          color="primary"
          small
          @click="$emit('approve', item.id)"
        >
          <v-icon size="14" class="mr-1">mdi-thumb-up-outline</v-icon>
          Approve
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { objectHasSearch } from '@/utils/search';
import { formatDate } from '@/utils/date';

export default {
  name: 'PendingRequestsTable',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    pendingRequests: {
      required: true
    }
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Name', value: 'firstName' },
      { text: 'Email', value: 'email' },
      { text: 'Site', value: 'site.name' },
      { text: 'Bundle', value: 'bundle.name' },
      { text: 'Employee ID', value: 'employeeIdentifier' },
      { text: 'Requested On', value: 'createdAt' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ]
  }),
  computed: {
    filteredPendingRequests() {
      return this.search
        ? this.pendingRequests.filter(pendingRequest =>
            objectHasSearch(pendingRequest, this.search)
          )
        : this.pendingRequests;
    }
  },
  methods: {
    formatDate(dateTime) {
      return formatDate(dateTime);
    }
  }
};
</script>
