import { render, staticRenderFns } from "./CourseContentLayout.vue?vue&type=template&id=b0d5ac8e&scoped=true"
import script from "./CourseContentLayout.vue?vue&type=script&lang=js"
export * from "./CourseContentLayout.vue?vue&type=script&lang=js"
import style0 from "./CourseContentLayout.vue?vue&type=style&index=0&id=b0d5ac8e&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0d5ac8e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b0d5ac8e')) {
      api.createRecord('b0d5ac8e', component.options)
    } else {
      api.reload('b0d5ac8e', component.options)
    }
    module.hot.accept("./CourseContentLayout.vue?vue&type=template&id=b0d5ac8e&scoped=true", function () {
      api.rerender('b0d5ac8e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/CourseContentLayout.vue"
export default component.exports