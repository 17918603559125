const MEDIA_TYPES = [
  {
    name: 'Strategy Image',
    contentType: ['image/png', 'image/jpeg'],
    width: 1920,
    height: 1080,
    enumValue: 'STRATEGY',
    icon: 'image'
  },
  {
    name: 'Question Image',
    contentType: ['image/png', 'image/jpeg'],
    width: 1080,
    height: 1080,
    enumValue: 'QUESTION',
    icon: 'image'
  },
  {
    name: 'Intro Screen Image',
    contentType: ['image/png', 'image/jpeg'],
    width: 1080,
    height: 1080,
    enumValue: 'INTRO_SCREEN',
    icon: 'image'
  },
  {
    name: 'Audio',
    contentType: ['audio/mpeg', 'video/mp4'],
    enumValue: 'AUDIO',
    icon: 'music_note'
  }
];

function mediaTypeByEnumValue(enumValue) {
  return MEDIA_TYPES.find(
    mediaType => mediaType.enumValue === enumValue
  );
}

function mediaTypeByName(name) {
  return MEDIA_TYPES.find(mediaType => mediaType.name === name);
}

export { MEDIA_TYPES, mediaTypeByEnumValue, mediaTypeByName };
