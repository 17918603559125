<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" small v-bind="attrs" v-on="on">
          <v-icon>mdi-plus</v-icon>
          Answer</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          New Option
        </v-card-title>

        <v-card-text class="py-2">
          <v-form ref="createOrderListOptionForm">
            <v-label>Option</v-label>
            <v-textarea
              v-model="option"
              :rules="rules.requiredField"
              label="Option"
              single-line
              rows="2"
              outlined
              dense
              required
            />

            <v-label>Correct Position</v-label>
            <v-text-field
              v-model="correctPosition"
              :rules="rules.requiredField"
              label="Correct Position"
              type="number"
              single-line
              outlined
              dense
              required
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="secondary" @click="createOrderListOption">
            {{ existingOption ? 'Update' : 'Create' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'CreateOrderListOptionModal',
  props: {
    existingOption: {
      default: undefined
    }
  },
  data: () => ({
    dialog: false,
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ]
    },
    option: '',
    correctPosition: undefined
  }),
  watch: {
    dialog(dialog) {
      if (!dialog) {
        this.$emit('reset-existing-option');
        this.$refs.createOrderListOptionForm.reset();
      } else {
        this.option = this.existingOption?.option ?? '';
        this.correctPosition =
          this.existingOption?.correctPosition ?? undefined;
      }
    }
  },
  methods: {
    resetForm() {
      this.option = '';
      this.correctPosition = undefined;
    },
    async createOrderListOption() {
      if (this.$refs.createOrderListOptionForm.validate()) {
        try {
          if (this.existingOption) {
            this.$emit('update-option', {
              id: this.existingOption.id,
              option: this.option,
              correctPosition: this.correctPosition
            });
          } else {
            this.$emit('add-option', {
              option: this.option,
              correctPosition: this.correctPosition
            });
          }
          this.dialog = false;
        } catch (e) {
          e.graphQLErrors.map(x => this.$toast.error(x.message));
        }
      }
    }
  }
};
</script>
