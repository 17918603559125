var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("loading")
    : _vm.insufficientPermissions && !_vm.loading
    ? _c("insufficient-permissions")
    : _c(
        "v-app",
        [
          _c(
            "v-navigation-drawer",
            {
              staticClass: "side-drawer",
              attrs: { app: "" },
              model: {
                value: _vm.drawerState,
                callback: function($$v) {
                  _vm.drawerState = $$v
                },
                expression: "drawerState"
              }
            },
            [
              _c("v-list", { staticClass: "secondary--text pa-0" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "background-color": "#2dd5c4 !important",
                      height: "64px"
                    }
                  },
                  [
                    _c(
                      "v-list-item",
                      {
                        attrs: { link: "" },
                        on: {
                          click: () =>
                            _vm.$router.push(
                              `/organisation/${_vm.$route.params.organisationId}/overview`
                            )
                        }
                      },
                      [
                        _c("v-list-item-content", { staticClass: "logo-sec" }, [
                          _c("img", {
                            staticClass: "logo",
                            attrs: { src: require("../images/logo.svg") }
                          })
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "primary", staticStyle: { height: "36px" } },
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "px-1",
                        staticStyle: { height: "36px" },
                        attrs: { "no-gutters": "", align: "center" }
                      },
                      [
                        !_vm.currentUserAccount.isUser()
                          ? _c(
                              "v-col",
                              { attrs: { cols: "auto" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "white", icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.$router.push(
                                          "/organisations"
                                        )
                                      }
                                    }
                                  },
                                  [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("v-col", { staticClass: "px-1" }, [
                          _c(
                            "div",
                            { staticClass: "white--text text-body-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.organisation?.companyName) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _vm.activeStaffMemberships.length > 1 ||
                        _vm.currentUserAccount.isDementiaAdmin()
                          ? _c(
                              "v-col",
                              { attrs: { cols: "auto" } },
                              [
                                _c("organisation-switcher", {
                                  attrs: { small: "" },
                                  on: { "switch-org": _vm.switchOrganisation }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      height: "calc(100vh - 165px)",
                      "overflow-y": "auto"
                    }
                  },
                  _vm._l(_vm.navigationItems, function(navItem, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        (_vm.currentUserAccount?.isUser()
                        ? _vm.containsUserRole(navItem.userRoles)
                        : true)
                          ? _c("div", { staticClass: "navigation-subtitle" }, [
                              _vm._v(" " + _vm._s(navItem.title) + " ")
                            ])
                          : _vm._e(),
                        _vm._l(navItem.children, function(navChild, index) {
                          return _c(
                            "div",
                            {
                              key: `${navItem.title.replaceAll(
                                " ",
                                "_"
                              )}-${index}`
                            },
                            [
                              (_vm.currentUserAccount?.isUser()
                              ? _vm.containsUserRole(navItem.userRoles)
                              : true)
                                ? _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        link: "",
                                        "active-class":
                                          "navigation-item-active",
                                        to: `/organisation/${_vm.$route.params.organisationId}${navChild.to}`
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(navChild.icon))
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(navChild.title))
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        (_vm.currentUserAccount?.isUser()
                        ? _vm.containsUserRole(navItem.userRoles)
                        : true)
                          ? _c("v-divider", { staticClass: "my-3" })
                          : _vm._e()
                      ],
                      2
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "navigation-bottom-bar" },
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "align-center px-2 text--primary",
                        staticStyle: { height: "64px" },
                        attrs: { "no-gutters": "" }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("organisation-switcher", {
                              on: { "switch-org": _vm.switchOrganisation }
                            })
                          ],
                          1
                        ),
                        _c("v-col", { attrs: { cols: "auto" } }, [
                          _c("div", { staticClass: "font-weight-bold" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  [
                                    _vm.currentUserAccount?.firstName,
                                    _vm.currentUserAccount?.lastName
                                  ].join(" ")
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "text-capitalize text--secondary",
                              staticStyle: { "font-size": "12px" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.currentUserAccount?.isUser()
                                      ? _vm.activeMembership?.memberRole
                                          .replaceAll("_", " ")
                                          .toLowerCase()
                                      : _vm.currentUserAccount?.systemRole
                                          .replaceAll("_", " ")
                                          .toLowerCase()
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c(
                          "v-col",
                          { staticClass: "ml-auto", attrs: { cols: "auto" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: { click: _vm.logout }
                              },
                              [
                                _c("v-icon", { attrs: { size: "24" } }, [
                                  _vm._v("mdi-logout")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "v-main",
            { staticClass: "main-application" },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [_c("router-view")],
                1
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }