import { render, staticRenderFns } from "./DeleteButton.vue?vue&type=template&id=03c0ac01"
import script from "./DeleteButton.vue?vue&type=script&lang=js"
export * from "./DeleteButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03c0ac01')) {
      api.createRecord('03c0ac01', component.options)
    } else {
      api.reload('03c0ac01', component.options)
    }
    module.hot.accept("./DeleteButton.vue?vue&type=template&id=03c0ac01", function () {
      api.rerender('03c0ac01', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Common/DeleteButton.vue"
export default component.exports