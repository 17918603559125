<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-3"
          color="primary"
          v-bind="attrs"
          v-on="on"
          :small="dense"
        >
          <v-icon class="mr-2">mdi-plus</v-icon>New Update</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          New Update
        </v-card-title>

        <v-card-text class="py-2">
          <v-form ref="createCourseContentForm">
            <v-label>Title</v-label>
            <v-text-field
              v-model="title"
              :rules="rules.requiredField"
              label="Title"
              single-line
              outlined
              dense
              required
            />

            <v-label>Summary</v-label>
            <v-text-field
              v-model="summary"
              :rules="rules.requiredField"
              label="Summary"
              single-line
              outlined
              dense
              required
            />

            <v-label>Version</v-label>
            <v-text-field
              v-model="version"
              :rules="rules.requiredField"
              label="Version"
              single-line
              outlined
              dense
              required
            />

            <v-label>Import Content from Previous Release</v-label>
          </v-form>
          <div
            :class="
              existingCourseContentId
                ? 'selected-course-content px-2 py-1'
                : 'mt-1'
            "
          >
            <v-row align="center">
              <v-col v-if="existingCourseContentId">
                <v-row align="center" no-gutters>
                  <v-col cols="auto" class="mr-2">
                    <v-chip
                      color="grey lighten-2"
                      class="text-caption font-weight-bold"
                      small
                      >v{{ courseContentSelected.version }}</v-chip
                    >
                  </v-col>
                  <v-col cols="auto">
                    <v-row align="center" no-gutters
                      ><v-col
                        cols="auto"
                        class="primary--text text-body-2"
                        >{{ courseContentSelected.title }}</v-col
                      ></v-row
                    >
                    <v-row align="center" no-gutters
                      ><v-col cols="auto" class="text-caption"
                        ><span
                          class="text-capitalize"
                          :style="
                            `color: ${getStateColour(
                              courseContentSelected.state
                            )}`
                          "
                        >
                          {{
                            courseContentSelected.state.toLowerCase()
                          }}
                        </span></v-col
                      ></v-row
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="auto">
                <course-content-selector
                  :selected-course-content="existingCourseContentId"
                  @add-selected-course-content="selectCourseContent"
                />
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="grey lighten-2" @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="secondary"
            class="primary--text"
            :disabled="creatingCourseContent"
            :loading="creatingCourseContent"
            @click="createCourseContent"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  CreateCourseContent,
  CourseContents
} from '@/graphql/CourseContent.gql';
import CourseContentSelector from '@/components/CourseContent/CourseContentSelector';
import { getStateColour } from '@/utils/courseContent';

export default {
  name: 'CreateCourseContentModal',
  components: { CourseContentSelector },
  props: {
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false,
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ]
    },
    title: '',
    summary: '',
    version: '',
    existingCourseContentId: null,
    creatingCourseContent: false
  }),
  watch: {
    dialog(dialog) {
      if (!dialog) {
        this.resetForm();
        this.$refs.createCourseContentForm.reset();
      }
    }
  },
  computed: {
    ...mapState('user', ['currentUserAccount']),
    courseContentItems() {
      return [
        { text: 'None', value: null },
        ...(this.courseContents ?? []).map(courseContent => ({
          text: courseContent.title,
          value: courseContent.id
        }))
      ];
    },
    courseContentSelected() {
      return this.courseContents.find(
        courseContent =>
          courseContent.id === this.existingCourseContentId
      );
    }
  },
  apollo: {
    courseContents: {
      query: CourseContents
    }
  },
  methods: {
    resetForm() {
      this.title = '';
      this.summary = '';
      this.version = '';
      this.existingCourseContentId = null;
      this.creatingCourseContent = false;
    },
    selectCourseContent(courseContent) {
      this.existingCourseContentId = courseContent.id;
    },
    async createCourseContent() {
      if (this.$refs.createCourseContentForm.validate()) {
        this.creatingCourseContent = true;
        try {
          await this.$apollo.mutate({
            mutation: CreateCourseContent,
            variables: {
              courseContent: {
                title: this.title,
                summary: this.summary,
                version: this.version,
                state: 'DRAFT',
                createdBy: this.currentUserAccount.id
              },
              existingCourseContentId: this.existingCourseContentId
            }
          });

          this.$emit('refetch');
          this.dialog = false;
        } catch (e) {
          e.graphQLErrors.map(x => this.$toast.error(x.message));
        } finally {
          this.creatingCourseContent = false;
        }
      }
    },
    getStateColour(state) {
      return getStateColour(state);
    }
  }
};
</script>

<style lang="scss" scoped>
.selected-course-content {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}
</style>
