<template>
  <v-dialog :value="open" width="500" @click:outside="$emit('close')">
    <v-card class="rounded-lg">
      <v-card-title class="h6 font-weight-bold primary--text">
        Missing Media
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p>
          Missing media will result in a placeholder image being shown
          in the app. Upload media to resolve this issue, or remove
          the media reference from the configuration. Below is a list
          of file names referenced in the configuration that are
          missing.
        </p>
        <v-list>
          <v-list-item v-for="media in missingMedia" :key="media">
            <v-icon class="orange--text darken-1 mr-2">error</v-icon>
            <span>{{ media }}</span>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="justify-end pa-4">
        <v-btn @click="$emit('close')">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true
    },
    missingMedia: {
      type: Array,
      required: true
    }
  },
  methods: {}
};
</script>
