var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-sub-sec" },
        [
          _c("v-text-field", {
            staticClass: "shrink",
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search",
              outlined: "",
              dense: "",
              "hide-details": ""
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "dark--text rounded-b-lg rounded-t-0",
        attrs: {
          "show-select": _vm.allowSelects,
          items: _vm.filteredModuleContent,
          headers: _vm.headers,
          "no-data-text": `There are no modules${
            _vm.search ? " that match your search" : ""
          }`,
          loading: _vm.loading,
          "loading-text": "Loading...",
          "item-key": "id"
        },
        on: {
          "click:row": item =>
            _vm.$router.push(
              _vm.courseContentId
                ? `/course-content/${_vm.courseContentId}/modules/${item.id}`
                : `/modules/${item.id}`
            )
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.updatedAt`,
              fn: function({ item }) {
                return [
                  _vm._v(" " + _vm._s(_vm.formatDate(item.updatedAt)) + " ")
                ]
              }
            },
            {
              key: `item.editColumn`,
              fn: function({ item }) {
                return [
                  _vm.moduleGroup
                    ? _c("delete-button", {
                        on: {
                          delete: function($event) {
                            return _vm.$emit("click-delete", item.id)
                          }
                        }
                      })
                    : _vm._e(),
                  !_vm.moduleGroup && !_vm.allowSelects && _vm.courseContentId
                    ? _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push(
                                `/course-content/${_vm.courseContentId}/modules/${item.id}`
                              )
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                        1
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push(`/modules/${item.id}`)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-eye")])],
                        1
                      )
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.selectedModules,
          callback: function($$v) {
            _vm.selectedModules = $$v
          },
          expression: "selectedModules"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }