var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loadingUserAccount
    ? _c(
        "div",
        [
          _c(
            "v-container",
            { staticClass: "primary--text mb-5", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mr-auto", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", align: "center" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "ml-n3 mr-1",
                              attrs: { cols: "auto" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "#00000060", icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.go(-1)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c("h1", [
                              _vm._v("Overview of " + _vm._s(_vm.bundle?.name))
                            ])
                          ])
                        ],
                        1
                      ),
                      _c("breadcrumbs", {
                        attrs: { items: ["Bundle", _vm.bundle?.name] }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", [
            _c(
              "div",
              { staticClass: "text--primary mb-3 font-weight-bold text-h6" },
              [_vm._v(" Bundle Overview ")]
            ),
            _c(
              "div",
              { staticClass: "rounded-lg white px-5 py-1 text--primary" },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "info-row py-2",
                    attrs: { "no-gutters": "", align: "center" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mr-auto title-column",
                        attrs: { cols: "auto" }
                      },
                      [_vm._v(" Bundle Name ")]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "ml-auto value-column",
                        attrs: { cols: "auto" }
                      },
                      [
                        _c("edit-field", {
                          attrs: { "current-value": _vm.bundle?.name },
                          on: { "update-value": _vm.updateBundleName }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "info-row py-2",
                    attrs: { "no-gutters": "", align: "center" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mr-auto title-column",
                        attrs: { cols: "auto" }
                      },
                      [_vm._v(" Data Sharing Code ")]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "ml-auto value-column",
                        attrs: { cols: "auto" }
                      },
                      [
                        _c("edit-field", {
                          attrs: { "current-value": _vm.bundle?.bundleCode },
                          on: { "update-value": _vm.updateBundleCode }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "mt-8" },
            [
              _c(
                "v-row",
                { attrs: { justify: "space-between", align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "text--primary mb-3 font-weight-bold text-h6",
                      attrs: { cols: "auto" }
                    },
                    [_vm._v(" Course Management ")]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("active-course-selector", {
                        attrs: {
                          "selected-courses": _vm.bundle?.courses.map(
                            c => c.referenceId
                          ),
                          "course-grouping-title": _vm.bundle?.name
                        },
                        on: { "add-selected-courses": _vm.addSelectedCourses }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "dark--text rounded-b-lg rounded-t-0",
                attrs: {
                  items: _vm.bundle?.courses,
                  headers: _vm.headers,
                  "no-data-text": "There are no courses for this bundle",
                  loading: _vm.$apollo.queries.bundle?.loading,
                  "loading-text": "Loading...",
                  "item-key": "referenceId"
                },
                on: {
                  "click:row": item => _vm.$router.push(`/courses/${item.id}`)
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.editColumn`,
                      fn: function({ item }) {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push(`/courses/${item.id}`)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-eye")])],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "error", icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.removeCourse(item.id)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-delete-outline")])],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      )
    : _c("div")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }