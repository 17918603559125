<template>
  <div>
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>Staff</h1>
            </v-col>
          </v-row>
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <div class="page-subtitle">
                A place where you can view all staff associated with
                your organisation.
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="ml-auto d-flex">
          <invite-user-account-modal
            :organisation-id="organisationId"
            @refetch="$apollo.queries.organisationStaff.refetch()"
            staff
          />
        </v-col>
      </v-row>
    </v-container>

    <organisation-staff-table
      :staff="organisationStaff"
      :loading="$apollo.queries.organisationStaff.loading"
    />
  </div>
</template>

<script>
import { OrganisationStaff } from '@/graphql/Organisation.gql';
import OrganisationStaffTable from '@/components/Organisation/Staff/OrganisationStaffTable';
import InviteUserAccountModal from '@/components/Common/InviteUserAccountModal';

export default {
  name: 'OrganisationStaff',
  components: {
    OrganisationStaffTable,
    InviteUserAccountModal
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Name', value: 'name' },
      {
        text: 'Bundle Code',
        value: 'bundleCode'
      },
      {
        text: 'Active',
        value: 'active'
      },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ],
    createModalOpen: false
  }),
  computed: {
    organisationId() {
      return this.$route.params.organisationId;
    }
  },
  apollo: {
    organisationStaff: {
      query: OrganisationStaff,
      variables() {
        return {
          organisationId: this.organisationId
        };
      }
    }
  },
  methods: {
    onCreated() {
      this.$apollo.queries.organisationStaff.refetch();
      this.createModalOpen = false;
    }
  }
};
</script>
