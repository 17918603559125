<template>
  <div class="tabbed-page">
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>Courses</h1>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <courses-table
      :courses="courses"
      :loading="$apollo.queries.courses.loading"
    />
  </div>
</template>

<script>
import CoursesTable from '@/components/Course/CoursesTable';
import { Courses } from '@/graphql/Course.gql';

export default {
  name: 'CourseContentCoursesPage',
  components: {
    CoursesTable
  },
  apollo: {
    courses: {
      query: Courses
    }
  }
};
</script>
