import enumToDisplay from '@/utils/enumToDisplay';

const BranchingOutcomeType = Object.freeze({
  RESTART_QUESTION: 'RESTART_QUESTION',
  EXIT_QUESTION: 'EXIT_QUESTION'
});

export const branchingOutcomeTypeItems = enumToDisplay(
  BranchingOutcomeType
);

export default BranchingOutcomeType;
