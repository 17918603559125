var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            color: "primary",
                            disabled: _vm.disabled,
                            small: ""
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-plus")]), _vm._v("Content")],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "rounded-lg" },
            [
              _c(
                "v-card-title",
                { staticClass: "h6 font-weight-bold primary--text" },
                [_vm._v(" New Content ")]
              ),
              _c(
                "v-card-text",
                { staticClass: "py-2" },
                [
                  _c(
                    "v-form",
                    { ref: "createContentForm" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-label", [_vm._v("Title")]),
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.rules.requiredField,
                                  label: "Title",
                                  "single-line": "",
                                  outlined: "",
                                  dense: "",
                                  required: ""
                                },
                                model: {
                                  value: _vm.title,
                                  callback: function($$v) {
                                    _vm.title = $$v
                                  },
                                  expression: "title"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-label", [_vm._v("Content Type")]),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.contentTypeItems,
                                  rules: _vm.rules.requiredField,
                                  label: "Content Type",
                                  "single-line": "",
                                  outlined: "",
                                  dense: "",
                                  required: ""
                                },
                                model: {
                                  value: _vm.contentType,
                                  callback: function($$v) {
                                    _vm.contentType = $$v
                                  },
                                  expression: "contentType"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.contentType == _vm.ContentType.TEXT
                        ? _c("tiptap-vuetify", {
                            attrs: { extensions: _vm.extensions },
                            model: {
                              value: _vm.textContent,
                              callback: function($$v) {
                                _vm.textContent = $$v
                              },
                              expression: "textContent"
                            }
                          })
                        : _vm._e(),
                      _vm.contentType == _vm.ContentType.IMAGE ||
                      _vm.contentType == _vm.ContentType.VIDEO
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-label",
                                { staticClass: "text-capitalize" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.contentType.toString().toLowerCase()
                                    )
                                  )
                                ]
                              ),
                              _c("v-file-input", {
                                ref: "fileInput",
                                attrs: {
                                  "single-line": "",
                                  outlined: "",
                                  dense: "",
                                  required: ""
                                },
                                on: { change: _vm.validateFile },
                                model: {
                                  value: _vm.input.file,
                                  callback: function($$v) {
                                    _vm.$set(_vm.input, "file", $$v)
                                  },
                                  expression: "input.file"
                                }
                              }),
                              _vm.input.file
                                ? _c(
                                    "v-label",
                                    { staticClass: "mt-3 text-capitalize" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.contentType
                                            .toString()
                                            .toLowerCase()
                                        ) + " Name"
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.input.file
                                ? _c("v-text-field", {
                                    attrs: {
                                      label: "Name",
                                      "single-line": "",
                                      outlined: "",
                                      dense: "",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.input.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.input, "name", $$v)
                                      },
                                      expression: "input.name"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.isLoading
                ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
                : _vm._e(),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: "",
                        disabled: _vm.isLoading
                      },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary", disabled: _vm.isLoading },
                      on: { click: _vm.createContent }
                    },
                    [_vm._v(" Create ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }