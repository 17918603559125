<template>
  <div class="tabbed-page">
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>Lessons</h1>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <lessons-table
      :lessons="lessons"
      :loading="$apollo.queries.lessons.loading"
    />
  </div>
</template>

<script>
import LessonsTable from '@/components/Lesson/LessonsTable';
import { Lessons } from '@/graphql/Lesson.gql';

export default {
  name: 'LessonsPage',
  components: {
    LessonsTable
  },
  apollo: {
    lessons: {
      query: Lessons
    }
  }
};
</script>
