<template>
  <div>
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>App Instructions</h1>
            </v-col>
          </v-row>
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <div class="page-subtitle">
                Manage in-app instructions and help content for users.
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" @click="createModalOpen = true">
            <v-icon class="mr-2">mdi-plus</v-icon>
            Create Instruction
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :items="instructions"
        :headers="headers"
        :loading="$apollo.loading"
        class="mt-4"
      >
        <template v-slot:[`item.state`]="{ item }">
          <v-chip
            class="pr-1 white--text font-weight-bold caption"
            :color="instructionStates[item.state].color">
            {{ instructionStates[item.state].displayName }}
            <v-icon
              @click="openStatusMenu = openStatusMenu == item.id ? null : item.id"
              class="ml-1">
              expand_circle_down
            </v-icon>
          </v-chip>
          <v-col v-if="openStatusMenu == item.id" class="pa-6 mt-1 rounded-xxl state-dropdown">
            <v-row
              class="justify-end"
              v-for="(state, index) in Object.values(instructionStates).filter(s => s.enumValue !== item.state)"
              :key="index"
            >
              <v-chip
                :class="index != 0 ? 'mt-2' : ''"
                class="white--text font-weight-bold caption"
                :color="state.color"
                @click="updateState(item, state)"
              >
                {{ state.displayName }}
              </v-chip>
            </v-row>
          </v-col>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ formatDateTime(item.createdAt) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            small
            @click="editInstruction(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-container>

    <create-instruction-modal
      :open="createModalOpen"
      :instruction-to-edit="instructionToEdit"
      @close="closeModal"
      @success="handleCreateSuccess"
    />
  </div>
</template>

<script>
import { formatDateTime } from '@/utils/date';
import CreateInstructionModal from '@/components/AppInstructions/CreateInstructionModal.vue';
import { FetchAppInstructions, SetInstructionState, UpdateAppInstruction } from '@/graphql/AppInstructions.gql';

export default {
  name: 'AppInstructionsPage',
  components: {
    CreateInstructionModal
  },
  data: () => ({
    createModalOpen: false,
    instructionToEdit: null,
    openStatusMenu: null,
    headers: [
      { text: 'Label', value: 'label' },
      { text: 'State', value: 'state' },
      { text: 'Created', value: 'createdAt' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    instructions: []
  }),
  computed: {
    instructionStates() {
      return {
        'ACTIVE': {displayName: 'Active', icon: 'check_circle_outline', color: 'success', enumValue: 'ACTIVE'},
        'INACTIVE': {displayName: 'Inactive', icon: 'visibility_off', color: 'grey lighten-1', enumValue: 'INACTIVE'},
        'TESTING': {displayName: 'Testing', icon: 'handyman', color: 'purple', enumValue: 'TESTING'},
      }
    }
  },
  apollo: {
    instructions: {
      query: FetchAppInstructions,
      update: data => data.appInstructions,
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    formatDateTime,
    getStateColor(state) {
      const colors = {
        ACTIVE: 'success',
        INACTIVE: 'grey',
        TESTING: 'purple'
      };
      return colors[state] || 'grey';
    },
    editInstruction(instruction) {
      this.instructionToEdit = instruction;
      this.createModalOpen = true;
    },
    closeModal() {
      this.createModalOpen = false;
      this.instructionToEdit = null;
      this.$emit('close');
    },
    handleCreateSuccess() {
      this.closeModal();
      this.$apollo.queries.instructions.refetch();
    },
    async updateState(instruction, state) {
      await this.$apollo.mutate({
        mutation: SetInstructionState,
        variables: {
          id: instruction.id,
          state: state.enumValue
        }
      });
      this.openStatusMenu = null;
      this.$apollo.queries.instructions.refetch();
    }
  }
};
</script>

<style scoped>
.state-dropdown {
  position: absolute;
  z-index: 1;
  background-color: white;
  width: auto;
  border: 1px solid lightgray;
}
</style>
