<template>
  <div class="tabbed-page">
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <h1>{{ courseGroup?.title }}</h1>
          <breadcrumbs
            :items="['Course Groups', courseGroup?.title]"
          />
        </v-col>
      </v-row>
    </v-container>

    <div>
      <div class="text--primary mb-3 font-weight-bold text-h6">
        Course Grouping Configuration
      </div>
      <div class="rounded-lg white px-5 py-1 text--primary">
        <v-row no-gutters align="center" class="info-row py-4">
          <v-col cols="auto" class="mr-auto title-column">
            Course Grouping Title
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <edit-field
              :current-value="courseGroup?.title"
              @update-value="updateTitle"
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-4">
          <v-col cols="auto" class="mr-auto title-column">
            Course Grouping Description
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <edit-field
              :current-value="courseGroup?.description"
              @update-value="updateDescription"
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-4">
          <v-col cols="auto" class="mr-auto title-column">
            Last Updated At
          </v-col>
          <v-col cols="auto" class="ml-auto">
            {{ formatDate(courseGroup?.updatedAt) }}
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="mt-8">
      <v-row justify="space-between" align="center">
        <v-col
          cols="auto"
          class="text--primary mb-3 font-weight-bold text-h6"
        >
          Courses Included
        </v-col>

        <v-col cols="auto">
          <create-course-modal
            @refetch="$apollo.queries.coursesForCourseGroup.refetch()"
          />
        </v-col>
      </v-row>

      <courses-table
        :courses="coursesForCourseGroup"
        :loading="$apollo.queries.coursesForCourseGroup.loading"
        :course-group="true"
        @click-delete="deleteCourseFromGroup"
      />
    </div>
  </div>
</template>

<script>
import CoursesTable from '@/components/Course/CoursesTable';
import CourseSelector from '@/components/Course/CourseSelector';
import CourseGroupTable from '@/components/Course/CourseGroup/CourseGroupTable';
import CreateCourseModal from '@/components/Course/CreateCourseModal';
import CreateCourseGroupModal from '@/components/Course/CourseGroup/CreateCourseGroupModal';
import {
  CourseGroup,
  AddCoursesToCourseGroup,
  DeleteCourseFromCourseGroup,
  DeleteCourseGroup,
  UpdateCourseGroup
} from '@/graphql/CourseGroup.gql';
import { CoursesForCourseGroup } from '@/graphql/Course.gql';
import { formatDate } from '@/utils/date';
import Breadcrumbs from '@/components/Common/Breadcrumbs';
import EditField from '@/components/Common/EditField';

export default {
  name: 'CourseGroupPage',
  components: {
    CoursesTable,
    CourseGroupTable,
    CreateCourseModal,
    CreateCourseGroupModal,
    CourseSelector,
    Breadcrumbs,
    EditField
  },
  data: () => ({
    tab: null,
    items: ['Courses', 'Course Groupings']
  }),
  computed: {
    courseContentId() {
      return this.$route.params.courseContentId;
    },
    courseGroupId() {
      return this.$route.params.courseGroupId;
    },
    selectedCourses() {
      return (
        this.coursesForCourseGroup?.map(course => course.id) ?? []
      );
    },
    defaultCourseGroup() {
      return {
        id: this.courseGroup?.id,
        title: this.courseGroup?.title,
        description: this.courseGroup?.description,
        displayIndex: this.courseGroup?.displayIndex
      };
    }
  },
  apollo: {
    coursesForCourseGroup: {
      query: CoursesForCourseGroup,
      variables() {
        return {
          courseGroupId: this.courseGroupId
        };
      },
      skip() {
        return !this.courseGroupId;
      }
    },
    courseGroup: {
      query: CourseGroup,
      variables() {
        return {
          id: this.courseGroupId
        };
      },
      skip() {
        return !this.courseGroupId;
      }
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    async addSelectedCourses(courseIds) {
      try {
        await this.$apollo.mutate({
          mutation: AddCoursesToCourseGroup,
          variables: {
            ids: courseIds,
            courseGroupId: this.courseGroupId
          }
        });

        this.$apollo.queries.coursesForCourseGroup.refetch();
      } catch (e) {
        e.graphQLErrors.map(x => this.$toast.error(x.message));
      }
    },
    async deleteCourseFromGroup(id) {
      try {
        await this.$apollo.mutate({
          mutation: DeleteCourseFromCourseGroup,
          variables: {
            id: id,
            courseGroupId: this.courseGroupId
          }
        });

        this.$apollo.queries.coursesForCourseGroup.refetch();
      } catch (e) {
        e.graphQLErrors.map(x => this.$toast.error(x.message));
      }
    },
    async deleteCourseGroup() {
      try {
        await this.$apollo.mutate({
          mutation: DeleteCourseGroup,
          variables: {
            id: this.courseGroupId,
            courseContentId: this.courseContentId
          }
        });

        this.$router.push(
          `/course-content/${this.courseContentId}/course-groups`
        );
      } catch (e) {
        console.log(e);
        e.graphQLErrors.map(x => this.$toast.error(x.message));
      }
    },
    async updateCourseGroup(courseGroup) {
      try {
        await this.$apollo.mutate({
          mutation: UpdateCourseGroup,
          variables: {
            courseGroup: courseGroup
          }
        });

        await this.$apollo.queries.courseGroup.refetch();
      } catch (e) {
        e.graphQLErrors.map(x => this.$toast.error(x.message));
      }
    },
    async updateTitle(title) {
      await this.updateCourseGroup({
        ...this.defaultCourseGroup,
        title: title
      });
    },
    async updateDescription(description) {
      await this.updateCourseGroup({
        ...this.defaultCourseGroup,
        description: description
      });
    }
  }
};
</script>
