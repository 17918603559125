<template>
  <span>
    <v-btn icon @click="confirmDelete" color="red" :disabled="disabled">
      <v-icon>mdi-delete-outline</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="290">
      <v-card class="rounded-lg pa-2">
        <v-card-title class="h6 font-weight-bold primary--text">
          Are you sure?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="red darken-1 white--text" @click="deleteItem">
            <v-icon class="mr-1">mdi-trash-can-outline</v-icon>Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
export default {
  name: 'DeleteButton',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    confirmDelete() {
      this.dialog = true;
    },
    deleteItem() {
      this.$emit('delete');
      this.dialog = false;
    }
  }
};
</script>
