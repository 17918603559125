<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-3"
          color="primary"
          v-bind="attrs"
          v-on="on"
          :small="dense || !!routeModuleId"
        >
          <v-icon :class="routeModuleId ? '' : 'mr-2'"
            >mdi-plus</v-icon
          >{{ routeModuleId ? 'Add' : 'New' }} Lesson</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          New Lesson
        </v-card-title>

        <v-card-text class="py-2">
          <v-form ref="createLessonForm">
            <v-label>Title</v-label>
            <v-text-field
              v-model="title"
              :rules="rules.requiredField"
              label="Title"
              single-line
              outlined
              dense
              required
            />

            <v-label>Learning Objective</v-label>
            <v-text-field
              v-model="learningObjective"
              :rules="rules.requiredField"
              label="Learning Objective"
              single-line
              outlined
              dense
              required
            />

            <v-label>Search Keywords</v-label>
            <v-text-field
              v-model="searchKeywords"
              :rules="rules.requiredField"
              label="Search Keywords"
              single-line
              outlined
              dense
              required
            />

            <v-label>Learning Minutes</v-label>
            <v-text-field
              v-model="learningMinutes"
              :rules="rules.requiredField"
              label="Learning Minutes"
              type="number"
              single-line
              outlined
              dense
              required
            />

            <v-label>Module</v-label>
            <v-select
              v-model="moduleId"
              :rules="rules.requiredField"
              :items="moduleItems"
              :disabled="!!routeModuleId"
              label="Module"
              single-line
              outlined
              dense
              required
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="secondary"
            :disabled="creatingLesson"
            :loading="creatingLesson"
            @click="createLesson"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { CreateLesson } from '@/graphql/Lesson.gql';
import ColourPicker from '@/components/Common/ColourPicker';
import { CourseContent } from '@/graphql/CourseContent.gql';

export default {
  name: 'CreateLessonModal',
  components: { ColourPicker },
  props: {
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false,
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ]
    },
    title: '',
    learningObjective: '',
    searchKeywords: '',
    learningObjectives: [],
    visible: true,
    disabled: false,
    moduleId: null,
    learningMinutes: null,
    creatingLesson: false
  }),
  watch: {
    dialog(dialog) {
      this.resetForm();

      if (!dialog) {
        this.$refs.createLessonForm.reset();
      } else {
        if (this.routeModuleId) {
          this.moduleId = this.routeModuleId;
        }
      }
    }
  },
  computed: {
    ...mapState('user', ['currentUserAccount']),
    courseContentId() {
      return this.$route.params.courseContentId;
    },
    routeModuleId() {
      return this.$route.params.moduleId;
    },
    modulesToDisplay() {
      if (this.$apollo.queries.courseContent.loading) {
        return [];
      }

      let modules = [];

      for (const courseGroup of this.courseContent.courseGroups) {
        for (const course of courseGroup.courses) {
          for (const module of course.modules) {
            modules.push(module);
          }
        }
      }

      return modules;
    },
    moduleItems() {
      return (this.modulesToDisplay ?? []).map(module => ({
        text: module.title,
        value: module.id
      }));
    }
  },
  apollo: {
    courseContent: {
      query: CourseContent,
      variables() {
        return {
          id: this.$route.params.courseContentId
        };
      }
    }
  },
  methods: {
    resetForm() {
      this.title = '';
      this.learningObjective = '';
      this.searchKeywords = '';
      this.learningObjectives = [];
      this.visible = true;
      this.disabled = false;
      this.moduleId = null;
      this.learningMinutes = null;
      this.creatingLesson = false;
    },
    async createLesson() {
      if (this.$refs.createLessonForm.validate()) {
        try {
          this.creatingLesson = true;
          await this.$apollo.mutate({
            mutation: CreateLesson,
            variables: {
              lesson: {
                title: this.title,
                learningObjective: this.learningObjective,
                searchKeywords: this.searchKeywords,
                learningMinutes: this.learningMinutes
              },
              moduleId: this.moduleId
            }
          });

          this.$emit('refetch');
          this.dialog = false;
        } catch (e) {
          e.graphQLErrors.map(x => this.$toast.error(x.message));
        } finally {
          this.creatingLesson = false;
        }
      }
    }
  }
};
</script>
