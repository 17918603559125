import enumToDisplay from '@/utils/enumToDisplay';

const QuestionType = Object.freeze({
  STATIC: 'STATIC',
  BRANCHING: 'BRANCHING'
});

export const questionTypeItems = enumToDisplay(QuestionType);

export default QuestionType;
