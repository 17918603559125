var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "primary--text mb-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mr-auto", attrs: { cols: "auto" } },
                        [_c("h1", [_vm._v("App Instructions")])]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mr-auto", attrs: { cols: "auto" } },
                        [
                          _c("div", { staticClass: "page-subtitle" }, [
                            _vm._v(
                              " Manage in-app instructions and help content for users. "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.createModalOpen = true
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-plus")
                      ]),
                      _vm._v(" Create Instruction ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "mt-4",
            attrs: {
              items: _vm.instructions,
              headers: _vm.headers,
              loading: _vm.$apollo.loading
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.state`,
                  fn: function({ item }) {
                    return [
                      _c(
                        "v-chip",
                        {
                          staticClass:
                            "pr-1 white--text font-weight-bold caption",
                          attrs: {
                            color: _vm.instructionStates[item.state].color
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.instructionStates[item.state].displayName
                              ) +
                              " "
                          ),
                          _c(
                            "v-icon",
                            {
                              staticClass: "ml-1",
                              on: {
                                click: function($event) {
                                  _vm.openStatusMenu =
                                    _vm.openStatusMenu == item.id
                                      ? null
                                      : item.id
                                }
                              }
                            },
                            [_vm._v(" expand_circle_down ")]
                          )
                        ],
                        1
                      ),
                      _vm.openStatusMenu == item.id
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "pa-6 mt-1 rounded-xxl state-dropdown"
                            },
                            _vm._l(
                              Object.values(_vm.instructionStates).filter(
                                s => s.enumValue !== item.state
                              ),
                              function(state, index) {
                                return _c(
                                  "v-row",
                                  { key: index, staticClass: "justify-end" },
                                  [
                                    _c(
                                      "v-chip",
                                      {
                                        staticClass:
                                          "white--text font-weight-bold caption",
                                        class: index != 0 ? "mt-2" : "",
                                        attrs: { color: state.color },
                                        on: {
                                          click: function($event) {
                                            return _vm.updateState(item, state)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(state.displayName) + " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: `item.createdAt`,
                  fn: function({ item }) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.formatDateTime(item.createdAt)) + " "
                      )
                    ]
                  }
                },
                {
                  key: `item.actions`,
                  fn: function({ item }) {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", small: "" },
                          on: {
                            click: function($event) {
                              return _vm.editInstruction(item)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-pencil")])],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c("create-instruction-modal", {
        attrs: {
          open: _vm.createModalOpen,
          "instruction-to-edit": _vm.instructionToEdit
        },
        on: { close: _vm.closeModal, success: _vm.handleCreateSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }