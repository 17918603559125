<template>
  <v-dialog :value="open" width="500" @click:outside="cancel()">
    <v-card class="rounded-lg">
      <v-card-title class="h6 font-weight-bold primary--text">
        Create Ask Annie Anything Configuration
      </v-card-title>
      <v-stepper v-model="step" class="mt-4">
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-progress-linear v-if="loading" indeterminate />
            <div v-else>
              <v-card-text>
                <v-file-input
                  v-model="input.file"
                  label="Upload XLSX File"
                  accept=".xlsx"
                  @change="fileChanged"
                />
                <div>
                  <div v-if="validationResult.errors.length > 0">
                    <v-card-title class="pb-1">
                      Errors ({{ validationResult.errors.length }})
                    </v-card-title>
                    <v-card-text class="pb-1">
                      Please fix the following and try again.
                    </v-card-text>
                    <v-list>
                      <v-list-item
                        v-for="error in validationResult.errors"
                        :key="error"
                        class="body-2"
                      >
                        <v-icon class="red--text darken-2 mr-2">
                          error
                        </v-icon>
                        <span>{{ error }}</span>
                      </v-list-item>
                    </v-list>
                  </div>
                  <div v-if="validationResult.warnings.length > 0">
                    <v-card-title class="pb-1">
                      Warnings ({{
                        validationResult.warnings.length
                      }})
                    </v-card-title>
                    <v-list>
                      <v-list-item
                        v-for="warning in validationResult.warnings"
                        :key="warning"
                        class="body-2"
                      >
                        <v-icon class="orange--text darken-1 mr-2">
                          error
                        </v-icon>
                        <span>{{ warning }}</span>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </v-card-text>
            </div>
            <v-card-actions class="justify-end">
              <span
                v-if="error"
                class="d-flex align-center pa-2 red--text"
              >
                <v-icon class="red--text">error</v-icon>
                &nbsp;{{ error }}
              </span>
              <v-btn @click="cancel()">
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                v-if="fileValidated"
                @click="step = 2"
              >
                Continue
              </v-btn>
              <v-btn
                v-else
                color="primary"
                @click="doFunc(upload)"
                :disabled="loading || !input.file"
              >
                {{
                  validationResult.errors.length > 0
                    ? 'Try Again'
                    : 'Upload'
                }}
              </v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-progress-linear v-if="loading" indeterminate />
            <div v-else>
              <v-text-field v-model="input.name" label="Name" />
              <v-text-field v-model="input.version" label="Version" />
            </div>
            <v-card-actions class="justify-end">
              <v-btn color="primary" @click="cancel()">
                Cancel
              </v-btn>
              <v-btn color="primary" @click="doFunc(save)">
                Save & Finish
              </v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  CreateAaaConfig,
  ValidateAaaConfigBucketObject
} from '@/graphql/AaaConfig.gql';
import {
  CreateBucketObject,
  RequestUploadUrl
} from '@/graphql/BucketObject.gql';
import axios from 'axios';

export default {
  data() {
    return {
      step: 1,
      input: {},
      validationResult: {
        errors: [],
        warnings: []
      },
      fileValidated: false,
      error: null,
      loading: false
    };
  },
  props: {
    open: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    async doFunc(func) {
      // Only say we're loading if it takes more than 250ms to do it
      // otherwise it's flickery and looks worse than not having a loader
      const loadingTimeout = setTimeout(() => {
        this.loading = true;
      }, 250);
      try {
        this.error = null;
        await func();
      } finally {
        clearTimeout(loadingTimeout);
        this.loading = false;
      }
    },
    async fileChanged() {
      this.input.name = this.input.file.name;
      this.fileValidated = false;
      this.$forceUpdate();
    },
    async createBucketObject() {
      try {
        const createResponse = await this.$apollo.mutate({
          mutation: CreateBucketObject,
          variables: {
            fileType: this.input.file.type
          }
        });
        return createResponse.data.createBucketObject;
      } catch (e) {
        throw new Error('Failed to create bucket object.');
      }
    },
    async getUploadUrl(bucketObjectId) {
      try {
        const uploadUrlResponse = await this.$apollo.mutate({
          mutation: RequestUploadUrl,
          variables: {
            bucketObjectId
          }
        });
        return uploadUrlResponse.data.requestUploadUrl;
      } catch (e) {
        throw new Error('Failed to get upload URL.');
      }
    },
    async createAaaConfig() {
      try {
        const createResponse = await this.$apollo.mutate({
          mutation: CreateAaaConfig,
          variables: {
            config: {
              name: this.input.name,
              version: this.input.version,
              bucketObjectId: this.bucketObject.id
            }
          }
        });
        return createResponse.data.createAaaConfig.id;
      } catch (e) {
        console.log(e.message);
        throw new Error('Failed to create configuration.');
      }
    },
    async doUpload(uploadUrl) {
      const uploadResponse = await axios.put(
        uploadUrl,
        this.input.file,
        {
          headers: {
            'Content-Type': this.input.file.type
          }
        }
      );
      if (
        uploadResponse.status >= 200 &&
        uploadResponse.status < 300
      ) {
        return true;
      } else {
        throw new Error('Failed to upload file.');
      }
    },
    async upload() {
      try {
        this.bucketObject = await this.createBucketObject();
        const uploadUrl = await this.getUploadUrl(
          this.bucketObject.id
        );
        await this.doUpload(uploadUrl);
        this.validationResult = await this.validateConfig(
          this.bucketObject.id
        );
      } catch (e) {
        this.error = e.message;
        return;
      }
    },
    async save() {
      try {
        await this.createAaaConfig();
        this.$emit('success');
        this.clear();
      } catch (e) {
        this.error = e.message;
        return;
      }
    },
    async validateConfig(bucketObjectId) {
      try {
        const validationResponse = await this.$apollo.mutate({
          mutation: ValidateAaaConfigBucketObject,
          variables: {
            id: bucketObjectId
          }
        });
        const validationResult =
          validationResponse.data.validateAaaConfigBucketObject;
        if (validationResult.errors.length === 0) {
          this.fileValidated = true;
        } else {
          this.fileValidated = false;
          this.input.file = null;
        }
        return validationResult;
      } catch (e) {
        throw new Error(
          'Error while attempting to validate configuration.'
        );
      }
    },
    clear() {
      this.input = {};
      this.validationResult = {
        errors: [],
        warnings: []
      };
      this.fileValidated = false;
      this.error = null;
      this.bucketObject = null;
    },
    cancel() {
      this.clear();
      this.$emit('close');
    }
  }
};
</script>
