var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "login-page" },
    [
      _c(
        "v-container",
        {
          staticClass: "login-container",
          attrs: { "fill-height": "", "justify-center": "" }
        },
        [
          _c(
            "v-row",
            { staticClass: "card-container" },
            [
              _c(
                "v-col",
                {
                  staticClass: "hero-image d-none d-md-flex",
                  attrs: { cols: "12", md: "6" }
                },
                [
                  _c("img", {
                    attrs: { src: require("../../images/LoginHero.svg") }
                  })
                ]
              ),
              _c(
                "v-col",
                { staticClass: "content", attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "header pa-4",
                      attrs: { justify: "center" }
                    },
                    [
                      _c("img", {
                        attrs: { src: require("../../images/LoginHeader.svg") }
                      })
                    ]
                  ),
                  _vm._t("default", function() {
                    return [_c("v-row", [_vm._v(" Content ")])]
                  })
                ],
                2
              )
            ],
            1
          ),
          _c("footer", { staticClass: "footer d-none d-md-block" }, [
            _vm._v(
              "© Copyright " +
                _vm._s(_vm.currentYear) +
                " Dementia & Alzheimer's Australia Ltd."
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }