<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-3"
          color="primary"
          v-bind="attrs"
          v-on="on"
          :small="dense"
        >
          <v-icon class="mr-2">mdi-eye-outline</v-icon>Change
          Visibility</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          Change Visibility
        </v-card-title>

        <v-card-text class="py-2">
          <div>
            Change who can see the changes made in this version
            (currently set to
            <span
              class="text-capitalize"
              :style="`color: ${getStateColour(currentState)}`"
            >
              {{ currentState.toLowerCase() }}</span
            >):
          </div>

          <div class="mt-3">
            <v-select
              v-model="newState"
              :items="[
                { text: 'Draft', value: 'DRAFT' },
                { text: 'Testing', value: 'TESTING' },
                { text: 'Published', value: 'PUBLISHED' },
                { text: 'Unavailable', value: 'UNAVAILABLE' }
              ]"
              outlined
            />
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="secondary" @click="saveVisibility">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { systemRolesItems } from '@/enums/SystemRoles';
import { Sites } from '@/graphql/Site.gql';
import { Bundles } from '@/graphql/Bundle.gql';
import { UpdateCourseContentState } from '@/graphql/CourseContent.gql';
import { siteRoles, organisationRoles } from '@/enums/UserRoles';
import enumToDisplay from '@/utils/enumToDisplay';
import { getStateColour } from '@/utils/courseContent';

export default {
  name: 'ChangeVisibilityModal',
  props: {
    currentState: {
      type: String,
      default: 'DRAFT'
    }
  },
  data: () => ({
    dialog: false,
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ]
    },
    newState: 'DRAFT'
  }),
  watch: {
    dialog(dialog) {
      if (dialog) {
        this.newState = this.currentState;
      }
    }
  },
  methods: {
    async saveVisibility() {
      try {
        await this.$apollo.mutate({
          mutation: UpdateCourseContentState,
          variables: {
            id: this.$route.params.courseContentId,
            state: this.newState
          }
        });

        this.$emit('refetch');
        this.dialog = false;
      } catch (e) {
        e.graphQLErrors.map(x => this.$toast.error(x.message));
      }
    },
    getStateColour(state) {
      return getStateColour(state);
    }
  }
};
</script>
