import Organisation from '../../models/Organisation';

const actions = {
  updateCurrentOrganisation({ commit }, data) {
    commit("setCurrentOrganisation", data.organisationAdmin?.organisation);
  }
};

const mutations = {
  setCurrentOrganisation(state, organisation) {
    state.currentOrganisation = new Organisation(organisation);
  }
};

const state = {
  currentOrganisation: undefined
};

export default {
  namespaced: true,
  actions,
  mutations,
  state
};
