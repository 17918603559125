<template>
  <div>
    <v-dialog v-model="dialog" width="1200">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on" small>
          <v-icon>{{
            selectedCourseContent ? 'mdi-pencil' : 'mdi-plus'
          }}</v-icon
          >{{ selectedCourseContent ? 'Change' : 'Select' }}</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          Select Course Content
        </v-card-title>

        <v-card-text class="py-2">
          <publish-table
            ref="courseContentTable"
            :course-content="courseContents ?? []"
            :loading="$apollo.queries.courseContents.loading"
            allow-select
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="secondary" @click="addSelectedCourse">
            Select Course Content
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { CourseContents } from '@/graphql/CourseContent.gql';
import CoursesTable from '@/components/Course/CoursesTable';
import PublishTable from '@/components/Course/PublishTable';

export default {
  name: 'CourseContentSelector',
  components: { CoursesTable, PublishTable },
  props: {
    selectedCourseContent: {
      default: null
    }
  },
  data: () => ({
    dialog: false
  }),
  watch: {
    selectedCourseContent() {
      this.$refs.courseContentTable.selectedCourseContent =
        [this.selectedCourseContent] ?? [];
    }
  },
  apollo: {
    courseContents: {
      query: CourseContents
    }
  },
  methods: {
    addSelectedCourse() {
      const selectedCourses = this.$refs.courseContentTable
        .selectedCourseContent;

      if (selectedCourses.length > 0) {
        this.$emit('add-selected-course-content', selectedCourses[0]);
      }

      this.dialog = false;
    }
  }
};
</script>
