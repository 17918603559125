var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        { staticClass: "my-auto" },
        [
          _c(
            "v-row",
            {
              staticClass: "text-h5 font-weight-bold primary--text mb-2",
              attrs: { justify: "center" }
            },
            [_vm._v(" Insufficient Permissions ")]
          ),
          _c(
            "v-row",
            {
              staticClass: "font-weight-light mb-10",
              attrs: { justify: "center" }
            },
            [
              _vm._v(
                " If you are a learner, please continue using Ask Annie via the mobile app. "
              )
            ]
          ),
          _c("v-row", { staticClass: "mb-10", attrs: { justify: "center" } }, [
            _c("img", {
              attrs: {
                src: require("../images/insufficient_permissions_hero.svg")
              }
            })
          ]),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.logout } },
                [_vm._v("Logout")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }