class Organisation {
  constructor(data = {}) {
    this.id = data.id;
    this.companyName = data.companyName;
    this.address = data.address;
    this.country = data.country;
    this.website = data.website;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}

export default Organisation;
