<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on" small>
          <v-icon>mdi-plus</v-icon>Question</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          New Question
        </v-card-title>

        <v-card-text class="py-2">
          <v-form ref="createQuestionForm">
            <v-label>Title</v-label>
            <v-text-field
              v-model="title"
              :rules="rules.requiredField"
              label="Title"
              single-line
              outlined
              dense
              required
            />

            <v-label>Question Type</v-label>
            <v-select
              v-model="questionType"
              :rules="rules.requiredField"
              :items="questionTypeItems"
              label="Question Type"
              single-line
              outlined
              dense
              required
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="secondary"
            :disabled="creatingQuestion"
            :loading="creatingQuestion"
            @click="createQuestion"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { CreateQuestion } from '@/graphql/Question.gql';
import { questionTypeItems } from '@/enums/QuestionType';

export default {
  name: 'CreateQuestionModal',
  data: () => ({
    dialog: false,
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ]
    },
    title: '',
    questionType: 'STATIC',
    creatingQuestion: false
  }),
  computed: {
    courseContentId() {
      return this.$route.params.courseContentId;
    },
    lessonId() {
      return this.$route.params.lessonId;
    },
    questionTypeItems() {
      return questionTypeItems;
    }
  },
  watch: {
    dialog(dialog) {
      if (!dialog) {
        this.$refs.createQuestionForm.reset();
      }

      this.resetForm();
    }
  },
  methods: {
    resetForm() {
      this.title = '';
      this.creatingQuestion = false;
    },
    async createQuestion() {
      if (this.$refs.createQuestionForm.validate()) {
        try {
          this.creatingQuestion = true;
          const response = await this.$apollo.mutate({
            mutation: CreateQuestion,
            variables: {
              question: {
                title: this.title,
                variations: [],
                lesson: this.lessonId,
                questionType: this.questionType
              }
            }
          });

          this.$router.push(
            this.courseContentId
              ? `/course-content/${this.courseContentId}/lessons/${this.lessonId}/question/${response.data.createQuestion.id}`
              : `/lessons/${this.lessonId}/question/${response.data.createQuestion.id}`
          );
          this.$emit('refetch');
          this.dialog = false;
        } catch (e) {
          e.graphQLErrors.map(x => this.$toast.error(x.message));
        } finally {
          this.creatingQuestion = false;
        }
      }
    }
  }
};
</script>
