<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawerState"
      app
      class="side-drawer"
    >
      <v-list class="secondary--text pa-0">
        <div
          style="background-color: #2dd5c4 !important; height: 64px;"
        >
          <v-list-item link @click="() => $router.push('/publish')">
            <v-list-item-content class="logo-sec">
              <img src="../images/logo.svg" class="logo" />
            </v-list-item-content>
          </v-list-item>
        </div>

        <div class="primary" style="height: 36px;">
          <v-row
            no-gutters
            align="center"
            class="px-1"
            style="height: 36px;"
          >
            <v-col v-if="!currentUserAccount.isUser()" cols="auto">
              <v-btn
                color="white"
                icon
                @click="$router.push('/publish')"
                ><v-icon>mdi-arrow-left</v-icon></v-btn
              >
            </v-col>

            <v-col class="pl-1">
              <div
                class="white--text text-body-2 text-truncate"
                style="max-width: 155px;"
              >
                {{ courseContent?.title }}
              </div>
            </v-col>

            <v-col cols="auto">
              <div class="white--text text-body-2">
                <v-chip
                  class="px-2 py-3 font-weight-bold text--primary"
                  color="secondary"
                  x-small
                  >v{{ courseContent?.version }}</v-chip
                >
              </div>
            </v-col>
          </v-row>
        </div>

        <div style="height: calc(100vh - 165px);overflow-y: auto;">
          <div
            v-for="(navItem, index) in navigationItems"
            :key="index"
          >
            <div class="navigation-subtitle">
              {{ navItem.title }}
            </div>

            <div
              v-for="(navChild, index) in navItem.children"
              :key="`${navItem.title.replaceAll(' ', '_')}-${index}`"
            >
              <v-list-item
                link
                active-class="navigation-item-active"
                :to="
                  `/course-content/${$route.params.courseContentId}${navChild.to}`
                "
              >
                <v-list-item-action>
                  <v-icon>{{ navChild.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    navChild.title
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>

            <v-divider class="my-3" />
          </div>
        </div>

        <div class="navigation-bottom-bar">
          <v-row
            class="align-center px-5 text--primary"
            style="height: 64px;"
            no-gutters
          >
            <v-col cols="auto">
              <div class="font-weight-bold">
                {{
                  [
                    currentUserAccount?.firstName,
                    currentUserAccount?.lastName
                  ].join(' ')
                }}
              </div>
              <div
                class="text-capitalize text--secondary"
                style="font-size: 12px;"
              >
                {{
                  currentUserAccount?.systemRole
                    .replaceAll('_', ' ')
                    .toLowerCase()
                }}
              </div>
            </v-col>

            <v-col cols="auto" class="ml-auto">
              <v-btn icon @click="logout"
                ><v-icon size="24">mdi-logout</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-main class="main-application">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { courseContentNavigationItems } from '@/utils/navigation';
import { auth } from '@/main';
import { CurrentUserAccount } from '@/graphql/UserAccount.gql';
import { CourseContent } from '@/graphql/CourseContent.gql';
import OrganisationSwitcher from '@/components/Common/OrganisationSwitcher';

export default {
  name: 'CourseContentLayout',
  components: { OrganisationSwitcher },
  data: () => ({
    drawerState: null
  }),
  computed: {
    ...mapState('user', ['currentUserAccount']),
    navigationItems() {
      return courseContentNavigationItems;
    }
  },
  apollo: {
    currentUserAccount: {
      query: CurrentUserAccount,
      fetchPolicy: 'no-cache',
      result({ data }) {
        this.updateCurrentUserAccount(data);
      }
    },
    courseContent: {
      query: CourseContent,
      variables() {
        return {
          id: this.$route.params.courseContentId
        };
      }
    }
  },
  methods: {
    ...mapActions('user', ['updateCurrentUserAccount']),
    logout() {
      auth.signOut().then(() => this.$router.push('/'));
    }
  }
};
</script>

<style lang="scss" scoped>
.side-drawer
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
  .col.col-auto
  i {
  color: #fff !important;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
