var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-3" },
    [
      _c(
        "v-row",
        { staticClass: "align-center mb-0", attrs: { align: "center" } },
        [
          _c("v-col", { staticClass: "text--primary font-weight-bold" }, [
            _vm._v(" Single Text Choice Options ")
          ]),
          _vm.courseContentId
            ? _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("create-text-option-modal", {
                    ref: "createTextOptionModalRef",
                    attrs: { "existing-option": _vm.editingOption },
                    on: {
                      "add-option": _vm.addOption,
                      "update-option": _vm.updateOption,
                      "reset-existing-option": function($event) {
                        _vm.editingOption = undefined
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "rounded-lg white px-5 py-1 text--primary" },
        [
          _c("v-data-table", {
            staticClass: "dark--text rounded-b-lg rounded-t-0",
            attrs: {
              items: _vm.existingOptions,
              headers: _vm.headers,
              "no-data-text": `There are no options`,
              "item-key": "id"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.correct`,
                  fn: function({ item }) {
                    return [
                      _c(
                        "v-chip",
                        {
                          staticClass: "white--text font-weight-bold",
                          attrs: { color: item.correct ? "green" : "red" }
                        },
                        [_vm._v(_vm._s(item.correct ? "Correct" : "Incorrect"))]
                      )
                    ]
                  }
                },
                {
                  key: `item.editColumn`,
                  fn: function({ item }) {
                    return [
                      _vm.courseContentId
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "pa-0 rounded-md",
                              attrs: { text: "", icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.editOption(item)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-pencil")])],
                            1
                          )
                        : _vm._e(),
                      _vm.courseContentId
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "pa-0 rounded-md",
                              attrs: { text: "", icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.deleteOption(item.id)
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { color: "red" } }, [
                                _vm._v("mdi-delete-outline")
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }