var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { color: "primary", small: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-plus")]), _vm._v(" Answer")],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "rounded-lg" },
            [
              _c(
                "v-card-title",
                { staticClass: "h6 font-weight-bold primary--text" },
                [_vm._v(" New Option ")]
              ),
              _c(
                "v-card-text",
                { staticClass: "py-2" },
                [
                  _c(
                    "v-form",
                    { ref: "createTextOptionForm" },
                    [
                      _c("v-label", [_vm._v("Option")]),
                      _c("v-textarea", {
                        attrs: {
                          rules: _vm.rules.requiredField,
                          label: "Option",
                          "single-line": "",
                          rows: "2",
                          outlined: "",
                          dense: "",
                          required: ""
                        },
                        model: {
                          value: _vm.option,
                          callback: function($$v) {
                            _vm.option = $$v
                          },
                          expression: "option"
                        }
                      }),
                      _c("v-switch", {
                        staticClass: "mt-1",
                        attrs: { label: "Correct", inset: "" },
                        model: {
                          value: _vm.correct,
                          callback: function($$v) {
                            _vm.correct = $$v
                          },
                          expression: "correct"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary" },
                      on: { click: _vm.createTextOption }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.existingOption ? "Update" : "Create") +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }