<template>
  <v-row
    no-gutters
    align="center"
    class="text--secondary text-subtitle-2 font-weight-light"
  >
    <v-col v-for="(item, index) in items" :key="index" cols="auto">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-1">
          {{ item }}
        </v-col>

        <v-col
          v-if="index != items.length - 1"
          cols="auto"
          class="mr-1"
        >
          /
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    items: {
      required: true
    }
  }
};
</script>
