var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.open,
            callback: function($$v) {
              _vm.open = $$v
            },
            expression: "open"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "rounded-lg" },
            [
              _c(
                "v-card-title",
                { staticClass: "h6 font-weight-bold primary--text" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.existingDementiaFact ? "Update" : "New") +
                      " Fact "
                  )
                ]
              ),
              _c(
                "v-card-text",
                { staticClass: "py-2" },
                [
                  _c(
                    "v-form",
                    { ref: "createDementiaFactForm" },
                    [
                      _c("v-label", [_vm._v("Title")]),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.rules.requiredField,
                          label: "Title",
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          required: ""
                        },
                        model: {
                          value: _vm.title,
                          callback: function($$v) {
                            _vm.title = $$v
                          },
                          expression: "title"
                        }
                      }),
                      _c("v-label", [_vm._v("Content")]),
                      _c("v-textarea", {
                        attrs: {
                          rules: _vm.rules.requiredField,
                          rows: "3",
                          label: "Content",
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          required: ""
                        },
                        model: {
                          value: _vm.content,
                          callback: function($$v) {
                            _vm.content = $$v
                          },
                          expression: "content"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.close }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary" },
                      on: { click: _vm.createDementiaFact }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.existingDementiaFact ? "Update" : "Create"
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }