var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "1000" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "ml-2",
                          attrs: { icon: "", disabled: _vm.disabled }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-pencil")])],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "rounded-lg" },
            [
              _c(
                "v-card-title",
                { staticClass: "h6 font-weight-bold primary--text" },
                [_vm._v(" Select Sites ")]
              ),
              _c(
                "v-card-text",
                { staticClass: "py-2" },
                [
                  _c(
                    "div",
                    { staticClass: "page-sub-sec" },
                    [
                      _c("v-text-field", {
                        staticClass: "shrink",
                        attrs: {
                          "append-icon": "mdi-magnify",
                          label: "Search",
                          outlined: "",
                          dense: "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    staticClass: "dark--text rounded-b-lg rounded-t-0",
                    attrs: {
                      items: _vm.filteredSites,
                      headers: _vm.headers,
                      "no-data-text": `There are no sites${
                        _vm.search ? " that match your search" : ""
                      }`,
                      loading: _vm.$apollo.queries.sites.loading,
                      "loading-text": "Loading...",
                      "show-select": true
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `item.siteCode`,
                          fn: function({ item }) {
                            return [
                              _c("v-chip", { staticClass: "text-button" }, [
                                _vm._v(_vm._s(item.siteCode))
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.selectedSites,
                      callback: function($$v) {
                        _vm.selectedSites = $$v
                      },
                      expression: "selectedSites"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "grey lighten-2" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.addSelectedSites }
                    },
                    [_vm._v(" Update Sites ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }