var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mr-3",
                          attrs: {
                            color: "primary",
                            small: _vm.dense || !!_vm.routeModuleId
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { class: _vm.routeModuleId ? "" : "mr-2" }, [
                        _vm._v("mdi-plus")
                      ]),
                      _vm._v(
                        _vm._s(_vm.routeModuleId ? "Add" : "New") + " Lesson"
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "rounded-lg" },
            [
              _c(
                "v-card-title",
                { staticClass: "h6 font-weight-bold primary--text" },
                [_vm._v(" New Lesson ")]
              ),
              _c(
                "v-card-text",
                { staticClass: "py-2" },
                [
                  _c(
                    "v-form",
                    { ref: "createLessonForm" },
                    [
                      _c("v-label", [_vm._v("Title")]),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.rules.requiredField,
                          label: "Title",
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          required: ""
                        },
                        model: {
                          value: _vm.title,
                          callback: function($$v) {
                            _vm.title = $$v
                          },
                          expression: "title"
                        }
                      }),
                      _c("v-label", [_vm._v("Learning Objective")]),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.rules.requiredField,
                          label: "Learning Objective",
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          required: ""
                        },
                        model: {
                          value: _vm.learningObjective,
                          callback: function($$v) {
                            _vm.learningObjective = $$v
                          },
                          expression: "learningObjective"
                        }
                      }),
                      _c("v-label", [_vm._v("Search Keywords")]),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.rules.requiredField,
                          label: "Search Keywords",
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          required: ""
                        },
                        model: {
                          value: _vm.searchKeywords,
                          callback: function($$v) {
                            _vm.searchKeywords = $$v
                          },
                          expression: "searchKeywords"
                        }
                      }),
                      _c("v-label", [_vm._v("Learning Minutes")]),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.rules.requiredField,
                          label: "Learning Minutes",
                          type: "number",
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          required: ""
                        },
                        model: {
                          value: _vm.learningMinutes,
                          callback: function($$v) {
                            _vm.learningMinutes = $$v
                          },
                          expression: "learningMinutes"
                        }
                      }),
                      _c("v-label", [_vm._v("Module")]),
                      _c("v-select", {
                        attrs: {
                          rules: _vm.rules.requiredField,
                          items: _vm.moduleItems,
                          disabled: !!_vm.routeModuleId,
                          label: "Module",
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          required: ""
                        },
                        model: {
                          value: _vm.moduleId,
                          callback: function($$v) {
                            _vm.moduleId = $$v
                          },
                          expression: "moduleId"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "secondary",
                        disabled: _vm.creatingLesson,
                        loading: _vm.creatingLesson
                      },
                      on: { click: _vm.createLesson }
                    },
                    [_vm._v(" Create ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }