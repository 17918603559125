<template>
  <div class="tabbed-page">
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>Dementia Facts</h1>
              <div class="page-subtitle">
                Configure the facts that are displayed on Ask
                Annie&apos;s loading screens.
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="ml-auto">
          <v-btn
            class="mr-3"
            color="primary"
            @click="openCreateDementiaFactModal = true"
          >
            <v-icon class="mr-2">mdi-plus</v-icon>New Fact</v-btn
          >
        </v-col>
      </v-row>
    </v-container>

    <dementia-facts-table
      :dementia-facts="dementiaFacts"
      :loading="$apollo.queries.dementiaFacts.loading"
      @edit-dementia-fact="editDementiaFact"
      @delete-dementia-fact="deleteDementiaFact"
    />

    <create-dementia-fact-modal
      :open="openCreateDementiaFactModal"
      :existing-dementia-fact="existingDementiaFact"
      @refetch="$apollo.queries.dementiaFacts.refetch()"
      @close="closeCreateDementiaFactModal"
    />
  </div>
</template>

<script>
import DementiaFactsTable from '@/components/DementiaFacts/DementiaFactsTable';
import CreateDementiaFactModal from '@/components/DementiaFacts/CreateDementiaFactModal';
import {
  DementiaFacts,
  DeleteDementiaFact
} from '@/graphql/DementiaFacts.gql';

export default {
  name: 'DementiaFactsPage',
  components: {
    DementiaFactsTable,
    CreateDementiaFactModal
  },
  data: () => ({
    openCreateDementiaFactModal: false,
    existingDementiaFact: null
  }),
  apollo: {
    dementiaFacts: {
      query: DementiaFacts
    }
  },
  methods: {
    closeCreateDementiaFactModal() {
      this.openCreateDementiaFactModal = false;
      this.existingDementiaFact = null;
    },
    editDementiaFact(dementiaFact) {
      this.existingDementiaFact = dementiaFact;
      this.openCreateDementiaFactModal = true;
    },
    async deleteDementiaFact(id) {
      try {
        await this.$apollo.mutate({
          mutation: DeleteDementiaFact,
          variables: {
            id: id
          }
        });

        this.$apollo.queries.dementiaFacts.refetch();
      } catch (e) {
        e.graphQLErrors.map(x => this.$toast.error(x.message));
      }
    }
  }
};
</script>
