<template>
  <v-dialog :value="open" width="500" @input="$emit('update:open', $event)">
    <v-card class="rounded-lg">
      <v-card-title class="h6 font-weight-bold primary--text">
        Edit Text Content
      </v-card-title>

      <v-card-text class="py-2">
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="localContent.title"
            :rules="rules.requiredField"
            label="Title"
            single-line
            outlined
            dense
            required
          />

          <tiptap-vuetify
            v-model="localContent.content"
            :extensions="extensions"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn color="primary" @click="save" :disabled="!valid">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
  History
} from 'tiptap-vuetify';

export default {
  name: 'EditTextContentModal',
  components: { TiptapVuetify },
  props: {
    content: {
      type: Object,
      default: null
    },
    open: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    valid: false,
    localContent: {
      title: '',
      content: '',
      contentType: 'TEXT'
    },
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ]
    },
    extensions: [
      History,
      Underline,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      Bold,
      HardBreak
    ]
  }),
  watch: {
    content: {
      immediate: true,
      handler(content) {
        if (content) {
          this.localContent = { ...content };
        } else {
          this.localContent = {
            title: '',
            content: '',
            contentType: 'TEXT'
          };
        }
      }
    }
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localContent);
        this.$emit('update:open', false);
      }
    },
    cancel() {
      this.$emit('update:open', false);
    }
  }
};
</script>
