<template>
  <div v-if="$apollo.queries.question.loading">Loading...</div>
  <div v-else>
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <v-row no-gutters align="center">
                <v-col cols="auto" class="mr-2 ml-n2">
                  <v-btn icon @click="$router.go(-1)"
                    ><v-icon>mdi-arrow-left</v-icon></v-btn
                  >
                </v-col>
                <v-col cols="auto" class="mr-auto">
                  <h1>{{ lesson?.title }}:</h1>
                </v-col>
                <v-col cols="auto" class="mr-auto ml-2">
                  <h1>
                    <edit-field
                      :readonly="!courseContentId"
                      :current-value="question?.title"
                      @update-value="updateTitle"
                    />
                  </h1>
                </v-col>
              </v-row>

              <v-row no-gutters align="center">
                <v-col cols="auto">
                  <breadcrumbs
                    :items="[
                      'Lessons',
                      lesson?.title,
                      question?.title
                    ]"
                  />
                </v-col>
                <v-col cols="auto">
                  <v-chip
                    small
                    color="primary"
                    class="font-weight-bold text-caption"
                    >v{{ question?.version }}</v-chip
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="ml-auto d-flex">
          <question-timeline-modal
            :question-reference-id="question?.referenceId"
            :current-version="question?.version"
          />
          <create-branch-modal
            v-if="isBranching && courseContentId"
            :is-first-node="question.variations.length === 0"
            @add-variation="addVariation"
          />
          <create-variation-modal
            v-else-if="!isBranching && courseContentId"
            @add-variation="addVariation"
          />
        </v-col>
      </v-row>

      <div class="mt-10">
        <v-row
          v-if="question.variations.length === 0"
          align="center"
          justify="center"
        >
          <v-col class="text-center">
            {{
              isBranching
                ? 'There are no branches for this question.'
                : 'There are no variations for this question.'
            }}
          </v-col>
        </v-row>
        <div v-else>
          <div v-if="isBranching">
            <div
              v-for="(variation, index) in question.variations"
              :key="index"
              class="mb-10"
            >
              <branching-node
                :variation="variation"
                :index="index"
                :nodes="question.variations"
                @node-updated="updateQuestion"
                @delete-node="deleteVariation"
              />
            </div>
          </div>
          <div v-else>
            <div
              v-for="(variation, index) in question.variations"
              :key="index"
              class="mb-10"
            >
              <v-row align="center" class="align-center mb-0">
                <v-col class="text--primary font-weight-bold text-h6">
                  Variation {{ index + 1 }}
                </v-col>

                <v-col v-if="courseContentId" cols="auto">
                  <v-btn
                    color="error"
                    small
                    @click="deleteVariation(variation.id)"
                    >Delete</v-btn
                  >
                </v-col>
              </v-row>
              <div class="rounded-lg white px-5 py-1 text--primary">
                <v-row
                  no-gutters
                  align="center"
                  class="info-row py-4"
                >
                  <v-col cols="auto" class="mr-auto title-column">
                    Question Type
                  </v-col>
                  <v-col cols="auto" class="ml-auto text-capitalize">
                    {{
                      variation.variationType
                        ?.replaceAll('_', ' ')
                        ?.toLowerCase()
                    }}
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  align="center"
                  class="info-row py-2"
                >
                  <v-col cols="auto" class="mr-auto title-column">
                    Question Text
                  </v-col>
                  <v-col cols="auto" class="ml-auto">
                    <edit-field
                      :readonly="!courseContentId"
                      type="richtext"
                      :current-value="variation.questionText"
                      @update-value="
                        newValue =>
                          updateQuestionText(newValue, index)
                      "
                    />
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  align="center"
                  class="info-row py-2"
                >
                  <v-col cols="auto" class="mr-auto title-column">
                    Incorrect Answer Feedback
                  </v-col>
                  <v-col cols="auto" class="ml-auto">
                    <edit-field
                      :readonly="!courseContentId"
                      :current-value="
                        variation.incorrectAnswerFeedback
                      "
                      @update-value="
                        newValue =>
                          updateIncorrectAnswerFeedback(
                            newValue,
                            index
                          )
                      "
                    />
                  </v-col>
                </v-row>
              </div>

              <single-text-choice
                v-if="
                  variation.variationType ==
                    variantType.SINGLE_TEXT_CHOICE
                "
                :existing-options="variation.options"
                @options-updated="
                  updateOptionsForVariation($event, index)
                "
              />
              <single-image-choice
                v-if="
                  variation.variationType ==
                    variantType.SINGLE_IMAGE_CHOICE
                "
                :existing-options="variation.options"
                @options-updated="
                  updateOptionsForVariation($event, index)
                "
              />
              <multiple-text-choice
                v-if="
                  variation.variationType ==
                    variantType.MULTIPLE_TEXT_CHOICE
                "
                :existing-options="variation.options"
                @options-updated="
                  updateOptionsForVariation($event, index)
                "
              />
              <multiple-image-choice
                v-if="
                  variation.variationType ==
                    variantType.MULTIPLE_IMAGE_CHOICE
                "
                :existing-options="variation.options"
                @options-updated="
                  updateOptionsForVariation($event, index)
                "
              />
              <order-list
                v-if="
                  variation.variationType == variantType.ORDER_LIST
                "
                :existing-options="variation.options"
                @options-updated="
                  updateOptionsForVariation($event, index)
                "
              />
              <drag-and-drop
                v-if="
                  variation.variationType == variantType.DRAG_AND_DROP
                "
                :existing-options="variation.options"
                @options-updated="
                  updateOptionsForVariation($event, index)
                "
              />
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import EditField from '@/components/Common/EditField';
import CreateVariationModal from '@/components/Question/CreateVariationModal';
import Breadcrumbs from '@/components/Common/Breadcrumbs';
import Question from '@/models/Question';
import VariantType from '@/enums/VariantType';
import SingleTextChoice from '@/components/Question/Type/SingleTextChoice';
import SingleImageChoice from '@/components/Question/Type/SingleImageChoice';
import MultipleImageChoice from '@/components/Question/Type/MultipleImageChoice';
import MultipleTextChoice from '@/components/Question/Type/MultipleTextChoice';
import OrderList from '@/components/Question/Type/OrderList';
import DragAndDrop from '@/components/Question/Type/DragAndDrop';
import {
  Question as QuestionQuery,
  UpdateQuestion
} from '@/graphql/Question.gql';
import { Lesson } from '@/graphql/Lesson.gql';
import QuestionType from '@/enums/QuestionType';
import CreateBranchModal from '@/components/Question/CreateBranchModal';
import { branchingNodeTypeItems } from '@/enums/BranchingNodeType';
import BranchingNode from '@/components/Question/Type/Branching/BranchingNode';
import QuestionTimelineModal from '@/components/Question/QuestionTimelineModal';

export default {
  name: 'QuestionPage',
  components: {
    Breadcrumbs,
    CreateVariationModal,
    SingleTextChoice,
    SingleImageChoice,
    MultipleImageChoice,
    MultipleTextChoice,
    OrderList,
    DragAndDrop,
    EditField,
    CreateBranchModal,
    BranchingNode,
    QuestionTimelineModal
  },
  data: () => ({
    question: undefined
  }),
  computed: {
    courseContentId() {
      return this.$route.params.courseContentId;
    },
    lessonId() {
      return this.$route.params.lessonId;
    },
    questionId() {
      return this.$route.params.questionId;
    },
    variantType() {
      return VariantType;
    },
    isBranching() {
      return this.question?.questionType === QuestionType.BRANCHING;
    },
    branchingNodeTypeItems() {
      return branchingNodeTypeItems;
    }
  },
  apollo: {
    lesson: {
      query: Lesson,
      variables() {
        return {
          id: this.lessonId
        };
      },
      skip() {
        return !this.lessonId;
      }
    },
    question: {
      query: QuestionQuery,
      variables() {
        return {
          id: this.questionId
        };
      },
      update(data) {
        return new Question(data.question);
      }
    }
  },
  methods: {
    addVariation(variation) {
      const updatedQuestion = {
        ...this.question,
        variations: [...this.question.variations, variation]
      };
      this.updateQuestion(updatedQuestion);
    },
    deleteVariation(id) {
      const updatedQuestion = {
        ...this.question,
        variations: this.question.variations.filter(
          variation => variation.id !== id
        )
      };
      this.updateQuestion(updatedQuestion);
    },
    updateTitle(newTitle) {
      const updatedQuestion = {
        ...this.question,
        title: newTitle
      };
      this.updateQuestion(updatedQuestion);
    },
    async updateQuestion(question) {
      try {
        // Apollo should automatically update the cache with the latest changes.
        await this.$apollo.mutate({
          mutation: UpdateQuestion,
          variables: {
            question: {
              id: question.id,
              title: question.title,
              variations: question.variations.map(variation =>
                JSON.stringify(variation)
              ),
              questionType: this.question.questionType,
              lesson: this.question.lesson,
              referenceId: this.question.referenceId,
              version: this.question.version
            }
          }
        });
      } catch (e) {
        e.graphQLErrors.map(x => this.$toast.error(x.message));
      }
    },
    updateOptionsForVariation(updatedOptions, index) {
      const updatedQuestion = {
        ...this.question,
        variations: this.question.variations.map((variation, i) =>
          i === index
            ? { ...variation, options: updatedOptions }
            : variation
        )
      };
      this.updateQuestion(updatedQuestion);
    },
    updateTitle(newValue, index) {
      this.question.variations[index].title = newValue;
      this.updateQuestion();
    },
    updateLabel(newValue, index) {
      this.question.variations[index].label = newValue;
      this.updateQuestion();
    },
    updateBranchingNodeType(newValue, index) {
      this.question.variations[index].branchingNodeType = newValue;
      this.updateQuestion();
    },
    updateQuestionText(newValue, index) {
      const updatedQuestion = {
        ...this.question,
        variations: this.question.variations.map((variation, i) =>
          i === index
            ? { ...variation, questionText: newValue }
            : variation
        )
      };
      this.updateQuestion(updatedQuestion);
    },
    updateIncorrectAnswerFeedback(newValue, index) {
      const updatedQuestion = {
        ...this.question,
        variations: this.question.variations.map((variation, i) =>
          i === index
            ? { ...variation, incorrectAnswerFeedback: newValue }
            : variation
        )
      };
      this.updateQuestion(updatedQuestion);
    }
  }
};
</script>
