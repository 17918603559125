import { render, staticRenderFns } from "./UserDetails.vue?vue&type=template&id=481c8183"
import script from "./UserDetails.vue?vue&type=script&lang=js"
export * from "./UserDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('481c8183')) {
      api.createRecord('481c8183', component.options)
    } else {
      api.reload('481c8183', component.options)
    }
    module.hot.accept("./UserDetails.vue?vue&type=template&id=481c8183", function () {
      api.rerender('481c8183', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/UserDetails.vue"
export default component.exports