var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.open, width: "500" },
      on: {
        "click:outside": function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "rounded-lg" },
        [
          _c(
            "v-card-title",
            { staticClass: "h6 font-weight-bold primary--text" },
            [_vm._v(" New Organisation ")]
          ),
          _c(
            "v-card-text",
            { staticClass: "py-2" },
            [
              _c(
                "v-form",
                {
                  ref: "createOrganisationForm",
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("v-label", [_vm._v("Name")]),
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.rules.requiredField,
                      label: "Name",
                      "single-line": "",
                      outlined: "",
                      dense: "",
                      required: ""
                    },
                    model: {
                      value: _vm.companyName,
                      callback: function($$v) {
                        _vm.companyName = $$v
                      },
                      expression: "companyName"
                    }
                  }),
                  _c("v-label", [_vm._v("Logo")]),
                  _c("v-file-input", {
                    ref: "fileInput",
                    attrs: {
                      label: "Logo",
                      rules: _vm.rules.logo,
                      "single-line": "",
                      outlined: "",
                      dense: "",
                      required: ""
                    },
                    on: { change: _vm.validateFile },
                    model: {
                      value: _vm.input.file,
                      callback: function($$v) {
                        _vm.$set(_vm.input, "file", $$v)
                      },
                      expression: "input.file"
                    }
                  }),
                  _c("v-label", [_vm._v("Address")]),
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.rules.requiredField,
                      label: "Address",
                      "single-line": "",
                      outlined: "",
                      dense: "",
                      required: ""
                    },
                    model: {
                      value: _vm.address,
                      callback: function($$v) {
                        _vm.address = $$v
                      },
                      expression: "address"
                    }
                  }),
                  _c("v-label", [_vm._v("Country")]),
                  _c("v-select", {
                    attrs: {
                      rules: _vm.rules.requiredField,
                      items: _vm.countryOptions,
                      label: "Country",
                      "single-line": "",
                      outlined: "",
                      dense: "",
                      required: ""
                    },
                    model: {
                      value: _vm.country,
                      callback: function($$v) {
                        _vm.country = $$v
                      },
                      expression: "country"
                    }
                  }),
                  _c("v-label", [_vm._v("Website")]),
                  _c("v-text-field", {
                    attrs: {
                      label: "Website",
                      "single-line": "",
                      outlined: "",
                      dense: ""
                    },
                    model: {
                      value: _vm.website,
                      callback: function($$v) {
                        _vm.website = $$v
                      },
                      expression: "website"
                    }
                  }),
                  _c("v-label", [_vm._v("Organisation Code")]),
                  _c("v-text-field", {
                    staticClass: "organisation-code-text-input",
                    attrs: {
                      rules: _vm.rules.organisationCode,
                      label: "Organisation Code",
                      "single-line": "",
                      outlined: "",
                      dense: "",
                      required: ""
                    },
                    model: {
                      value: _vm.organisationCode,
                      callback: function($$v) {
                        _vm.organisationCode = $$v
                      },
                      expression: "organisationCode"
                    }
                  }),
                  _c("v-label", [_vm._v("Employee Identifier")]),
                  _c("v-select", {
                    attrs: {
                      rules: _vm.rules.requiredField,
                      items: _vm.employeeIdentifierOptions,
                      label: "Employee Identifier",
                      "single-line": "",
                      outlined: "",
                      dense: "",
                      required: ""
                    },
                    model: {
                      value: _vm.employeeIdentifier,
                      callback: function($$v) {
                        _vm.employeeIdentifier = $$v
                      },
                      expression: "employeeIdentifier"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.isLoading
            ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
            : _vm._e(),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary" },
                  on: { click: _vm.createOrganisation }
                },
                [_vm._v(" Create ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }