var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.open, width: "500" },
      on: {
        "click:outside": function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "rounded-lg" },
        [
          _c(
            "v-card-title",
            { staticClass: "h6 font-weight-bold primary--text" },
            [
              _vm._v(" Missing Media "),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("p", [
                _vm._v(
                  " Missing media will result in a placeholder image being shown in the app. Upload media to resolve this issue, or remove the media reference from the configuration. Below is a list of file names referenced in the configuration that are missing. "
                )
              ]),
              _c(
                "v-list",
                _vm._l(_vm.missingMedia, function(media) {
                  return _c(
                    "v-list-item",
                    { key: media },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "orange--text darken-1 mr-2" },
                        [_vm._v("error")]
                      ),
                      _c("span", [_vm._v(_vm._s(media))])
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-end pa-4" },
            [
              _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }