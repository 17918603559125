<template>
  <div>
    <v-dialog v-model="dialog" width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :class="errorAlert ? 'primary--text' : 'mr-3'"
          :color="errorAlert ? 'white' : 'grey lighten-2'"
          :small="errorAlert"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="mr-2">mdi-history</v-icon>Version History</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          Course History
        </v-card-title>

        <v-card-text>
          <v-timeline dense clipped class="ml-n3 mr-2">
            <v-timeline-item
              v-for="course in courseVersions"
              :key="course.id"
              :color="
                course.version === currentVersion ? 'primary' : 'grey'
              "
              icon-color="white"
              class="mb-4 align-center"
            >
              <template v-slot:icon>
                <span class="white--text font-weight-bold text-caption">V{{ course.version }}</span>
              </template>

              <v-row justify="space-between" align="center">
                <v-col cols="7" class="primary--text">
                  <v-row align="center" no-gutters>
                    <v-col cols="auto">
                      {{ course.title }}
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        v-if="course.version !== currentVersion"
                        icon
                        color="primary"
                        class="ml-1"
                        @click="openVersion(course.id)"
                      >
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row align="center" no-gutters>
                    <v-col cols="auto" class="text-capitalize font-weight-bold text-caption" :style="{ color: getCourseContentStateColour(getCourseContentState(course)) }">
                      {{ getCourseContentState(course).toLowerCase() }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="text-right" cols="5">
                  {{ formatDate(course.createdAt) }}
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { CourseVersions } from '@/graphql/Course.gql';
import { formatDate } from '@/utils/date';
import { getStateColour } from '@/utils/courseContent';

export default {
  name: 'CourseTimelineModal',
  props: {
    courseReferenceId: {
      type: String,
      required: true
    },
    currentVersion: {
      type: Number,
      required: true
    },
    errorAlert: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false
  }),
  apollo: {
    courseVersions: {
      query: CourseVersions,
      variables() {
        return { referenceId: this.courseReferenceId };
      },
      skip() {
        return !this.courseReferenceId;
      }
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    openVersion(id) {
      this.dialog = false;
      this.$router.push(`/courses/${id}`);
    },
    getCourseContentState(course) {
      return course.isTestCourseContent
        ? 'TESTING'
        : course.isRetiredCourseContent
        ? 'UNAVAILABLE'
        : course.isCurrentCourseContent
        ? 'PUBLISHED'
        : 'DRAFT';
    },
    getCourseContentStateColour(courseContentState) {
      return getStateColour(courseContentState);
    }
  }
};
</script>
