import { capitaliseFirstLetter } from '@/utils/text';

const enumToDisplay = enumToUse => {
  return Object.values(enumToUse).map(val => ({
    text: capitaliseFirstLetter(
      val.replaceAll('_', ' ').toLowerCase()
    ),
    value: val
  }));
};

export default enumToDisplay;
