<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="text-caption"
          color="primary"
          x-small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="ml-n1">mdi-plus</v-icon>
          New Question
        </v-btn>
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          New Question
        </v-card-title>

        <v-card-text class="py-2">
          <v-form ref="createQuestionForm">
            <v-label>Icon Name</v-label>
            <v-text-field
              v-model="iconName"
              :rules="rules.requiredField"
              label="Icon Name"
              single-line
              outlined
              dense
              required
            />

            <v-label>Text</v-label>
            <v-text-field
              v-model="text"
              :rules="rules.requiredField"
              label="Text"
              single-line
              outlined
              dense
              required
            />

            <v-switch
              v-model="correct"
              class="mt-1"
              label="Correct"
              inset
            />

            <v-label>Link Node</v-label>
            <v-select
              v-model="linkNode"
              :items="nodeItems"
              :rules="rules.requiredField"
              label="Link Node"
              single-line
              outlined
              dense
              required
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="secondary" @click="createQuestion">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { questionTypeItems } from '@/enums/QuestionType';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'BranchQuestionModal',
  props: {
    nodeId: {
      type: String,
      required: true
    },
    nodes: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ]
    },
    id: uuidv4(),
    iconName: '',
    text: '',
    correct: false,
    linkNode: null,
  }),
  computed: {
    lessonId() {
      return this.$route.params.lessonId;
    },
    questionTypeItems() {
      return questionTypeItems;
    },
    nodeItems() {
      return this.nodes
        .filter(node => node.id !== this.nodeId)
        .map(node => ({
          text: node.label,
          value: node.id
        }));
    }
  },
  watch: {
    dialog(dialog) {
      if (!dialog) {
        this.$refs.createQuestionForm.reset();
      }

      this.resetForm();
    }
  },
  methods: {
    resetForm() {
      this.id = uuidv4();
      this.iconName = '';
      this.text = '';
      this.correct = false;
      this.linkNode = null;
    },
    async createQuestion() {
      if (this.$refs.createQuestionForm.validate()) {
        this.$emit('create-question', {
          id: this.id,
          iconName: this.iconName,
          text: this.text,
          correct: this.correct,
          linkNode: this.linkNode
        });
        this.dialog = false;
      }
    }
  }
};
</script>
