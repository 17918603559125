var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "1200" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { color: "primary", small: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", [
                        _vm._v(
                          _vm._s(
                            _vm.selectedCourseContent
                              ? "mdi-pencil"
                              : "mdi-plus"
                          )
                        )
                      ]),
                      _vm._v(
                        _vm._s(_vm.selectedCourseContent ? "Change" : "Select")
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "rounded-lg" },
            [
              _c(
                "v-card-title",
                { staticClass: "h6 font-weight-bold primary--text" },
                [_vm._v(" Select Course Content ")]
              ),
              _c(
                "v-card-text",
                { staticClass: "py-2" },
                [
                  _c("publish-table", {
                    ref: "courseContentTable",
                    attrs: {
                      "course-content": _vm.courseContents ?? [],
                      loading: _vm.$apollo.queries.courseContents.loading,
                      "allow-select": ""
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary" },
                      on: { click: _vm.addSelectedCourse }
                    },
                    [_vm._v(" Select Course Content ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }