<template>
  <div>
    <v-dialog v-model="dialog" width="1000">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on" small>
          <v-icon>mdi-plus</v-icon>Lesson</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          Select Lesson
          {{ moduleTitle ? `for ${moduleTitle}` : '' }}
        </v-card-title>

        <v-card-text class="py-2">
          <lessons-table
            ref="lessonsTable"
            :lessons="filteredLessons"
            :loading="$apollo.queries.lessons.loading"
            :allow-selects="true"
            :singleSelect="singleSelect"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="secondary" @click="addSelectedLessons">
            Add Lessons
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Lessons } from '@/graphql/Lesson.gql';
import LessonsTable from '@/components/Lesson/LessonsTable';

export default {
  name: 'LessonSelector',
  components: { LessonsTable },
  props: {
    moduleTitle: {
      default: undefined
    },
    selectedLessons: {
      default: []
    },
    singleSelect: {
      default: false
    }
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    filteredLessons() {
      return (
        this.lessons?.filter(lesson => {
          if (this.selectedLessons.includes(lesson.id)) {
            return false;
          }

          return true;
        }) ?? []
      );
    }
  },
  apollo: {
    lessons: {
      query: Lessons
    }
  },
  methods: {
    addSelectedLessons() {
      const selectedLessons = this.$refs.lessonsTable.selectedLessons;

      if (selectedLessons.length > 0) {
        this.$emit(
          'add-selected-lessons',
          selectedLessons.map(lesson => lesson.id)
        );
      }

      this.dialog = false;
    }
  }
};
</script>
