<template>
  <div>
    <div class="page-sub-sec">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        dense
        hide-details
        class="shrink"
      />
    </div>
    <v-data-table
      :items="filteredStaff"
      :headers="headers"
      class="dark--text rounded-b-lg rounded-t-0"
      :no-data-text="
        `There are no staff${search ? ' that match your search' : ''}`
      "
      :loading="loading"
      loading-text="Loading..."
      item-key="id"
      @click:row="item => $router.push(`/staff/${item.id}`)"
    >
      <template v-slot:[`item.firstName`]="{ item }">
        {{ [item.firstName, item.lastName].join(' ') }}
      </template>
      <template v-slot:[`item.email`]="{ item }">
        {{ item.email }}
      </template>

      <template v-slot:[`item.memberRole`]="{ item }">
        <span class="text-capitalize">{{
          item.systemRole.replaceAll('_', ' ').toLowerCase()
        }}</span>
      </template>

      <template v-slot:[`item.editColumn`]="{ item }">
        <v-btn icon @click="$router.push(`/staff/${item.id}`)">
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { objectHasSearch } from '@/utils/search';

export default {
  name: 'DementiaStaffTable',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    staff: {
      required: true
    }
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Name', value: 'firstName' },
      { text: 'Email', value: 'email' },
      { text: 'Role', value: 'memberRole' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ]
  }),
  computed: {
    filteredStaff() {
      return this.search
        ? this.staff.filter(staff =>
            objectHasSearch(staff, this.search)
          )
        : this.staff;
    }
  }
};
</script>
