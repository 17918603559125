<template>
  <v-dialog :value="open" width="500" @click:outside="$emit('close')">
    <v-card class="rounded-lg">
      <v-card-title class="h6 font-weight-bold primary--text">
        Warnings
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p>
          Warnings should ideally be resolved, but Ask Annie Anything
          will continue to work regardless. They may result in some
          strange behaviour in the app if not resolved. Upload a new
          version to resolve them.
        </p>
        <v-list>
          <v-list-item
            v-for="(warning, i) in warnings"
            :key="warning + '_' + i"
          >
            <v-icon class="orange--text darken-1 mr-2">error</v-icon>
            <span>{{ warning }}</span>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="justify-end pa-4">
        <v-btn @click="$emit('close')">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true
    },
    warnings: {
      type: Array,
      required: true
    }
  },
  methods: {}
};
</script>
