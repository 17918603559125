var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-sub-sec" },
        [
          _c("v-text-field", {
            staticClass: "shrink",
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search",
              outlined: "",
              dense: "",
              "hide-details": ""
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "dark--text rounded-b-lg rounded-t-0",
        attrs: {
          items: _vm.filteredCourseContent,
          "show-select": _vm.allowSelect,
          "single-select": _vm.allowSelect,
          headers: _vm.headersToUse,
          "no-data-text": `There are no course content${
            _vm.search ? " that match your search" : ""
          }`,
          loading: _vm.loading,
          "loading-text": "Loading...",
          "item-key": "id"
        },
        on: {
          "click:row": item =>
            _vm.$router.push(`/course-content/${item.id}/overview`)
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.createdBy`,
              fn: function({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        [
                          item.createdBy.firstName,
                          item.createdBy.lastName
                        ].join(" ")
                      ) +
                      " "
                  )
                ]
              }
            },
            {
              key: `item.publishedAt`,
              fn: function({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        item.publishedAt
                          ? _vm.formatDate(item.publishedAt)
                          : "-"
                      ) +
                      " "
                  )
                ]
              }
            },
            {
              key: `item.state`,
              fn: function({ item }) {
                return [
                  _c(
                    "span",
                    {
                      staticClass: "text-capitalize",
                      style: `color: ${_vm.getStateColour(item.state)}`
                    },
                    [_vm._v(" " + _vm._s(item.state.toLowerCase()) + " ")]
                  )
                ]
              }
            },
            {
              key: `item.editColumn`,
              fn: function({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "pa-0 rounded-md",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push(
                            `/course-content/${item.id}/overview`
                          )
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-right")])],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.selectedCourseContent,
          callback: function($$v) {
            _vm.selectedCourseContent = $$v
          },
          expression: "selectedCourseContent"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }