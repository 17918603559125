export const getMembershipStateColour = state => {
  switch (state) {
    case 'ACTIVE':
      return '#66BB6A';
    case 'PENDING':
      return '#FFA726';
    default:
      return '#EF5350';
  }
};
