<template>
  <v-menu offset-y top :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-row
        align="center"
        :class="`${readonly ? 'py-2' : ''}`"
        no-gutters
      >
        <v-col cols="auto" :class="`${readonly ? 'pa-0 mr-2' : ''}`">
          <div
            class="rounded-lg mr-2"
            :style="
              `width: 25px; height: 25px; background-color: ${currentColour}`
            "
            v-bind="attrs"
            v-on="!readonly ? on : undefined"
          ></div>
        </v-col>
        <v-col cols="auto" :class="`${readonly ? 'pa-0' : ''} mr-2`"
          ><div class="text--primary text-uppercase">
            {{ currentColour }}
          </div></v-col
        >
        <v-col v-if="!readonly" cols="auto">
          <v-btn text v-bind="attrs" v-on="on" icon>
            <v-icon>mdi-pencil</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </template>
    <v-color-picker
      :value="currentColour"
      dot-size="25"
      hide-inputs
      hide-mode-switch
      mode="hexa"
      width="300px"
      @input="debouncedColourChanged"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'ColourPicker',
  props: {
    currentColour: {
      type: String
    },
    label: {
      type: String
    },
    readonly: {
      type: Boolean
    }
  },
  data() {
    return {
      debounceTimeout: null
    };
  },
  destroyed() {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
  },
  methods: {
    colourChanged(e) {
      this.$emit('change', e);
    },
    debouncedColourChanged(e) {
      if (this.currentColour?.toLowerCase() === e.toLowerCase()) return;
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.colourChanged(e);
      }, 300);
    }
  }
};
</script>

<style lang="scss">
.v-menu__content {
  min-width: 300px !important;
  max-width: 300px !important;
}
</style>
