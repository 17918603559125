var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabbed-page" },
    [
      _c(
        "v-container",
        { staticClass: "primary--text mb-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "auto" } },
                [
                  _c("h1", [_vm._v(_vm._s(_vm.lesson?.title))]),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("breadcrumbs", {
                            attrs: { items: ["Lessons", _vm.lesson?.title] }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "font-weight-bold text-caption",
                              attrs: { small: "", color: "primary" }
                            },
                            [_vm._v("v" + _vm._s(_vm.lesson?.version))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mr-0", attrs: { align: "center" } },
                    [
                      _c("lesson-timeline-modal", {
                        attrs: {
                          "lesson-reference-id":
                            _vm.lesson?.completionReferenceId,
                          "current-version": _vm.lesson?.version
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", [
        _c(
          "div",
          { staticClass: "text--primary mb-3 font-weight-bold text-h6" },
          [_vm._v(" Lesson Configuration ")]
        ),
        _c(
          "div",
          { staticClass: "rounded-lg white px-5 py-1 text--primary" },
          [
            _c(
              "v-row",
              {
                staticClass: "info-row py-2",
                attrs: { "no-gutters": "", align: "center" }
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "mr-auto title-column",
                    attrs: { cols: "auto" }
                  },
                  [_vm._v(" Lesson Title ")]
                ),
                _c(
                  "v-col",
                  { staticClass: "ml-auto", attrs: { cols: "auto" } },
                  [
                    _c("edit-field", {
                      attrs: {
                        readonly: !_vm.courseContentId,
                        "current-value": _vm.lesson?.title
                      },
                      on: { "update-value": _vm.updateTitle }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "info-row py-2",
                attrs: { "no-gutters": "", align: "center" }
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "mr-auto title-column",
                    attrs: { cols: "auto" }
                  },
                  [_vm._v(" Learning Objective ")]
                ),
                _c(
                  "v-col",
                  { staticClass: "ml-auto", attrs: { cols: "auto" } },
                  [
                    _c("edit-field", {
                      attrs: {
                        readonly: !_vm.courseContentId,
                        "current-value": _vm.lesson?.learningObjective
                      },
                      on: { "update-value": _vm.updateLearningObjective }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "info-row py-2",
                attrs: { "no-gutters": "", align: "center" }
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "mr-auto title-column",
                    attrs: { cols: "auto" }
                  },
                  [_vm._v(" Search Keywords ")]
                ),
                _c(
                  "v-col",
                  { staticClass: "ml-auto", attrs: { cols: "auto" } },
                  [
                    _c("edit-field", {
                      attrs: {
                        readonly: !_vm.courseContentId,
                        "current-value": _vm.lesson?.searchKeywords
                      },
                      on: { "update-value": _vm.updateSearchKeywords }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "info-row py-2",
                attrs: { "no-gutters": "", align: "center" }
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "mr-auto title-column",
                    attrs: { cols: "auto" }
                  },
                  [_vm._v(" Visible ")]
                ),
                _c(
                  "v-col",
                  { staticClass: "ml-auto", attrs: { cols: "auto" } },
                  [
                    _c("v-switch", {
                      staticClass: "mt-0",
                      attrs: {
                        disabled: !_vm.courseContentId,
                        "input-value": _vm.lesson?.visible,
                        "hide-details": "",
                        inset: ""
                      },
                      on: { change: _vm.updateVisible }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "info-row py-2",
                attrs: { "no-gutters": "", align: "center" }
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "mr-auto title-column",
                    attrs: { cols: "auto" }
                  },
                  [_vm._v(" Learning Minutes ")]
                ),
                _c(
                  "v-col",
                  { staticClass: "ml-auto", attrs: { cols: "auto" } },
                  [
                    _c("edit-field", {
                      attrs: {
                        readonly: !_vm.courseContentId,
                        "current-value": _vm.lesson?.learningMinutes
                      },
                      on: { "update-value": _vm.saveLearningMinutes }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "info-row py-4",
                attrs: { "no-gutters": "", align: "center" }
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "mr-auto title-column",
                    attrs: { cols: "auto" }
                  },
                  [_vm._v(" Last Updated At ")]
                ),
                _c(
                  "v-col",
                  { staticClass: "ml-auto", attrs: { cols: "auto" } },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.formatDate(_vm.lesson?.updatedAt)) + " "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "mt-8" },
        [
          _c(
            "v-row",
            { attrs: { justify: "space-between", align: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "text--primary mb-3 font-weight-bold text-h6",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Completion Tracking Between Lessons ")]
              ),
              _vm.courseContentId
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _vm.lesson?.id === _vm.lesson?.completionReferenceId
                        ? _c("lesson-selector", {
                            attrs: {
                              "selected-lessons": _vm.lesson?.id
                                ? [_vm.lesson?.id]
                                : [],
                              "single-select": ""
                            },
                            on: {
                              "add-selected-lessons":
                                _vm.updateCompletionReference
                            }
                          })
                        : _c(
                            "v-btn",
                            {
                              attrs: { color: "error", small: "" },
                              on: {
                                click: function($event) {
                                  return _vm.updateCompletionReference([])
                                }
                              }
                            },
                            [
                              _c("v-icon", [_vm._v("mdi-delete-outline")]),
                              _vm._v("Remove")
                            ],
                            1
                          )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "rounded-lg white px-5 text--primary" },
            [
              _c("v-data-table", {
                staticClass: "dark--text rounded-b-lg rounded-t-0",
                attrs: {
                  items: _vm.lesson?.completionReferenceLessons.filter(
                    l => l.id !== _vm.lesson.id
                  ),
                  headers: _vm.lessonCompletionHeaders,
                  "no-data-text":
                    "No lesson completions are counted towards this lesson",
                  "loading-text": "Loading...",
                  "item-key": "id"
                },
                on: {
                  "click:row": item =>
                    _vm.$router.push(
                      _vm.courseContentId
                        ? `/course-content/${_vm.courseContentId}/lessons/${item.id}`
                        : `/lessons/${item.id}`
                    )
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.editColumn`,
                      fn: function({ item }) {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push(
                                    _vm.courseContentId
                                      ? `/course-content/${_vm.courseContentId}/lessons/${item.id}`
                                      : `/lessons/${item.id}`
                                  )
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-eye")])],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-8" },
        [
          _c(
            "v-row",
            { attrs: { justify: "space-between", align: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "text--primary mb-3 font-weight-bold text-h6",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Lesson - Card Image ")]
              ),
              _vm.courseContentId
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("create-content-modal", {
                        attrs: {
                          "required-content-type": [_vm.ContentType.IMAGE],
                          disabled: !!_vm.lesson?.lessonCardImage
                        },
                        on: { "create-content": _vm.saveCardImage }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "rounded-lg white px-5 text--primary" },
            [
              _c("v-data-table", {
                staticClass: "dark--text rounded-b-lg rounded-t-0",
                attrs: {
                  items: _vm.lesson?.lessonCardImage
                    ? [JSON.parse(_vm.lesson?.lessonCardImage)]
                    : [],
                  headers: _vm.lessonHeaderMediaHeaders,
                  "no-data-text": "No lesson card image",
                  "loading-text": "Loading...",
                  "item-key": "id"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.content`,
                      fn: function({ item }) {
                        return [
                          _c("bucket-object-format", {
                            attrs: { "bucket-object-id": item.content }
                          })
                        ]
                      }
                    },
                    {
                      key: `item.contentType`,
                      fn: function({ item }) {
                        return [
                          item.contentType == _vm.ContentType.IMAGE
                            ? _c("v-icon", [_vm._v("mdi-image-outline")])
                            : _vm._e(),
                          item.contentType == _vm.ContentType.VIDEO
                            ? _c("v-icon", [_vm._v("mdi-video-outline")])
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: `item.editColumn`,
                      fn: function({ item }) {
                        return [
                          _vm.courseContentId
                            ? _c("delete-button", {
                                on: { delete: _vm.deleteLessonCardImage }
                              })
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-8" },
        [
          _c(
            "v-row",
            { attrs: { justify: "space-between", align: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "text--primary mb-3 font-weight-bold text-h6",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Lesson Introduction - Header Media ")]
              ),
              _vm.courseContentId
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("create-content-modal", {
                        attrs: {
                          "required-content-type": [
                            _vm.ContentType.IMAGE,
                            _vm.ContentType.VIDEO
                          ],
                          disabled: !!_vm.lesson?.headerMedia
                        },
                        on: { "create-content": _vm.saveHeaderMedia }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "rounded-lg white px-5 text--primary" },
            [
              _c("v-data-table", {
                staticClass: "dark--text rounded-b-lg rounded-t-0",
                attrs: {
                  items: _vm.lesson?.headerMedia
                    ? [JSON.parse(_vm.lesson?.headerMedia)]
                    : [],
                  headers: _vm.lessonHeaderMediaHeaders,
                  "no-data-text": "No lesson introduction header media",
                  "loading-text": "Loading...",
                  "item-key": "id"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.content`,
                      fn: function({ item }) {
                        return [
                          _c("bucket-object-format", {
                            attrs: { "bucket-object-id": item.content }
                          })
                        ]
                      }
                    },
                    {
                      key: `item.contentType`,
                      fn: function({ item }) {
                        return [
                          item.contentType == _vm.ContentType.IMAGE
                            ? _c("v-icon", [_vm._v("mdi-image-outline")])
                            : _vm._e(),
                          item.contentType == _vm.ContentType.VIDEO
                            ? _c("v-icon", [_vm._v("mdi-video-outline")])
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: `item.editColumn`,
                      fn: function({ item }) {
                        return [
                          _vm.courseContentId
                            ? _c("delete-button", {
                                on: { delete: _vm.deleteLessonHeaderMedia }
                              })
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-8" },
        [
          _c(
            "v-row",
            { attrs: { justify: "space-between", align: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "text--primary mb-3 font-weight-bold text-h6",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Lesson Introduction - Content ")]
              ),
              _vm.courseContentId
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("create-content-modal", {
                        on: { "create-content": _vm.createContent }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "rounded-lg white px-5 text--primary" },
            [
              _c("v-data-table", {
                staticClass: "dark--text rounded-b-lg rounded-t-0",
                attrs: {
                  items:
                    _vm.lesson?.lessonIntroduction?.map(fb => JSON.parse(fb)) ??
                    [],
                  headers: _vm.lessonIntroductionHeaders,
                  "no-data-text": "No lesson introduction content",
                  "loading-text": "Loading...",
                  "item-key": "id"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.contentType`,
                      fn: function({ item }) {
                        return [
                          item.contentType == _vm.ContentType.TEXT
                            ? _c("v-icon", [_vm._v("mdi-text-shadow")])
                            : _vm._e(),
                          item.contentType == _vm.ContentType.IMAGE
                            ? _c("v-icon", [_vm._v("mdi-image-outline")])
                            : _vm._e(),
                          item.contentType == _vm.ContentType.VIDEO
                            ? _c("v-icon", [_vm._v("mdi-video-outline")])
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: `item.editColumn`,
                      fn: function({ item }) {
                        return [
                          _vm.courseContentId
                            ? _c("delete-button", {
                                on: {
                                  delete: function($event) {
                                    return _vm.deleteLessonIntroduction(item.id)
                                  }
                                }
                              })
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-8" },
        [
          _c(
            "v-row",
            { attrs: { justify: "space-between", align: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "text--primary mb-3 font-weight-bold text-h6",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Learning Material - Header Media ")]
              ),
              _vm.courseContentId
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("create-content-modal", {
                        attrs: {
                          "required-content-type": [
                            _vm.ContentType.IMAGE,
                            _vm.ContentType.VIDEO
                          ],
                          disabled: !!_vm.lesson?.learningHeaderMedia
                        },
                        on: { "create-content": _vm.saveLearningHeaderMedia }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "rounded-lg white px-5 text--primary" },
            [
              _c("v-data-table", {
                staticClass: "dark--text rounded-b-lg rounded-t-0",
                attrs: {
                  items: _vm.lesson?.learningHeaderMedia
                    ? [JSON.parse(_vm.lesson?.learningHeaderMedia)]
                    : [],
                  headers: _vm.lessonHeaderMediaHeaders,
                  "no-data-text": "No learning header media",
                  "loading-text": "Loading...",
                  "item-key": "id"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.content`,
                      fn: function({ item }) {
                        return [
                          _c("bucket-object-format", {
                            attrs: { "bucket-object-id": item.content }
                          })
                        ]
                      }
                    },
                    {
                      key: `item.contentType`,
                      fn: function({ item }) {
                        return [
                          item.contentType == _vm.ContentType.IMAGE
                            ? _c("v-icon", [_vm._v("mdi-image-outline")])
                            : _vm._e(),
                          item.contentType == _vm.ContentType.VIDEO
                            ? _c("v-icon", [_vm._v("mdi-video-outline")])
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: `item.editColumn`,
                      fn: function({ item }) {
                        return [
                          _vm.courseContentId
                            ? _c("delete-button", {
                                on: { delete: _vm.deleteLearningHeaderMedia }
                              })
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-8" },
        [
          _c(
            "v-row",
            { attrs: { justify: "space-between", align: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "text--primary mb-3 font-weight-bold text-h6",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Learning Material - Introduction ")]
              ),
              _vm.courseContentId
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("create-content-modal", {
                        on: { "create-content": _vm.createLearningIntroduction }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "rounded-lg white px-5 text--primary" },
            [
              _c("v-data-table", {
                staticClass: "dark--text rounded-b-lg rounded-t-0",
                attrs: {
                  items:
                    _vm.lesson?.learningIntroduction?.map(fb =>
                      JSON.parse(fb)
                    ) ?? [],
                  headers: _vm.lessonIntroductionHeaders,
                  "no-data-text": "No learning material introduction content",
                  "loading-text": "Loading...",
                  "item-key": "id"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.contentType`,
                      fn: function({ item }) {
                        return [
                          item.contentType == _vm.ContentType.TEXT
                            ? _c("v-icon", [_vm._v("mdi-text-shadow")])
                            : _vm._e(),
                          item.contentType == _vm.ContentType.IMAGE
                            ? _c("v-icon", [_vm._v("mdi-image-outline")])
                            : _vm._e(),
                          item.contentType == _vm.ContentType.VIDEO
                            ? _c("v-icon", [_vm._v("mdi-video-outline")])
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: `item.editColumn`,
                      fn: function({ item }) {
                        return [
                          _vm.courseContentId
                            ? _c("delete-button", {
                                on: {
                                  delete: () =>
                                    _vm.deleteLearningIntroduction(item.id)
                                }
                              })
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-8" },
        [
          _c(
            "v-row",
            { attrs: { justify: "space-between", align: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "text--primary mb-3 font-weight-bold text-h6",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Feedback ")]
              ),
              _vm.courseContentId
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("create-lesson-feedback-modal", {
                        attrs: { "lesson-id": _vm.lesson?.id },
                        on: { "add-threshold": _vm.addThreshold }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "rounded-lg white px-5 text--primary" },
            [
              _c("v-data-table", {
                staticClass: "dark--text rounded-b-lg rounded-t-0",
                attrs: {
                  items: _vm.lesson?.feedback?.map(fb => JSON.parse(fb)) ?? [],
                  headers: _vm.feedbackHeaders,
                  "no-data-text": "Feedback not set up for this lesson",
                  "loading-text": "Loading...",
                  "item-key": "id"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.editColumn`,
                      fn: function({ item }) {
                        return [
                          _vm.courseContentId
                            ? _c("delete-button", {
                                on: {
                                  delete: () => _vm.deleteThreshold(item.id)
                                }
                              })
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-8" },
        [
          _c(
            "v-row",
            { attrs: { justify: "space-between", align: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "text--primary mb-3 font-weight-bold text-h6",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Questions ")]
              ),
              _vm.courseContentId
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("create-question-modal", {
                        attrs: { "lesson-id": _vm.lesson?.id },
                        on: {
                          refetch: function($event) {
                            return _vm.$apollo.queries.lesson.refetch()
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "rounded-lg white px-5 text--primary" },
            [
              _c("v-data-table", {
                staticClass: "dark--text rounded-b-lg rounded-t-0",
                attrs: {
                  items: _vm.lesson?.questions ?? [],
                  headers: _vm.questionHeaders,
                  "no-data-text": "There are no questions for this lesson",
                  "item-key": "id"
                },
                on: {
                  "click:row": item =>
                    _vm.$router.push(
                      _vm.courseContentId
                        ? `/course-content/${_vm.courseContentId}/lessons/${_vm.lesson?.id}/question/${item.id}`
                        : `/lessons/${_vm.lesson?.id}/question/${item.id}`
                    )
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.updatedAt`,
                      fn: function({ item }) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm.formatDate(item.updatedAt)) + " "
                          )
                        ]
                      }
                    },
                    {
                      key: `item.questionType`,
                      fn: function({ item }) {
                        return [
                          _c("span", { staticClass: "text-capitalize" }, [
                            _vm._v(_vm._s(item.questionType.toLowerCase()))
                          ])
                        ]
                      }
                    },
                    {
                      key: `item.variations`,
                      fn: function({ item }) {
                        return [
                          _vm._v(" " + _vm._s(item.variations.length) + " ")
                        ]
                      }
                    },
                    {
                      key: `item.editColumn`,
                      fn: function({ item }) {
                        return [
                          _vm.courseContentId
                            ? _c("delete-button", {
                                on: {
                                  delete: () => _vm.deleteQuestion(item.id)
                                }
                              })
                            : _vm._e(),
                          _vm.courseContentId
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push(
                                        `/course-content/${_vm.courseContentId}/lessons/${_vm.lesson?.id}/question/${item.id}`
                                      )
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-pencil")])],
                                1
                              )
                            : _vm._e(),
                          !_vm.courseContentId
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push(
                                        `/lessons/${_vm.lesson?.id}/question/${item.id}`
                                      )
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-eye")])],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }