<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" small v-bind="attrs" v-on="on">
          <v-icon>mdi-plus</v-icon>
          Answer</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          New Option
        </v-card-title>

        <v-card-text class="py-2">
          <v-form ref="createImageOptionForm">
            <v-label>Option</v-label>
            <v-file-input
              ref="fileInput"
              v-model="input.file"
              label="Option"
              @change="validateFile"
              single-line
              outlined
              dense
              required
            />

            <div v-if="input.file" class="mt-3">
              <v-label>Image Name</v-label>
              <v-text-field
                v-model="input.name"
                label="Name"
                single-line
                outlined
                dense
                required
              />
            </div>

            <v-switch
              v-model="correct"
              class="mt-1"
              label="Correct"
              inset
            />
          </v-form>
        </v-card-text>

        <v-progress-linear indeterminate v-if="isLoading"></v-progress-linear>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="secondary" @click="createImage">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import {
  CreateBucketObjectWithName,
  RequestUploadUrl
} from '@/graphql/BucketObject.gql';

export default {
  name: 'CreateImageOptionModal',
  data: () => ({
    dialog: false,
    input: {},
    correct: false,
    // Added isLoading state
    isLoading: false
  }),
  computed: {
    lessonId() {
      return this.$route.params.lessonId;
    },
    showNameAndTypeForm() {
      return this.option.file;
    }
  },
  watch: {
    dialog(dialog) {
      if (!dialog) {
        this.$refs.createImageOptionForm.reset();
      } else {
        this.resetForm();
      }
    }
  },
  methods: {
    resetForm() {
      this.input = {};
      this.correct = false;
    },
    async validateFile() {
      this.input.name = this.input.file.name;
    },
    async createImage() {
      this.isLoading = true;
      try {
        const response = await this.$apollo.mutate({
          mutation: CreateBucketObjectWithName,
          variables: {
            bucketObject: {
              objectId: `/lesson/${
                this.lessonId
              }/${moment().unix()}-${this.input.name.replaceAll(
                '/',
                '-'
              )}`,
              fileType: this.input.file.type
            }
          }
        });
        const media = response.data.createBucketObjectWithName;
        const uploadUrlResponse = await this.$apollo.mutate({
          mutation: RequestUploadUrl,
          variables: {
            bucketObjectId: media.id
          }
        });
        const uploadUrl = uploadUrlResponse.data.requestUploadUrl;

        try {
          await axios.put(uploadUrl, this.input.file, {
            headers: { 'Content-Type': this.input.file.type }
          });

          this.$emit('add-option', {
            bucketObject: media.id,
            correct: this.correct
          });

          this.dialog = false;
        } catch (error) {
          console.log(error.message);
          this.$toast.error('Failed to upload file.');
        }
      } catch (error) {
        console.log(error.message);
        this.$toast.error('Failed to upload file.');
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
