<template>
  <div>
    <v-dialog v-model="dialog" width="1000">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
          small
        >
          <v-icon>mdi-plus</v-icon>Module</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          Select Module
          {{ courseTitle ? `for ${courseTitle}` : '' }}
        </v-card-title>

        <v-card-text class="py-2">
          <modules-table
            ref="modulesTable"
            :modules="filteredModules"
            :loading="$apollo.queries.modules.loading"
            :allow-selects="true"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="secondary" @click="addSelectedModules">
            Add Modules
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Modules } from '@/graphql/Module.gql';
import ModulesTable from '@/components/Module/ModulesTable';

export default {
  name: 'ModuleSelector',
  components: { ModulesTable },
  props: {
    courseTitle: {
      default: undefined
    },
    selectedModules: {
      default: []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    filteredModules() {
      return (
        this.modules?.filter(module => {
          if (this.selectedModules.includes(module.id)) {
            return false;
          }

          return true;
        }) ?? []
      );
    }
  },
  apollo: {
    modules: {
      query: Modules
    }
  },
  methods: {
    addSelectedModules() {
      const selectedModules = this.$refs.modulesTable.selectedModules;

      if (selectedModules.length > 0) {
        this.$emit(
          'add-selected-modules',
          selectedModules.map(module => module.id)
        );
      }

      this.dialog = false;
    }
  }
};
</script>
