<template>
  <div>
    <div class="page-sub-sec">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        dense
        hide-details
        class="shrink"
      />
    </div>
    <v-data-table
      v-model="selectedCourseContent"
      :items="filteredCourseContent"
      :show-select="allowSelect"
      :single-select="allowSelect"
      :headers="headersToUse"
      class="dark--text rounded-b-lg rounded-t-0"
      :no-data-text="
        `There are no course content${
          search ? ' that match your search' : ''
        }`
      "
      :loading="loading"
      loading-text="Loading..."
      item-key="id"
      @click:row="
        item => $router.push(`/course-content/${item.id}/overview`)
      "
    >
      <template v-slot:[`item.createdBy`]="{ item }">
        {{
          [item.createdBy.firstName, item.createdBy.lastName].join(
            ' '
          )
        }}
      </template>

      <template v-slot:[`item.publishedAt`]="{ item }">
        {{ item.publishedAt ? formatDate(item.publishedAt) : '-' }}
      </template>

      <template v-slot:[`item.state`]="{ item }">
        <span
          class="text-capitalize"
          :style="`color: ${getStateColour(item.state)}`"
        >
          {{ item.state.toLowerCase() }}
        </span>
      </template>

      <template v-slot:[`item.editColumn`]="{ item }">
        <v-btn
          text
          class="pa-0 rounded-md"
          icon
          @click="$router.push(`/course-content/${item.id}/overview`)"
        >
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { objectHasSearch } from '@/utils/search';
import { formatDate } from '@/utils/date';
import { getStateColour } from '@/utils/courseContent';

export default {
  name: 'PublishTable',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    courseContent: {
      required: true
    },
    allowSelect: {
      type: Boolean
    }
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Updated Name', value: 'title' },
      { text: 'Version', value: 'version' },
      { text: 'Created By', value: 'createdBy' },
      { text: 'State', value: 'state' },
      { text: 'Published On', value: 'publishedAt' },
      { text: 'Update Summary', value: 'summary' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ],
    selectedCourseContent: []
  }),
  computed: {
    filteredCourseContent() {
      return (this.courseContent ?? []).filter(content =>
        objectHasSearch(content, this.search)
      );
    },
    headersToUse() {
      return this.headers.filter(header =>
        this.allowSelect ? header.text !== 'Created By' : header
      );
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    getStateColour(state) {
      return getStateColour(state);
    }
  }
};
</script>
