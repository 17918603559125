var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "side-drawer",
          attrs: { app: "" },
          model: {
            value: _vm.drawerState,
            callback: function($$v) {
              _vm.drawerState = $$v
            },
            expression: "drawerState"
          }
        },
        [
          _c("v-list", { staticClass: "secondary--text pa-0" }, [
            _c(
              "div",
              {
                staticStyle: {
                  "background-color": "#2dd5c4 !important",
                  height: "64px"
                }
              },
              [
                _c(
                  "v-list-item",
                  {
                    attrs: { link: "" },
                    on: { click: () => _vm.$router.push("/publish") }
                  },
                  [
                    _c("v-list-item-content", { staticClass: "logo-sec" }, [
                      _c("img", {
                        staticClass: "logo",
                        attrs: { src: require("../images/logo.svg") }
                      })
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "primary", staticStyle: { height: "36px" } },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "px-1",
                    staticStyle: { height: "36px" },
                    attrs: { "no-gutters": "", align: "center" }
                  },
                  [
                    !_vm.currentUserAccount.isUser()
                      ? _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "white", icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.$router.push("/publish")
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("v-col", { staticClass: "pl-1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "white--text text-body-2 text-truncate",
                          staticStyle: { "max-width": "155px" }
                        },
                        [_vm._v(" " + _vm._s(_vm.courseContent?.title) + " ")]
                      )
                    ]),
                    _c("v-col", { attrs: { cols: "auto" } }, [
                      _c(
                        "div",
                        { staticClass: "white--text text-body-2" },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass:
                                "px-2 py-3 font-weight-bold text--primary",
                              attrs: { color: "secondary", "x-small": "" }
                            },
                            [_vm._v("v" + _vm._s(_vm.courseContent?.version))]
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  height: "calc(100vh - 165px)",
                  "overflow-y": "auto"
                }
              },
              _vm._l(_vm.navigationItems, function(navItem, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c("div", { staticClass: "navigation-subtitle" }, [
                      _vm._v(" " + _vm._s(navItem.title) + " ")
                    ]),
                    _vm._l(navItem.children, function(navChild, index) {
                      return _c(
                        "div",
                        {
                          key: `${navItem.title.replaceAll(" ", "_")}-${index}`
                        },
                        [
                          _c(
                            "v-list-item",
                            {
                              attrs: {
                                link: "",
                                "active-class": "navigation-item-active",
                                to: `/course-content/${_vm.$route.params.courseContentId}${navChild.to}`
                              }
                            },
                            [
                              _c(
                                "v-list-item-action",
                                [_c("v-icon", [_vm._v(_vm._s(navChild.icon))])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(navChild.title))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    _c("v-divider", { staticClass: "my-3" })
                  ],
                  2
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "navigation-bottom-bar" },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "align-center px-5 text--primary",
                    staticStyle: { height: "64px" },
                    attrs: { "no-gutters": "" }
                  },
                  [
                    _c("v-col", { attrs: { cols: "auto" } }, [
                      _c("div", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              [
                                _vm.currentUserAccount?.firstName,
                                _vm.currentUserAccount?.lastName
                              ].join(" ")
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "text-capitalize text--secondary",
                          staticStyle: { "font-size": "12px" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.currentUserAccount?.systemRole
                                  .replaceAll("_", " ")
                                  .toLowerCase()
                              ) +
                              " "
                          )
                        ]
                      )
                    ]),
                    _c(
                      "v-col",
                      { staticClass: "ml-auto", attrs: { cols: "auto" } },
                      [
                        _c(
                          "v-btn",
                          { attrs: { icon: "" }, on: { click: _vm.logout } },
                          [
                            _c("v-icon", { attrs: { size: "24" } }, [
                              _vm._v("mdi-logout")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "v-main",
        { staticClass: "main-application" },
        [_c("v-container", { attrs: { fluid: "" } }, [_c("router-view")], 1)],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }