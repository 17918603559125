<template>
  <v-row no-gutters align="center">
    <v-col cols="auto">{{ fileName }}</v-col>
    <v-col cols="auto" class="ml-2">
      <v-btn
        :disabled="$apollo.queries.generateSignedUrl.loading"
        icon
        @click="openImage"
      >
        <v-icon>mdi-eye-outline</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import {
  BucketObject,
  GenerateSignedUrl
} from '@/graphql/BucketObject.gql';

export default {
  name: 'BucketObjectFormat',
  props: {
    bucketObjectId: {
      type: String,
      default: undefined
    }
  },
  data: () => ({
    name: ''
  }),
  apollo: {
    bucketObject: {
      query: BucketObject,
      variables() {
        return {
          id: this.bucketObjectId
        };
      },
      skip() {
        return !this.bucketObjectId;
      }
    },
    generateSignedUrl: {
      query: GenerateSignedUrl,
      // Refetch after 14 minutes since the URL is only valid for 15
      pollInterval: 840000,
      variables() {
        return {
          id: this.bucketObjectId
        };
      },
      skip() {
        return !this.bucketObjectId;
      }
    }
  },
  computed: {
    fileName() {
      if (!this.bucketObject) {
        return 'Loading...';
      }

      const fullObjectId = this.bucketObject.objectId;
      const splitObject = fullObjectId.split('/');

      return splitObject[splitObject.length - 1];
    }
  },
  methods: {
    openImage() {
      window.open(this.generateSignedUrl, '_blank');
    }
  }
};
</script>
