<template>
  <div>
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>Organisations</h1>
            </v-col>
          </v-row>
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <div class="page-subtitle">
                A place where you can view all the organisations.
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="ml-auto">
          <v-btn color="primary" @click="createModalOpen = true">
            <v-icon class="mr-2">mdi-plus</v-icon>New
            Organisation</v-btn
          >
        </v-col>
      </v-row>
    </v-container>

    <div>
      <div class="page-sub-sec">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          outlined
          dense
          hide-details
          class="shrink"
        />
      </div>
      <v-data-table
        :items="filteredOrganisations"
        :headers="headers"
        class="dark--text rounded-b-lg rounded-t-0"
        :no-data-text="
          `There are no organisations${
            search ? ' that match your search' : ''
          }`
        "
        :loading="$apollo.queries.organisations.loading"
        loading-text="Loading..."
        item-key="id"
        @click:row="
          item => $router.push(`/organisation/${item.id}/overview`)
        "
      >
        <template v-slot:[`item.organisationCode`]="{ item }">
          <v-chip class="text-button">{{
            item.organisationCode
          }}</v-chip>
        </template>

        <template v-slot:[`item.editColumn`]="{ item }">
          <v-btn
            icon
            @click="$router.push(`/organisation/${item.id}/overview`)"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <create-organisation-modal
      :open="createModalOpen"
      @close="createModalOpen = false"
      @created="onCreated"
    />
  </div>
</template>

<script>
import Learners from '@/components/Common/Learners';
import { Organisations } from '@/graphql/Organisation.gql';
import CreateOrganisationModal from '@/components/Organisation/CreateOrganisationModal';
import { objectHasSearch } from '@/utils/search';

export default {
  name: 'OrganisationList',
  components: {
    Learners,
    CreateOrganisationModal
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Name', value: 'companyName' },
      { text: 'Address', value: 'address' },
      {
        text: 'Organisation Code',
        value: 'organisationCode'
      },
      {
        text: 'Website',
        value: 'website'
      },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ],
    createModalOpen: false
  }),
  apollo: {
    organisations: {
      query: Organisations
    }
  },
  computed: {
    filteredOrganisations() {
      return this.search
        ? this.organisations.filter(organisation =>
            objectHasSearch(organisation, this.search)
          )
        : this.organisations;
    }
  },
  methods: {
    onCreated() {
      this.$apollo.queries.organisations.refetch();
      this.createModalOpen = false;
    }
  }
};
</script>
