var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabbed-page" },
    [
      _c(
        "v-container",
        { staticClass: "primary--text mb-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "auto" } },
                [
                  _c("h1", [_vm._v(_vm._s(_vm.organisation?.companyName))]),
                  _c("breadcrumbs", {
                    attrs: {
                      items: ["Organisation", _vm.organisation?.companyName]
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "rounded-lg white px-5 py-1 text--primary" },
        [
          _c(
            "v-row",
            {
              staticClass: "info-row py-2",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "mr-auto title-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Name ")]
              ),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c("edit-field", {
                    attrs: { "current-value": _vm.organisation?.companyName },
                    on: { "update-value": _vm.updateName }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "info-row py-2",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "mr-auto title-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Logo ")]
              ),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", "no-gutters": "" } },
                    [
                      _vm.isUploading
                        ? _c("v-progress-circular", {
                            staticClass: "mr-2",
                            attrs: { indeterminate: "", size: "24" }
                          })
                        : _vm._e(),
                      _c("edit-field", {
                        attrs: {
                          "display-value": _vm.logoFileName,
                          type: "file"
                        },
                        on: { "update-value": _vm.updateLogo }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "info-row py-2",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "mr-auto title-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Address ")]
              ),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c("edit-field", {
                    attrs: { "current-value": _vm.organisation?.address },
                    on: { "update-value": _vm.updateAddress }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "info-row py-2",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "mr-auto title-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Country ")]
              ),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c("edit-field", {
                    attrs: {
                      type: "select",
                      "current-value": _vm.organisation?.country,
                      "select-items": _vm.countryOptions
                    },
                    on: { "update-value": _vm.updateCountry }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "info-row py-2",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "mr-auto title-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Website ")]
              ),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c("edit-field", {
                    attrs: { "current-value": _vm.organisation?.website },
                    on: { "update-value": _vm.updateWebsite }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "info-row py-2",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "mr-auto title-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Organisation Code ")]
              ),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c("edit-field", {
                    attrs: {
                      "current-value": _vm.organisation?.organisationCode
                    },
                    on: { "update-value": _vm.updateOrganisationCode }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "info-row py-2",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "mr-auto title-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Employee Identifier ")]
              ),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c("edit-field", {
                    attrs: {
                      type: "select",
                      textClass: "text-capitalize",
                      "current-value": _vm.organisation?.employeeIdentifier,
                      "display-value": _vm.organisation?.employeeIdentifier
                        .replaceAll("_", " ")
                        .toLowerCase(),
                      "select-items": _vm.employeeIdentifierOptions
                    },
                    on: { "update-value": _vm.updateEmployeeIdentifier }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "info-row py-4",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "mr-auto title-column",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Last Updated At ")]
              ),
              _c("v-col", { staticClass: "ml-auto", attrs: { cols: "auto" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.formatDate(_vm.organisation?.updatedAt)) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }