<template>
  <div class="tabbed-page">
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>Modules</h1>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <modules-table
      :modules="modules"
      :loading="$apollo.queries.modules.loading"
    />
  </div>
</template>

<script>
import ModulesTable from '@/components/Module/ModulesTable';
import { Modules } from '@/graphql/Module.gql';

export default {
  name: 'ModulesPage',
  components: {
    ModulesTable
  },
  apollo: {
    modules: {
      query: Modules
    }
  }
};
</script>
