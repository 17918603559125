<template>
  <div>
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>Sites</h1>
            </v-col>
          </v-row>
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <div class="page-subtitle">
                A place where you can view all the sites associated
                with your organisation.
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="ml-auto">
          <v-btn color="primary" @click="createModalOpen = true">
            <v-icon class="mr-2">mdi-plus</v-icon>New Site</v-btn
          >
        </v-col>
      </v-row>
    </v-container>

    <div>
      <div class="page-sub-sec">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          outlined
          dense
          hide-details
          class="shrink"
        />
      </div>
      <v-data-table
        :items="filteredSites"
        :headers="headers"
        class="dark--text rounded-b-lg rounded-t-0"
        :no-data-text="
          `There are no sites${
            search ? ' that match your search' : ''
          }`
        "
        :loading="$apollo.queries.sites.loading"
        loading-text="Loading..."
        item-key="id"
        @click:row="
          item =>
            $router.push(
              `/organisation/${organisationId}/sites/${item.id}`
            )
        "
      >
        <template v-slot:[`item.siteCode`]="{ item }">
          <v-chip class="text-button">{{ item.siteCode }}</v-chip>
        </template>

        <template v-slot:[`item.editColumn`]="{ item }">
          <v-btn
            icon
            @click="
              $router.push(
                `/organisation/${organisationId}/sites/${item.id}`
              )
            "
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <create-site-modal
      :open="createModalOpen"
      :organisation-id="organisationId"
      @close="createModalOpen = false"
      @created="onCreated"
    />
  </div>
</template>

<script>
import { Sites } from '@/graphql/Site.gql';
import CreateSiteModal from '@/components/Site/CreateSiteModal';
import { objectHasSearch } from '@/utils/search';

export default {
  name: 'OrganisationSites',
  components: {
    CreateSiteModal
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Address', value: 'address' },
      {
        text: 'Site Code',
        value: 'siteCode'
      },
      {
        text: 'Website',
        value: 'website'
      },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ],
    createModalOpen: false
  }),
  computed: {
    organisationId() {
      return this.$route.params.organisationId;
    },
    filteredSites() {
      return this.search
        ? this.sites.filter(site =>
            objectHasSearch(site, this.search)
          )
        : this.sites;
    }
  },
  apollo: {
    sites: {
      query: Sites,
      variables() {
        return {
          organisationId: this.organisationId
        };
      }
    }
  },
  methods: {
    onCreated() {
      this.$apollo.queries.sites.refetch();
      this.createModalOpen = false;
    }
  }
};
</script>
