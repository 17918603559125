var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mr-3",
                          attrs: { color: "primary", small: _vm.dense }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-account-plus")
                      ]),
                      _vm._v(
                        "Invite " + _vm._s(_vm.staff ? "Staff" : "Learner")
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "rounded-lg" },
            [
              _c(
                "v-card-title",
                { staticClass: "h6 font-weight-bold primary--text" },
                [
                  _vm._v(
                    " Invite " +
                      _vm._s(_vm.staff ? "Staff" : "Learner") +
                      " Account "
                  )
                ]
              ),
              _c(
                "v-card-text",
                { staticClass: "py-2" },
                [
                  _c(
                    "v-form",
                    { ref: "inviteUserAccountForm" },
                    [
                      _c("v-label", [_vm._v("Email")]),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.rules.requiredField,
                          label: "Email",
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          required: ""
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      }),
                      _c("v-label", [_vm._v("First Name")]),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.rules.requiredField,
                          label: "First Name",
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          required: ""
                        },
                        model: {
                          value: _vm.firstName,
                          callback: function($$v) {
                            _vm.firstName = $$v
                          },
                          expression: "firstName"
                        }
                      }),
                      _c("v-label", [_vm._v("Last Name")]),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.rules.requiredField,
                          label: "Last Name",
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          required: ""
                        },
                        model: {
                          value: _vm.lastName,
                          callback: function($$v) {
                            _vm.lastName = $$v
                          },
                          expression: "lastName"
                        }
                      }),
                      !_vm.organisationId && _vm.staff
                        ? _c(
                            "div",
                            [
                              _c("v-label", [_vm._v("System Role")]),
                              _c("v-select", {
                                attrs: {
                                  rules: _vm.rules.requiredField,
                                  items: _vm.systemRolesItems,
                                  label: "System Role",
                                  "single-line": "",
                                  outlined: "",
                                  dense: "",
                                  required: ""
                                },
                                model: {
                                  value: _vm.systemRole,
                                  callback: function($$v) {
                                    _vm.systemRole = $$v
                                  },
                                  expression: "systemRole"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.organisationId && _vm.staff
                        ? _c(
                            "div",
                            [
                              _c("v-label", [_vm._v("User Role")]),
                              _c("v-select", {
                                attrs: {
                                  rules: _vm.rules.requiredField,
                                  items:
                                    _vm.organisationId && !_vm.siteId
                                      ? _vm.organisationRoleItems
                                      : _vm.siteRoleItems,
                                  label: "User Role",
                                  "single-line": "",
                                  outlined: "",
                                  dense: "",
                                  required: ""
                                },
                                model: {
                                  value: _vm.userRole,
                                  callback: function($$v) {
                                    _vm.userRole = $$v
                                  },
                                  expression: "userRole"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.organisationId && !_vm.staff
                        ? _c(
                            "div",
                            [
                              _c("v-label", [_vm._v("Site")]),
                              _c("v-autocomplete", {
                                attrs: {
                                  rules: _vm.rules.requiredField,
                                  items: _vm.siteItems,
                                  label: "Site",
                                  "single-line": "",
                                  outlined: "",
                                  dense: "",
                                  required: ""
                                },
                                model: {
                                  value: _vm.site,
                                  callback: function($$v) {
                                    _vm.site = $$v
                                  },
                                  expression: "site"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.organisationId && !_vm.staff
                        ? _c(
                            "div",
                            [
                              _c("v-label", [_vm._v("Bundle")]),
                              _c("v-autocomplete", {
                                attrs: {
                                  rules: _vm.rules.requiredField,
                                  items: _vm.bundleItems,
                                  label: "Bundle",
                                  "single-line": "",
                                  outlined: "",
                                  dense: "",
                                  required: ""
                                },
                                model: {
                                  value: _vm.bundle,
                                  callback: function($$v) {
                                    _vm.bundle = $$v
                                  },
                                  expression: "bundle"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.loading
                ? _c("v-progress-linear", {
                    attrs: { indeterminate: "", color: "primary" }
                  })
                : _vm._e(),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: "",
                        disabled: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "secondary",
                        loading: _vm.loading,
                        disabled: _vm.loading
                      },
                      on: { click: _vm.inviteUser }
                    },
                    [_vm._v(" Invite ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }