<template>
  <div class="mt-3">
    <v-row align="center" class="align-center mb-0">
      <v-col class="text--primary font-weight-bold">
        Order List Options
      </v-col>

      <v-col v-if="courseContentId" cols="auto">
        <create-order-list-option-modal
          ref="createOrderListModalRef"
          :existing-option="editingOption"
          @add-option="addOption"
          @update-option="updateOption"
          @reset-existing-option="editingOption = undefined"
        />
      </v-col>
    </v-row>

    <div class="rounded-lg white px-5 py-1 text--primary">
      <v-data-table
        :items="existingOptions"
        :headers="headers"
        class="dark--text rounded-b-lg rounded-t-0"
        :no-data-text="`There are no options`"
        item-key="id"
      >
        <template v-slot:[`item.correctPosition`]="{ item }">
          <v-chip class="font-weight-bold">{{
            ordinalSuffixOf(item.correctPosition)
          }}</v-chip>
        </template>
        <template v-slot:[`item.editColumn`]="{ item }">
          <v-btn
            v-if="courseContentId"
            text
            class="pa-0 rounded-md"
            icon
            @click="editOption(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="courseContentId"
            text
            class="pa-0 rounded-md"
            icon
            @click="deleteOption(item.id)"
          >
            <v-icon color="red">mdi-delete-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import CreateOrderListOptionModal from './Common/CreateOrderListOptionModal';
import { ordinalSuffixOf } from '@/utils/number';

export default {
  name: 'OrderList',
  components: { CreateOrderListOptionModal },
  props: {
    existingOptions: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    headers: [
      { text: 'ID', value: 'id', sortable: false },
      {
        text: 'Option',
        value: 'option',
        sortable: false
      },
      {
        text: 'Correct Position',
        value: 'correctPosition',
        sortable: true
      },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ],
    editingOption: undefined
  }),
  computed: {
    courseContentId() {
      return this.$route.params.courseContentId;
    }
  },
  methods: {
    ordinalSuffixOf(number) {
      return ordinalSuffixOf(number);
    },
    addOption(option) {
      const hasOptionAlready = this.existingOptions.find(
        o => o.correctPosition === option.correctPosition
      );

      if (hasOptionAlready) {
        this.$toast.error(
          'The correct position provided exists for this question'
        );
        return;
      }

      const nextId =
        this.existingOptions.length > 0
          ? Math.max(...this.existingOptions.map(option => option.id)) + 1
          : 1;
      const updatedOptions = [...this.existingOptions, { ...option, id: nextId }];
      this.$emit('options-updated', updatedOptions);
    },
    updateOption(option) {
      const updatedOptions = this.existingOptions.map(o => 
        o.id === option.id ? { ...option } : o
      );
      this.$emit('options-updated', updatedOptions);
    },
    deleteOption(id) {
      const updatedOptions = this.existingOptions.filter(option => option.id !== id);
      this.$emit('options-updated', updatedOptions);
    },
    editOption(item) {
      this.editingOption = { ...item };
      this.$refs.createOrderListModalRef.dialog = true;
    }
  }
};
</script>
