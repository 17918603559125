<template>
  <v-app>
    <v-container class="my-auto">
      <v-row justify="center">
        <v-progress-circular indeterminate color="primary" />
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { auth } from '@/main';

export default {
  name: 'InsufficientPermissions',
  methods: {
    logout() {
      auth.signOut().then(() => this.$router.push('/'));
    }
  }
};
</script>
