<template>
  <v-main class="login-page">
    <v-container class="login-container" fill-height justify-center>
      <v-row class="card-container">
        <v-col cols="12" md="6" class="hero-image d-none d-md-flex">
          <img src="../../images/LoginHero.svg" />
        </v-col>
        <v-col cols="12" md="6" class="content">
          <v-row class="header pa-4" justify="center">
            <img src="../../images/LoginHeader.svg" />
          </v-row>
          <slot>
            <v-row>
              Content
            </v-row>
          </slot>
        </v-col>
      </v-row>
      <footer class="footer d-none d-md-block">© Copyright {{ currentYear }} Dementia & Alzheimer's Australia Ltd.</footer>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'BaseLoginPage',
  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  background: #fafafa;
}

.login-container {
  @media (max-width: 959px) {
    align-items: start !important;
  }
}

.card-container {
  height: 100%;
  max-width: 1200px !important;
  max-height: 600px;
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 959px) {
    max-height: none;
  }
}

.hero-image {
  background: #113247;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: white;

  @media (max-width: 959px) {
    background: transparent;
  }
}

.header {
  background: #96EBE3;
  flex: 0;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 16px;
  color: #113247;
  font-size: 16px;
}
</style>
