<template>
  <div>
    <v-dialog v-model="dialog" width="1000">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on" small>
          <v-icon>mdi-plus</v-icon>Course</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          Select Course
          {{
            courseGroupingTitle ? `for ${courseGroupingTitle}` : ''
          }}
        </v-card-title>

        <v-card-text class="py-2">
          <courses-table
            ref="coursesTable"
            :courses="filteredCourses"
            :loading="$apollo.queries.courses.loading"
            :allow-selects="true"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="secondary" @click="addSelectedCourses">
            Add Courses
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Courses } from '@/graphql/Course.gql';
import CoursesTable from '@/components/Course/CoursesTable';

export default {
  name: 'CourseSelector',
  components: { CoursesTable },
  props: {
    courseGroupingTitle: {
      default: undefined
    },
    selectedCourses: {
      default: []
    }
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    filteredCourses() {
      return (
        this.courses?.filter(course => {
          if (this.selectedCourses.includes(course.id)) {
            return false;
          }

          return true;
        }) ?? []
      );
    }
  },
  apollo: {
    courses: {
      query: Courses
    }
  },
  methods: {
    addSelectedCourses() {
      const selectedCourses = this.$refs.coursesTable.selectedCourses;

      if (selectedCourses.length > 0) {
        this.$emit(
          'add-selected-courses',
          selectedCourses.map(course => course.id)
        );
      }

      this.dialog = false;
    }
  }
};
</script>
