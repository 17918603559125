import enumToDisplay from '@/utils/enumToDisplay';

const VariantType = Object.freeze({
  SINGLE_TEXT_CHOICE: 'SINGLE_TEXT_CHOICE',
  MULTIPLE_TEXT_CHOICE: 'MULTIPLE_TEXT_CHOICE',
  SINGLE_IMAGE_CHOICE: 'SINGLE_IMAGE_CHOICE',
  MULTIPLE_IMAGE_CHOICE: 'MULTIPLE_IMAGE_CHOICE',
  DRAG_AND_DROP: 'DRAG_AND_DROP',
  ORDER_LIST: 'ORDER_LIST'
});

export const variantTypeItems = enumToDisplay(VariantType);

export default VariantType;
